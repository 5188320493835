/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  Popconfirm,
  Modal,
  message,
  Button,
  Divider,
  Collapse,
  Image,
} from "antd";
import Phone from "../../assets/images/offer-phone.svg";
import Globe from "../../assets/images/offer-globe.svg";
import Share from "../../assets/images/offer-share.svg";
import Map from "../../assets/images/offer-map.svg";
import Heart from "../../assets/images/offer-heart.svg";
import viewmore from "../../assets/images/viewmore.svg";
import { AiFillHeart } from "react-icons/ai";

import { parseAddress, getFormattedDt } from "../../common/util";
import getEnvVars from "../../environment";
import { Actions } from "../../common";
import UserActions from "../../actions";
import Axios from "axios";
import { isMobile } from "../../common/util";
import { isTab } from "../../common/util";
import Images from "../../assets/images/Images";
import { getRemainingOffer } from "../../utils/Helper";
import closeBtn from "../../assets/images/closeBtn.svg";
import Phoneblue from "../../assets/images/PhoneBlue.svg";
import featureImage from "../../assets/images/featuredLogo.png";
import ReviewStars from "../../components/ReviewStars/ReviewStars";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { Router } from "react-router-dom/cjs/react-router-dom.min";
import {
  UpOutlined,
  DownOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  RightOutlined,
  LeftOutlined,
  GlobalOutlined,
  ShareAltOutlined,
  HeartOutlined,
  HeartFilled,
  PhoneOutlined,
} from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/AntdIcon";
import { FavoriteBorderOutlined, FavoriteOutlined } from "@material-ui/icons";
import { Footer } from "../../components";

const { web } = getEnvVars();
const { Panel } = Collapse;
const { Text } = Typography;

const OfferCard = (props) => {
  const { locQRId } = props;
  const closeIcon = <img src={closeBtn} alt="closeBtn" />;
  const [reviewsOverview, setReviewsOverview] = useState(null);
  const elementRef = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [likeDetails, setLikeDetails] = useState({});
  const toggleCollapse = (e) => {
    e.stopPropagation();
    setIsCollapsed(!isCollapsed);
  };


  const offerNameStyle = {
    paddingLeft: "1rem",
    textAlign: "left",
    paddingTop: isCollapsed ? "6rem" : "0rem",
  };

  const dispatch = useDispatch();
  const {
    item: {
      objid,
      title,
      biz,
      endDt,
      cmpnImgs,
      brochureUri,
      offers,
      cmpnType,
      cmpnUrl,
      cmpnLocs,
    },
    item,
    history,
  } = props;
  {
    console.log("imgUri", item, biz);
  }
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const handleHorizontalScroll = (event, amount) => {
    if (event && event.stopPropagation) event.stopPropagation();
    if (elementRef.current) {
      elementRef.current.scrollBy({
        left: amount,
        behavior: "smooth",
      });
      elementRef.current.classList.add("show-scrollbar");
    }
  };

  const handleScroll = () => {
    if (elementRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = elementRef.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    if (elementRef.current) {
      elementRef.current.addEventListener("scroll", handleScroll);
      handleScroll();

      return () => {
        if (elementRef.current) {
          elementRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (biz?.bizid) {
      getReviewOverViews(biz?.bizid);
    }
  }, []);
  const { indvid } = useSelector(({ auth }) => auth.indv);
  const { cmpnsLiked, bizLikes } = useSelector(({ cmpn }) => cmpn);
  const liked = cmpnsLiked && cmpnsLiked[objid && objid];
console.log("liked",liked);
  const formattedCmpnType =
    cmpnType.charAt(0).toUpperCase() + cmpnType.slice(1).toLowerCase();

  const { CMPN_LIKED } = Actions;

  const addr = biz.showBiz ? parseAddress(biz) : "";
  const url = `${web}/offer-details/${item.biz.bizName.replace(
    /[.\W]+/g,
    "-"
  )}/${item.qrCode}`;
  const totalOfferSold = offers?.reduce(function (sum, current) {
    return sum + current.offerSold;
  }, 0);

  const doLike = async (cmpnId, liked) => {
    let payload = {
      cmpnId,
      indvId: indvid,
      bizId: biz.bizid,
    };
    if (liked) {
      payload.objid = liked.likedId;
      payload.isDeleted = !liked.isDeleted;
    }
    const resp = await UserActions.likeCmpn(payload);

    if (resp.success) {
      dispatch({ type: CMPN_LIKED, payload: resp.payload });
      props.callBack(true)
      
    } else {
      message.error("Please login first");
    }
  };
  const [showNumberModal, setShowNumberModal] = useState(false);
  const [scroll, setScroll] = useState(0);
  const getReviewOverViews = async (cmpn) => {
    let res = await UserActions.fetchReviewsOverview(cmpn);
    setReviewsOverview(res.cmpn);
  };
  const download = async (e, uri, title) => {
    e.stopPropagation();
    let fileDownload = require("js-file-download");
    const res = await Axios.get(uri, {
      responseType: "blob",
    });
    fileDownload(res.data, `${title}.pdf`);
  };

  const Mailto = ({ email, subject, body, children }) => {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`mailto:${email}?subject=${encodeURIComponent(subject) || ""
          }&body=${encodeURIComponent(body) || ""}`}
      >
        {children}
      </a>
    );
  };

  const Confirm = ({ url }) => {
    const link = !url.startsWith("http") ? `https://${url}` : url;
    return (
      <Popconfirm
        overlay
        placement="top"
        title={
          <div style={{ width: "24rem" }}>
            You are visiting the link {url} outside Fyndr domain that is not
            verified or secured by Fyndr, do you want to continue ?
          </div>
        }
        onConfirm={(e) => {
          e.stopPropagation();
          window.open(link);
        }}
        okText="Yes"
        cancelText="No"
      >
        <GlobalOutlined style={{ fontSize: "1.6rem", color: "#7F7F7F" }} />
      </Popconfirm>
    );
  };
  const getCount = (item) => {
    console.log("getCount",likeDetails );
    if (liked["isDeleted"] === false && liked.likedId === likeDetails?.objid ) {
      return item?.likedCount + 1
    } else if (liked["isDeleted"] === true && liked.likedId === likeDetails?.objid ) {
      return item?.likedCount - 1
    }
    else {
      return item?.likedCount
    }
  }
  return (
    <>
      <Card
        className="offer-new-card"
        onClick={() =>
          history.push(
            `/offer-details/${item.biz.bizName.replace(/[.\W]+/g, "-")}/${item.qrCode
            }`,
            {
              item,
              liked,
              locQRId,
            }
          )
        }
      >
        {!isMobile() && (
          <Row style={{ marginBottom: "8px" }}>
            <Col
              style={{ display: "flex", justifyContent: "flex-start" }}
              xs={0}
              sm={18}
              md={18}
            >
              <span className="offer-card-top-heading">{item.title}</span>
            </Col>
            <Col
              xs={0}
              sm={6}
              md={6}
              // span={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {item?.isFeatured && (
                <Image
                  preview={false}
                  src={featureImage}
                  style={{ width: "101px", height: "25px" }}
                />
              )}{" "}
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24} sm={13} md={10}>
            <img
              alt=""
              className="offer_cards--cmpn-img"
              src={
                Array.isArray(cmpnImgs) && cmpnImgs[0]
                  ? cmpnImgs[0]?.imgUri
                  : Images.PlaceHolder
              }
            />
            {item?.isFeatured && isMobile() && (
              <img src={featureImage} className="overlay-image" />
            )}
          </Col>
          <Col span={1}></Col>

          <Col style={{ textAlign: "left" }} xs={24} sm={10} md={13}>
            {!isMobile() && (
              <Row>

                <span className="offer-card-heading"> {item?.biz?.bizName}</span>
              </Row>
            )}
            {isMobile() && (
              <Row style={{ marginTop: "8px" }}>
                <Col span={12}>
                  <span className="offer-card-top-heading">{item.title}</span>
                </Col>
                <Col
                  span={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div
                    style={{
                      width: "48px",
                      height: "24px",
                      borderRadius: "20px",
                      backgroundColor: "#00000066",
                      display: "flex",
                      padding: "2px",
                      justifyContent: "space-around",
                      color: "#fff",
                    }}
                  >
                    {liked && liked["isDeleted"] === false && (
                      <FavoriteOutlined
                        style={{ marginTop: "2px" }}
                        onClick={() => {
                          doLike(objid, liked);
                        }}
                      />
                    )}
                    {(!liked?.hasOwnProperty("likedId") ||
                      liked["isDeleted"] === true) && (
                        <FavoriteBorderOutlined
                          style={{ marginTop: "2px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            doLike(objid, liked);
                          }}
                        />
                      )}
                    <Text
                      className="offer_cards--cmpn-liked"
                      style={{
                        color: "#fff",
                        fontSize: "14px",
                        marginTop: "0.5px",
                      }}
                    >
                      {/* {bizLikes && bizLikes[biz?.bizid]} */}
                      {/* {item?.likedCount > 0 && getCount(item)} */}
                      {item?.likedCount > 0 && item?.likedCount}
                    </Text>
                  </div>
                </Col>
              </Row>
            )}
            {props.typeOfDeals !== "online" && (
              <Row style={{ marginTop: "12px" }}>
                <Text className="offer_cards--address">{`${cmpnLocs[0]?.distance ? cmpnLocs[0]?.distance.toFixed(1) : "0"
                  } miles, ${parseAddress(cmpnLocs[0])}`}</Text>
              </Row>
            )}

            {!isMobile() && (
              <Row style={{ marginTop: "8px" }}>
                <Space
                  style={{ gap: isTab() ? "2px" : "14px !important" }}
                  direction="horizontal"
                  size="small"
                >
                  {isMobile()
                    ? cmpnLocs[0]?.phone && (
                      <a href={`tel:${cmpnLocs[0]?.phone}`}>
                        <PhoneOutlined
                          style={{ fontSize: "1.6rem", color: "#7F7F7F" }}
                        />
                      </a>
                    )
                    : cmpnLocs[0]?.phone && (
                      <PhoneOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowNumberModal(true);
                        }}
                        style={{ fontSize: "1.6rem", color: "#7F7F7F" }}
                      />
                    )}
                  {(biz.website || cmpnUrl) && (
                    <div onClick={(e) => e.stopPropagation()}>
                      <Confirm url={cmpnUrl ? cmpnUrl : biz.website} />
                    </div>

                    // <Confirm url={cmpnUrl ? cmpnUrl : biz.website} />
                  )}
                  <Mailto
                    onClick={(e) => e.stopPropagation()}
                    email=""
                    subject={`${biz.bizName}: ${title}`}
                    body={`Found this deal on fyndr:\n${item.title}\n${url}`}
                    children={
                      <ShareAltOutlined
                        style={{ color: "#7F7F7F", fontSize: "1.6rem" }}
                      />
                    }
                  />

                  {liked && liked["isDeleted"] === false ? (
                    <HeartFilled
                      style={{ color: "#2764cd", fontSize: "1.6rem" }}
                      color={"#2764cd"}
                      onClick={(e) => {
                        e.stopPropagation();
                        doLike(objid, liked);
                      }}
                    />
                  ) : (

                    <HeartOutlined
                      style={{ fontSize: "1.6rem", color: "#7F7F7F" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        doLike(objid, liked);
                      }}
                    />
                  )}
                  <Text className="offer_cards--cmpn-liked">
                    {/* {bizLikes && bizLikes[biz?.bizid]} */}
                    {/* {item?.likedCount > 0 && getCount(item)} */}
                    {item?.likedCount > 0 && item?.likedCount}
                  </Text>
                </Space>
              </Row>
            )}

            {isMobile() && (
              <Row
                className="offer_cards--offer-icons-div"
                onClick={(e) => e.stopPropagation()}
              >
                <Col xs={8} sm={8} md={8} align={"center"}>
                  <Mailto
                    email=""
                    subject={`${biz.bizName}: ${title}`}
                    body={`Found this deal on fyndr:\n${item.title}\n${url}`}
                    children={
                      <ShareAltOutlined
                        style={{ color: "#7F7F7F", fontSize: "1.6rem" }}
                      />
                    }
                    className="offer_cards--icon-border"
                  />
                </Col>

                {(biz.website || cmpnUrl) && (
                  <Col
                    xs={8}
                    sm={8}
                    md={8}
                    align={"center"}
                    className="offer_cards--icon-border"
                  >
                    <Confirm url={cmpnUrl ? cmpnUrl : biz.website} />
                  </Col>
                )}

                <Col
                  xs={8}
                  sm={8}
                  md={8}
                  align={"center"}
                  className="offer_cards--icon-border"
                >
                  {isMobile()
                    ? cmpnLocs[0]?.phone && (
                      <a href={`tel:${cmpnLocs[0]?.phone}`}>
                        <PhoneOutlined
                          style={{ fontSize: "1.6rem", color: "#7F7F7F" }}
                        />
                      </a>
                    )
                    : cmpnLocs[0]?.phone && (
                      <PhoneOutlined
                        style={{ fontSize: "1.6rem", color: "#7F7F7F" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowNumberModal(true);
                        }}
                      />
                    )}
                </Col>
                <Col
                  xs={8}
                  sm={8}
                  md={8}
                  align={"center"}
                  className="offer_cards--icon-border"
                ></Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: "8px" }}>
          {offers.length > 0 && (
            <Col span={12} style={{ textAlign: "left" }}>
              <Text style={{ textTransform: "capitalize" }}>
                {item.cmpnType} : {offers.length}
              </Text>
            </Col>
          )}

          {offers.length > 0 && (
            <Col onClick={toggleCollapse} span={12} className="toggleCollapse">
              {isCollapsed ? (
                <>
                  {offers.length === 1 ? (
                    <>
                      See {item.cmpnType.replace(/s$/, "")} <DownOutlined />
                    </>
                  ) : (
                    <>
                      See {item.cmpnType} <DownOutlined />
                    </>
                  )}
                </>
              ) : (
                <>
                  See less <UpOutlined />
                </>
              )}
            </Col>
          )}
        </Row>
        {!isCollapsed && offers.length > 0 && (
          <>
            <Divider style={{ margin: "0px" }} />

            <div className="arrow-button-div">
              {offers.length > 1 && (
                <Button
                  className="arrow-button-left"
                  shape="circle"
                  icon={<LeftOutlined />}
                  onClick={(event) => handleHorizontalScroll(event, -120)}
                ></Button>
              )}
              <div className="offer-horizontal-scroll-div" ref={elementRef}>
                {offers.map((offer, key) => {
                  const discount = `${offer.discountType === "%"
                    ? offer.amount.toFixed(0) + "%"
                    : offer.currencySymbol + offer.amount.toFixed(0)
                    } Off`;
                  return (
                    <>
                      <Card
                        className="offer-secondary-card"
                        style={{ marginRight: "16px", borderRadius: "5px" }}
                      >
                        <Row>
                          <Col
                            span={24}
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              whiteSpace: "normal",
                              backgroundImage: `url(${offer.imgUri
                                ? offer?.imgUri
                                : cmpnImgs[1]?.imgUri
                                  ? cmpnImgs[1]?.imgUri
                                  : Images.PlaceHolder
                                })`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                            className="offer_cards--img_big"
                          >
                            {(cmpnType === "offers" ||
                              cmpnType === "events") && (
                                <div>
                                  <div className="offer_cards--ribbon-text">
                                    {discount}
                                  </div>
                                  <svg
                                    width="40"
                                    height="70"
                                    viewBox="0 0 39 70"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="offer_cards--ribbon"
                                  >
                                    <path
                                      d="M0 0H22.9C28.5949 0 31.4424 0 33.6097 1.12587C35.4362 2.07462 36.9254 3.56382 37.8741 5.39026C39 7.55764 39 10.4051 39 16.1V70L19.5 45.3704L0 70V0Z"
                                      fill="#FFE606"
                                    />
                                  </svg>
                                </div>
                              )}
                          </Col>
                        </Row>
                        <div style={{ padding: "4px" }}>
                          <Row>
                            <Col span={24} className="lower-offer-title">
                              {" "}
                              <span style={{fontSize:"14px"}}>{offer.title}</span>{" "}
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              {cmpnType === "offers" && (
                                <div>
                                  <Text
                                    className="offer_cards--offer-original-price"
                                    delete
                                  >{`${offer.currencySymbol
                                    }${offer?.retailPrice?.toFixed(2)} `}</Text>
                                  <Text
                                    strong
                                    className="offer_cards--offer-discount"
                                  >{`${offer.currencySymbol
                                    }${offer?.offerPrice?.toFixed(2)}`}</Text>
                                </div>
                              )}

                              {cmpnType === "events" && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                  }}
                                >
                                  <Text
                                    className="offer_cards--offer-original-price"
                                    delete
                                  >{`${offer.currencySymbol
                                    }${offer?.retailPrice?.toFixed(2)} `}</Text>
                                  <Text
                                    strong
                                    className="offer_cards--offer-discount"
                                  >{`${offer.currencySymbol
                                    }${offer?.offerPrice?.toFixed(2)}`}</Text>
                                </div>
                              )}
                              {cmpnType === "coupons" && (
                                <>
                                  <Text className="offer_cards--offer-discount-text">
                                    {discount}
                                  </Text>
                                </>
                              )}
                            </Col>
                            <Col
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              span={12}
                            >
                              {offer.offerSold >= 10 && (
                                <Text className="offer_cards--offer-subtitle">{`${"Sold offer"} : ${offer.offerSold
                                  }`}</Text>
                              )}
                              {cmpnType === "offers" &&
                                offer.offerLimit !== null && (
                                  <Text className="offer_cards--offer-subtitle">
                                    {`${"Offer Remaining"} :  ${offer.offerLimit == null
                                      ? "Unlimited"
                                      : getRemainingOffer(
                                        offer.offerLimit,
                                        offer.offerSold
                                      )
                                      }`}
                                  </Text>
                                )}
                              {cmpnType === "events" &&
                                offer.offerLimit !== null && (
                                  <Text className="offer_cards--offer-subtitle">
                                    {`${"Offer Remaining"} :  ${offer.offerLimit == null
                                      ? "Unlimited"
                                      : getRemainingOffer(
                                        offer.offerLimit,
                                        offer.offerSold
                                      )
                                      }`}
                                  </Text>
                                )}

                              {cmpnType === "coupons" && (
                                <>
                                  <Text className="offer_cards--coupon-code">{`${offer.couponCode}`}</Text>
                                </>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    </>
                  );
                })}
              </div>
              {offers.length > 1 && (
                <Button
                  onClick={(event) => handleHorizontalScroll(event, 160)}
                  className="arrow-button-right"
                  shape="circle"
                  icon={<RightOutlined />}
                ></Button>
              )}
            </div>
          </>
        )}
      </Card>

      <Modal
        visible={showNumberModal}
        onCancel={() => setShowNumberModal(false)}
        footer={null}
        title="Call us"
        className="callUs_modal"
        closeIcon={closeIcon}
      >
        <Row justify={"center"}>
          <img alt="bluePhone" src={Phoneblue} />
          <Text
            style={{
              color: "#257CDB",
              fontSize: "1.25rem",
              fontWeight: "400",
              marginLeft: "16px",
            }}
          >{`${cmpnLocs[0]?.phone}`}</Text>
        </Row>
      </Modal>
    </>
  );
};
export default OfferCard;
