// import React from "react";
// import PropType from "prop-types";
// import ReactHtmlParser from 'react-html-parser';
// const ReactMarkdown = require("react-markdown");

// const RichTextViewer = ({ content }) => {
//   return <>{ReactHtmlParser(content)}</>;
// };

// RichTextViewer.propType = {
//   content: PropType.string,
// };
// export default RichTextViewer;

import React from "react";
import PropType from "prop-types";
import ReactHtmlParser from 'react-html-parser';
const ReactMarkdown = require("react-markdown");
const RichTextViewer = ({ content }) => {
  // const isHtml =  isHTML(content)
  const trimmedContent = cleanHTMLContent(content);  
  const isHtml = isHTML(trimmedContent);

  const transform = (node) => {
    if (node.type === "tag" && node.name === "strong") {
      return <b>{node.children[0].data}</b>;
    }
  };
  
  if(isHtml){
    // console.log("Html"  , content)
    return <>{ReactHtmlParser(trimmedContent ,  { transform } )}</>;
  }else{
    // console.log("markdown" ,  content)
    return <ReactMarkdown>{content.trim()}</ReactMarkdown>;
  }
};

const cleanHTMLContent = (htmlContent) => {
  return htmlContent 
    .trim()                                          
    .replace(/\s+/g, ' ')
    .replace(/>\s+</g, '><')
    .replace(/&nbsp;{2,}/g, ' ')
    .replace(/<p>\s*(.*?)\s*<\/p>/g, '<p>$1</p>')
    .replace(/<span>\s*(.*?)\s*<\/span>/g, '<span>$1</span>')
    .replace(/<p>(\s|&nbsp;|<span><\/span>)*<\/p>/g, '')
    .replace(/<span>(\s|&nbsp;)*<\/span>/g, '')
    .replace(/<p>\s*<\/p>/g, '')
    .replace(/<p>(&nbsp;|\s)+/g, '<p>')
    .replace(/<span>(&nbsp;|\s)+/g, '<span>')
    .replace(/(&nbsp;)+/g, '')
    .replace(/<p>\s*<span>(.*?)<\/span>\s*<\/p>/g, '<p><span>$1</span></p>')      
};


function isHTML(str) {
  var a = document.createElement('div');
  a.innerHTML = str;
  for (var c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true;
  }
  return false;
}
RichTextViewer.propType = {
  content: PropType.string,
};
export default RichTextViewer;
