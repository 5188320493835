import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Menu, Row, Typography, Col } from "antd";
import { useHistory } from "react-router-dom";
import { Logo } from "../../../components";
import { isMobile } from "../../../common/util";
import classNames from "classnames";
import campaignTour from '../../../assets/images/campaignsTour.png';
import paymentTour from '../../../assets/images/paymentTour.png'
import storeTour from '../../../assets/images/storeTour.png'
import businessTour from '../../../assets/images/businessTour.png'
import profileTour from '../../../assets/images/profileTour.png'
import reportsTour from '../../../assets/images/reportsTour.png'
import reportsicon from '../../../assets/images/reportsicon.svg';
import disputeicon from '../../../assets/images/disputeicon.svg';
import businessicon from '../../../assets/images/businessicon.jpg';
import profileicon from '../../../assets/images/profileicon.svg';
import revenueicon from '../../../assets/images/revenueicon.svg';
import campaignicon from '../../../assets/images/campaignicon.svg';
import {
  ShopOutlined,
  UnorderedListOutlined,
  DollarOutlined,
  FileDoneOutlined,
  ProfileOutlined,
  PicLeftOutlined,
  QrcodeOutlined,
  BankOutlined,
  ShoppingCartOutlined,
  LockOutlined,
  CreditCardOutlined,
  GatewayOutlined,
  TeamOutlined,
  LeftSquareOutlined,
  RightSquareOutlined,
  PieChartOutlined,
  SoundOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { RiShieldUserLine } from "react-icons/ri";
import maskgroup from "../../../assets/images/Mask group.svg";
import myAppointment from "../../../assets/images/myAppointment.svg"
import platformVar from "../../../assets/images/platformVar.svg";
import exclamation from "../../../assets/images/exclamation.jpg";
import { Actions, Theme } from "../../../common";
import { Popover } from "antd";
import { useState } from "react";
import Tour from "reactour";
import { selector } from "recoil";
import { icons } from "antd/lib/image/PreviewGroup";
const {
  TOGGLE_COLLAPSED_NAV,
  TOGGLE_CREATE_CAMPAIGN,
  ROLE_FYNDR,
  ROLE_MANAGER,
  ROLE_SUPPORT,

} = Actions;


const { Text } = Typography;


const sidemenuOptionsBiz = !isMobile() ? [
  {
    key: "1",
    label: "My Orders",
    icon: <UnorderedListOutlined />,
    path: "/billing/transactions",
  },
  {
    mainCategory: "Campaigns",
    icon: <ShoppingCartOutlined />,
    // path: "/campaign",
    options: [
      {
        key: "2",
        label: "Create Campaign",
        icon: <ProfileOutlined />,
        path: "/campaign",
        params: { mode: "createCampaign" },
      },
      {
        key: "3",
        label: "Campaigns",
        icon: <ProfileOutlined />,
        path: "/campaign",
        params: { mode: "campaigns" },
      },
    ],
  },
  // {
  //   key: "4",
  //   label: "Interactions",
  //   icon: <TeamOutlined />,
  //   path: "/interactions",
  // },
  {
    mainCategory: "Payments",
    icon: <DollarOutlined />,
    options: [
      {
        key: "5",
        label: "Collect Payment",
        icon: <GatewayOutlined />,
        path: "/merchant/stripe_connect",
      },
      {
        key: "6",
        label: "Payment Methods",
        icon: <CreditCardOutlined />,
        path: "/billing/payment_methods",
      },
    ],
  },
  {
    mainCategory: "Stores",
    icon: <ShopOutlined />,
    options: [
      {
        key: "7",
        label: "Create Store",
        icon: <ProfileOutlined />,
        path: "/catalogue/list",
      },
      {
        key: "8",
        label: "Categories",
        icon: <ProfileOutlined />,
        path: "/catalogue/categories",
      },
      {
        key: "9",
        label: "Items",
        icon: <ProfileOutlined />,
        path: "/catalogue/items",
      },
      {
        key: "10",
        label: "Modifiers",
        icon: <ProfileOutlined />,
        path: "/catalogue/modifiers",
      },
    ],
  },
  {
    mainCategory: "Business",
    icon : <img src={businessicon} style={{width:"15px"}}/>,
    options: [
      {
        key: "11",
        label: "Business Terms",
        icon: <ProfileOutlined />,
        path: "/account/business_terms",
      },
      {
        key: "12",
        label: "Business Logo",
        icon: <PicLeftOutlined />,
        path: "/account/upload_logo/main",
      },
      {
        key: "13",
        label: "QR Logo",
        icon: <QrcodeOutlined />,
        path: "/account/upload_logo/qr",
      },
      {
        key: "14",
        label: "Locations",
        icon: <BankOutlined />,
        path: "/account/venue/manage",
      },
      {
        key: "15",
        label: "Offer Summary",
        icon: <PieChartOutlined />,
        path: "/account/offers_summary",
      },
      {
        key: "16",
        label: "My Appointment",
        icon: <img src={myAppointment} alt="" />,
        path: "/business/appointment",
      },
    ],
  },
  {
    mainCategory: "Profile",
    icon : <img src={profileicon} style={{width:"14px"}}/>,
    options: [
      {
        key: "17",
        label: "Update Profile",
        icon: <ProfileOutlined />,
        path: "/preferences/profile",
        params: { mode: "update" },
      },
      {
        key: "18",
        label: "Change Password",
        icon: <LockOutlined />,
        path: "/preferences/password",
      },
    ],
  },
  {
    mainCategory: "Reports",
    // icon: <FileDoneOutlined />,
    icon : <img src={reportsicon}/>,
    options: [
      {
        key: "19",
        label: "Terms Accepted",
        icon: <UnorderedListOutlined />,
        path: "/reports/terms",
      },
    ],
  },
  {
    key: "20",
    label: "Dispute",
    icon: <img src={disputeicon} alt="" />,
    path: "/dispute",
  },
] : [
  {
    key: "1",
    label: "My Orders",
    icon: <UnorderedListOutlined />,
    path: "/billing/transactions",
  },
  {
    mainCategory: "Payments",
    icon: <DollarOutlined />,
    options: [
      {
        key: "5",
        label: "Collect Payment",
        icon: <GatewayOutlined />,
        path: "/merchant/stripe_connect",
      },
      {
        key: "6",
        label: "Payment Methods",
        icon: <CreditCardOutlined />,
        path: "/billing/payment_methods",
      },
    ],
  },
]

const sidemenuOptionsInd = [
  {
    key: "1",
    label: "My Orders",
    icon: <UnorderedListOutlined />,
    path: "/billing/transactions",
  },
  // {
  //   key: "2",
  //   label: "Interactions",
  //   icon: <TeamOutlined />,
  //   path: "/interactions",
  // },
  {
    mainCategory: "Profile",
    icon : <img src={profileicon} style={{width:"14px"}}/>,
    options: [
      {
        key: "3",
        label: "Update Profile",
        icon: <ProfileOutlined />,
        path: "/preferences/profile",
      },
      {
        key: "4",
        label: "Change Password",
        icon: <ProfileOutlined />,
        path: "/preferences/password",
      },
    ],
  },
  {
    mainCategory: "Billing",
    icon: <DollarOutlined />,
    options: [
      {
        key: "5",
        label: "Payment Methods",
        icon: <CreditCardOutlined />,
        path: "/billing/payment_methods",
      },
    ],
  },
];
const sidemenuOptionsIndMsqMgr = [
  {
    key: "1",
    label: "My Orders",
    icon: <UnorderedListOutlined />,
    path: "/billing/transactions",
  },
  // {
  //   key: "2",
  //   label: "Interactions",
  //   icon: <TeamOutlined />,
  //   path: "/interactions",
  // },
  {
    mainCategory: "Profile",
    icon : <img src={profileicon} style={{width:"14px"}} />,
    options: [
      {
        key: "3",
        label: "Update Profile",
        icon: <ProfileOutlined />,
        path: "/preferences/profile",
      },
    ],
  },
];
const sidemenuOptionsIndMsq = [
  {
    key: "1",
    label: "My Orders",
    icon: <UnorderedListOutlined />,
    path: "/billing/transactions",
  },
  // {
  //   key: "2",
  //   label: "Interactions",
  //   icon: <TeamOutlined />,
  //   path: "/interactions",
  // },
  {
    mainCategory: "Profile",
    icon : <img src={profileicon} style={{width:"14px"}} />,
    options: [
      {
        key: "3",
        label: "Update Profile",
        icon: <ProfileOutlined />,
        path: "/preferences/profile",
      },
    ],
  },
  {
    mainCategory: "Billing",
    icon: <DollarOutlined />,
    options: [
      {
        key: "5",
        label: "Payment Methods",
        icon: <CreditCardOutlined />,
        path: "/billing/payment_methods",
      },
    ],
  },
];
const sidemenuOptionsFyndr = [
  {
    key: "1",
    label: "Dashboard",
    icon: <UnorderedListOutlined />,
    path: "/admin/dashboard",
  },
  {
    key: "2",
    label: "User Details",
    icon: <TeamOutlined />,
    path: "/admin/customers",
  },
  {
    key: "8",
    label: "User Management",
    icon: <RiShieldUserLine style={{ fontSize: "18px" }} />,
    path: "/admin/management",
  },
  {
    key: "10",
    label: "Registration Records",
    icon: <BookOutlined/>,  
    path:"/admin/registration-records"
  },
  // commented due to static UI
  {
    key:"9",
    label: "Promo codes", 
    icon : <SoundOutlined/>,
    path : "/admin/promo"
  },
  {
    key: "3",
    label: "Campaign",
    icon: <img src={campaignicon} />,
    path: "/admin/dashboard/campaign",
  },
  {
    key: "4",
    label: " Revenue",
    icon : <img src={revenueicon}/>,
    path: "/admin/dashboard/revenue",
  },
  {
    key: "5",
    label: "Dispute",
    icon: <img src={maskgroup} alt="dispute" />,
    path: "/admin/dispute",
  },
  {
    key: "6",
    label: "Platform Variables",
    icon: <img src={platformVar} alt="platformVar" />,
    path: "/admin/platformvariable",
  },
  {
    key: "7",
    label: "Reviews  Reports",
    icon: <img src={exclamation} alt="exclamation" />,
    path: "/admin/comments&reports",
  },
];
const sidemenuOptionsMgr = [
  {
    key: "1",
    label: "Dashboard",
    icon: <UnorderedListOutlined />,
    path: "/admin/dashboard",
  },
  {
    key: "2",
    label: "User Details",
    icon: <TeamOutlined />,
    path: "/admin/customers",
  },
  {
    key: "3",
    label: "Campaign ",
    icon : <img src={campaignicon}/>,
    path: "/admin/dashboard/campaign",
  },
  {
    key: "4",
    label: " Revenue",
    icon : <img src={revenueicon} />,
    path: "/admin/dashboard/revenue",
  },
  {
    key: "5",
    label: "Dispute",
    icon: <img src={maskgroup} alt="dispute" />,
    path: "/admin/dispute",
  },
  {
    key: "6",
    label: "Reviews  Reports",
    icon: <img src={exclamation} alt="exclamation" />,
    path: "/admin/comments&reports",
  },
];

const sidemenuOptionsSuprt = [
  {
    key: "1",
    label: "Dashboard",
    icon: <UnorderedListOutlined />,
    path: "/admin/dashboard",
  },
  {
    key: "2",
    label: "User Details",
    icon: <TeamOutlined />,
    path: "/admin/customers",
  },
  {
    key: "3",
    label: "Campaign ",
    icon: <img src={campaignicon} />,
    path: "/admin/dashboard/campaign",
  },
  {
    key: "4",
    label: "Dispute",
    icon: <img src={maskgroup} alt="dispute" />,
    path: "/admin/dispute",
  },
  {
    key: "5",
    label: "Reviews  Reports",
    icon: <img src={exclamation} alt="exclamation" />,
    path: "/admin/comments&reports",
  },
];

const sidemenuOptionsBizMasq = [
  {
    key: "1",
    label: "My Orders",
    icon: <UnorderedListOutlined />,
    path: "/billing/transactions",
  },
  {
    mainCategory: "Campaigns",
    icon: <ShoppingCartOutlined />,
    // path: "/campaign",
    options: [
      {
        key: "2",
        label: "Create Campaign",
        icon: <ProfileOutlined />,
        path: "/campaign",
        params: { mode: "createCampaign" },
      },
      {
        key: "3",
        label: "Campaigns",
        icon: <ProfileOutlined />,
        path: "/campaign",
        params: { mode: "campaigns" },
      },
    ],
  },
  // {
  //   key: "4",
  //   label: "Interactions",
  //   icon: <TeamOutlined />,
  //   path: "/interactions",
  // },
  {
    mainCategory: "Payments",
    icon: <DollarOutlined />,
    options: [
      {
        key: "5",
        label: "Collect Payment",
        icon: <GatewayOutlined />,
        path: "/merchant/stripe_connect",
      },
    ],
  },
  {
    mainCategory: "Stores",
    icon: <ShopOutlined />,
    options: [
      {
        key: "7",
        label: "Create Store",
        icon: <ProfileOutlined />,
        path: "/catalogue/list",
      },
      {
        key: "8",
        label: "Categories",
        icon: <ProfileOutlined />,
        path: "/catalogue/categories",
      },
      {
        key: "9",
        label: "Items",
        icon: <ProfileOutlined />,
        path: "/catalogue/items",
      },
      {
        key: "10",
        label: "Modifiers",
        icon: <ProfileOutlined />,
        path: "/catalogue/modifiers",
      },
    ],
  },
  {
    mainCategory: "Business",
    icon : <img src={businessicon} style={{width:"15px"}}/>,
    options: [
      {
        key: "11",
        label: "Business Terms",
        icon: <ProfileOutlined />,
        path: "/account/business_terms",
      },
      {
        key: "12",
        label: "Business Logo",
        icon: <PicLeftOutlined />,
        path: "/account/upload_logo/main",
      },
      {
        key: "13",
        label: "QR Logo",
        icon: <QrcodeOutlined />,
        path: "/account/upload_logo/qr",
      },
      {
        key: "14",
        label: "Locations",
        icon: <BankOutlined />,
        path: "/account/venue/manage",
      },
      {
        key: "15",
        label: "Offer Summary",
        icon: <PieChartOutlined />,
        path: "/account/offers_summary",
      },
      {
        key: "16",
        label: "My Appointment",
        icon: <img src={myAppointment} alt="" />,
        path: "/business/appointment",
      },
    ],
  },
  {
    mainCategory: "Profile",
    icon : <img src={profileicon} style={{width:"14px"}}/>,
    options: [
      {
        key: "16",
        label: "Update Profile",
        icon: <ProfileOutlined />,
        path: "/preferences/profile",
        params: { mode: "update" },
      },
    ],
  },
  {
    mainCategory: "Reports",
    icon: <FileDoneOutlined />,
    options: [
      {
        key: "17",
        label: "Terms Accepted",
        icon: <UnorderedListOutlined />,
        path: "/reports/terms",
      },
    ],
  },
  {
    key: "18",
    label: "Dispute",
    icon: <img src={maskgroup} alt="" />,
    path: "/dispute",
  },
];
const sidemenuOptionsBizMasqSuprt = [
  {
    mainCategory: "Campaigns",
    icon: <ShoppingCartOutlined />,
    // path: "/campaign",
    options: [
      {
        key: "1",
        label: "Create Campaign",
        icon: <ProfileOutlined />,
        path: "/campaign",
        params: { mode: "createCampaign" },
      },
      {
        key: "2",
        label: "Campaigns",
        icon: <ProfileOutlined />,
        path: "/campaign",
        params: { mode: "campaigns" },
      },
    ],
  },
  {
    mainCategory: "Payments",
    icon: <DollarOutlined />,
    options: [
      {
        key: "53",
        label: "Collect Payment",
        icon: <GatewayOutlined />,
        path: "/merchant/stripe_connect",
      },
    ],
  },
  {
    mainCategory: "Stores",
    icon: <ShopOutlined />,
    options: [
      {
        key: "4",
        label: "Create Store",
        icon: <ProfileOutlined />,
        path: "/catalogue/list",
      },
      {
        key: "5",
        label: "Categories",
        icon: <ProfileOutlined />,
        path: "/catalogue/categories",
      },
      {
        key: "6",
        label: "Items",
        icon: <ProfileOutlined />,
        path: "/catalogue/items",
      },
      {
        key: "7",
        label: "Modifiers",
        icon: <ProfileOutlined />,
        path: "/catalogue/modifiers",
      },
    ],
  },
  {
    mainCategory: "Business",
    icon : <img src={businessicon} style={{width:"15px"}} />,
    options: [
      {
        key: "8",
        label: "Business Terms",
        icon: <ProfileOutlined />,
        path: "/account/business_terms",
      },
      {
        key: "9",
        label: "Business Logo",
        icon: <PicLeftOutlined />,
        path: "/account/upload_logo/main",
      },
      {
        key: "10",
        label: "QR Logo",
        icon: <QrcodeOutlined />,
        path: "/account/upload_logo/qr",
      },
      {
        key: "11",
        label: "Locations",
        icon: <BankOutlined />,
        path: "/account/venue/manage",
      },
    ],
  },
  {
    mainCategory: "Profile",
    icon : <img src={profileicon} style={{width:"14px"}}/>,
    options: [
      {
        key: "12",
        label: "Update Profile",
        icon: <ProfileOutlined />,
        path: "/preferences/profile",
        params: { mode: "update" },
      },
    ],
  },
  {
    key: "18",
    label: "Dispute",
    icon: <img src={maskgroup} alt="" />,
    path: "/dispute",
  },
] ;
const sidemenuOptionsIndMsqSuprt = [
  {
    mainCategory: "Profile",
    icon : <img src={profileicon} style={{width:"14px"}} />,
    options: [
      {
        key: "1",
        label: "Update Profile",
        icon: <ProfileOutlined />,
        path: "/preferences/profile",
      },
    ],
  },
];
const getSideMenu = (biz, role, isMasquerade, entityRole, roleType) => {
  if (role === ROLE_FYNDR && entityRole === ROLE_MANAGER) {
    return sidemenuOptionsMgr;
  } else if (role === ROLE_FYNDR && entityRole === ROLE_SUPPORT) {
    return sidemenuOptionsSuprt;
  } else if (roleType === ROLE_SUPPORT) {
    return biz ? sidemenuOptionsBizMasqSuprt : sidemenuOptionsIndMsqSuprt;
  } else if (role === ROLE_FYNDR) {
    return sidemenuOptionsFyndr;
  } else if (roleType === ROLE_MANAGER) {
    return biz ? sidemenuOptionsBizMasq : sidemenuOptionsIndMsqMgr;
  } else if (isMasquerade) {
    return biz ? sidemenuOptionsBizMasq : sidemenuOptionsIndMsq;
  } else {
    return biz ? sidemenuOptionsBiz : sidemenuOptionsInd;
  }
  // switch (role) {
  //   case ROLE_FYNDR: {
  //     return sidemenuOptionsFyndr;
  //   }
  //   default: {
  //     return biz ? sidemenuOptionsBiz : sidemenuOptionsInd;
  //   }
  // }
};

const Nav = (props) => {
  
  const [selectedKeys, setSelectedKeys] = useState("1");
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { collapsedNav } = useSelector(({ settings }) => settings);
  const history = useHistory();
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const dispatch = useDispatch();
  const onToggleCollapsedNav = () => {
    dispatch({ type: TOGGLE_COLLAPSED_NAV, isCollapsedNav:isMobile() ? collapsedNav : !collapsedNav });
  };
  const {
    roleType,
    indv: { fname, biz, accountStatus, entityType, entityRole },
  } = useSelector(({ auth }) => auth);
  // const {
  //   roleType
  // } = useSelector(({ fin }) => fin);

  const { isMasquerade } = useSelector(({ admin }) => admin);

  // const SideMenuOption = biz ? sidemenuOptionsBiz : sidemenuOptionsInd;

  const SideMenuOption = getSideMenu(
    biz,
    entityType,
    isMasquerade,
    entityRole,
    roleType
  );

  // const SideMenuOption = biz ? sidemenuOptionsBiz : sidemenuOptionsInd;
  // const collapsedWidth = offCanvasNav ? 0 : 80;
  const dotClass = classNames({
    "dot-green": accountStatus === "ACTIVE",
    "dot-red": accountStatus !== "ACTIVE",
  });
  const activeinactiveClass = classNames({
    green: accountStatus === "ACTIVE",
    red: !accountStatus !== "ACTIVE",
  });
  const menuClass = classNames({
    "bg-danger-fyndr": isMasquerade,
    "bg-info": !isMasquerade,
  });

  const cardContent = [
    "You can view transactions related to your account like transactions made in the past their respective details",
    "You can view details related to campaigns from here. It has 2 subcategories. You can create new campaigns and view already created campaigns",
    "Interactions lets you see customer interactions with stores",
    "The payments tab contains options like collect payment and payment methods.",
    "You can create stores, view store categories, items and modifiers in the stores tab",
    "This is business tab and it contains details related to you business like terms, business logo, QR code etc.",
    "You can update details related to your profile here in the profile tab",
    "The reports tab contains report related to your customers",
    "You can see disputes raised by customers here."
  ]
useEffect(()=>{
if(entityType === ROLE_FYNDR){
  dispatch({ type: TOGGLE_COLLAPSED_NAV, isCollapsedNav:true});
}
},[entityType])
  const tourConfig = SideMenuOption.map((item, index) => {
    if (item.mainCategory) {
      if(item.mainCategory == 'Campaigns'){
        return{
          selector:`[sideNavTour="${item.mainCategory}"]`,
          content: ()=>(
            <div>
              <span>{cardContent[index]}</span>
              <img src={campaignTour} />
            </div>
          )
        }
      }
      if(item.mainCategory === 'Payments'){
        return {
          selector:`[sideNavTour="${item.mainCategory}"]`,
          content: ()=>(
            <div>
              <span>{cardContent[index]}</span>
              <img src={paymentTour} />
            </div>
          )
        }
      }
      if(item.mainCategory === 'Stores'){
        return {
          selector:`[sideNavTour="${item.mainCategory}"]`,
          content: ()=>(
            <div>
              <span>{cardContent[index]}</span>
              <img src={storeTour} />
            </div>
          )
        }
      }
      if(item.mainCategory === 'Business'){
        return {
          selector:`[sideNavTour="${item.mainCategory}"]`,
          content: ()=>(
            <div>
              <span>{cardContent[index]}</span>
              <img src={businessTour} />
            </div>
          )
        }
      }
      if(item.mainCategory === 'Profile'){
        return {
          selector:`[sideNavTour="${item.mainCategory}"]`,
          content: ()=>(
            <div>
              <span>{cardContent[index]}</span>
              <img src={profileTour} />
            </div>
          )
        }
      }
      if(item.mainCategory === 'Reports'){
        return {
          selector:`[sideNavTour="${item.mainCategory}"]`,
          content: ()=>(
            <div>
              <span>{cardContent[index]}</span>
              <img src={reportsTour} />
            </div>
          )
        }
      }
      return {
        selector: `[sideNavTour="${item.mainCategory}"]`,
        content: `Explore ${item.mainCategory}. ${cardContent[index]}`,
      };
    } else {
      return {
        selector: `[sideNavTour="${item.label}"]`,
        content: `${cardContent[index]}`,
      };
    }
  });
  

  const closeTour = () => {
    setIsOpen(false);
  };

  const skipTour = () => {
    setIsOpen(false);
  };
  useEffect(() => {
      const storedTab = localStorage.getItem("selectedTab") || "1";
      setSelectedKeys(storedTab);
      return () => {
        localStorage.removeItem("selectedTab");
      };
      
    }, [SideMenuOption]);

  useEffect(() => {
    const currentPath = history.location.pathname;
    const matchingTab = sidemenuOptionsFyndr.find(item => item.path === currentPath);
    if (matchingTab) {
      setSelectedKeys(matchingTab?.key);
    }

  }, [sidemenuOptionsFyndr, history?.location?.pathname]);

  useEffect(() => {
    const isTourOpen = JSON.parse(localStorage.getItem("tourSideNav"));
    if (isTourOpen !== null) {
      setIsOpen(isTourOpen);
    } else {
      localStorage.setItem("tourSideNav", JSON.stringify(true));
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("tourSideNav", JSON.stringify(isOpen));
    // if(currentStep===4){
    //   setIsOpen(false);
    // }
  }, [isOpen]);

  
  const handleMenuClick = (item) => {
    setSelectedKeys(item.key);
    
    localStorage.setItem("selectedTab", item.key);
    props.history.push(item.path, item.params);
    if (item?.params?.mode === "createCampaign") {
      dispatch({ type: TOGGLE_CREATE_CAMPAIGN, value: "createCampaign" });
    } else {
      dispatch({
        type: TOGGLE_CREATE_CAMPAIGN,
        value: "campaignList",
      });
    }
  
  };


  return (
    <>
    {
    (entityType === "BUSINESS" && entityRole=== "BIZ_ADMIN" && !isMasquerade && biz)
     && 
    <Tour
    steps={tourConfig}
    isOpen={isOpen}
    onRequestClose={closeTour}
    showCloseButton={true}
    showSkipButton={true}
    onAfterOpen={() => setIsOpen(true)}
    onRequestSkip={skipTour}
    getCurrentStep={() => currentStep}
    />
    }
    <Sider
      // collapsible

      width={280}
      theme="light"
      collapsed={collapsedNav}
      collapsedWidth={80}
      // hidden={isMobile() && collapsedNav ? true : false}
      mode="inline"
    >
      <div
      // style={{ backgroundColor: '#3F9AF7' }}
      >
        <Row justify="space-between" align="middle">
          <Row
            justify={!collapsedNav && "space-between"}
            align="center"
            style={{
              padding: "0.5rem 0.5rem 0.5rem 1.5rem",
              width: "100%",
              borderBottom: "1px solid #EEE",
              fontSize: collapsedNav && "0.8rem",
            }}
          >
            <div>
              <Text strong>Welcome {fname}</Text>

              <Row
                onClick={() => {
                  props.history.push({
                    pathname: "/preferences/profile",
                    // state: {

                    //   label: "Update Profile",
                    // },
                  });
                }}
                gutter={[10]}
                style={{ marginTop: "1rem", cursor: "pointer", paddingLeft:isMobile() ? "18px" : "7px" }}
                // justify="space-evenly"
              >
                <Col span={!collapsedNav && 4}>
                  <div className={!biz ? `dot dot-green` : `dot ${dotClass}`} />
                </Col>
                {!collapsedNav && (
                  <Col style={{ alignContent: "baseline" }} span={10}>
                    <span
                      className={
                        !biz ? `status-green` : `status-${activeinactiveClass}`
                      }
                    >
                      {!biz
                        ? "Active"
                        : accountStatus === "ACTIVE"
                        ? "Active"
                        : "Inactive"}
                    </span>
                  </Col>
                )}
                {!collapsedNav && biz && (
                  <Col span={10}>
                    <Popover content={"Toggle between the status "}>
                      <i
                        style={{ color: Theme.blue, marginLeft: ".5rem" }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </Popover>
                  </Col>
                )}
              </Row>
            </div>
            <div
              onClick={onToggleCollapsedNav}
              style={{ cursor: "pointer", justifyContent: "center", paddingLeft:isMobile() ? "18px" : "7px"}}
            >
              {collapsedNav && (
                <RightSquareOutlined
                  style={{ fontSize: "1.5rem", color: "#3F9AF7" }}
                />
              )}
              {!collapsedNav && (
                <LeftSquareOutlined
                  style={{ fontSize: "1.5rem", color: "#3F9AF7" }}
                />
              )}
            </div>
          </Row>
        </Row>
        <Menu
          theme="light"
          // defaultSelectedKeys={["0"]}
          // selectedKeys={[1]}
          mode="inline"
          className="side_nav_right_border"
          inlineCollapsed={collapsedNav}
          selectedKeys={[selectedKeys]}
        >
          {SideMenuOption.map((item) =>
            item.mainCategory ? (
              <SubMenu
                // sideNavTour="target"
                sideNavTour={item.mainCategory}
                key={item.mainCategory}
                title={item.mainCategory}
                icon={item.icon}
              >
                {item.options.map((item) => (
                  <Menu.Item
                    onClick={() =>history.push( handleMenuClick(item))}
                    icon={item.icon}
                    key={item.key}
                    sideNavTour={item.label}
                  >
                    {item.label}
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item
                onClick={() => handleMenuClick(item)}
                icon={item.icon}
                key={item.key}
                sideNavTour={item.label}
              >
                {item.label}
              </Menu.Item>
            )
          )}
        </Menu>
      </div>
    </Sider>
    </>
  );
};

export default Nav;
