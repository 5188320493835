import React from "react";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { motion } from "framer-motion";

const Logo = (props) => {
  let history = useHistory();
  const { collapsed } = props;
  let LogoClass = cx({
    logo_white: !collapsed,
    logo_no_text: collapsed,
  });
  return (
    <motion.div
      whileHover={{ scale: 1.2 }}
      className="bizHome_logo"
    >
      <a href="/" rel="noopener noreferrer">
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push("/");
        }}
        className={LogoClass}
      />
      </a>
    </motion.div>
  );
};

export default Logo;
