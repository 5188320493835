import React, { useEffect, useState } from "react";
import "./appointment.scss";
import { Row, Col, Button, Space, Divider } from "antd";
import { useSelector } from "react-redux";
import CalendarBackIcon from "../../assets/images/CalendarBackIcon.png";
import actions from "../../actions";
import { isMobile, parseAddress } from "../../common/util";
import moment from "moment";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function BusinessAppointment(props) {
  const { indv } = useSelector(({ auth }) => {
    return auth;
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [locationDropdownData, setLocationDropdownData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [count, setCount] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth());
  const [slotCapacity, setSlotCapacity] = useState();
  const [highlightedDate, setHighlightedDate] = useState(null);

  const getDaysInMonth = (year, month) =>
    new Date(year, month + 1, 0).getDate();
  const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getDay();

  useEffect(() => {
    locationDataApi();
    setMonth(new Date().getMonth() + 1);
  }, []);

  useEffect(() => {
    fetchAppointmentCount();
  }, [month, selectedValue]);

  const locationDataApi = async () => {
    let res = await actions.fetchCmpnLocation(indv.bizid);
    const resultArray = res
      .filter((item) => item.isCatalogueAppointmentEnabled || item.isCampaignAppoinmtnetEnabled)
      .map((obj) => {
        const label = parseAddress(obj);
        return {
          ...obj,
          value: obj?.objid,
          label: obj?.locName,
        };
      });
    if (props?.locId) {
      setSelectedValue(props?.locId);
    } else {
      setSelectedValue(resultArray[0].objid);
    }
    setLocationDropdownData(resultArray);
    setLocationDropdownData(resultArray);
  };

  const fetchAppointmentCount = async () => {
    const payload = {
      businessId: indv?.bizid,
      locationId: selectedValue,
      month: month,
      year: new Date().getFullYear(),
    };
    let response = await actions.fetchAppointmentCount(payload);

    if (response?.success === true) {
      setCount(response?.appointmentMonthCountDTOList);
      setSlotCapacity(response?.slotCapacity);
    }
  };

  const handleDayClick = (day) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(day);
    setSelectedDate(newDate);
    const formattedDate = newDate.toISOString().split("T")[0];
    props.onDateClick && props.onDateClick(formattedDate, selectedValue);
    setHighlightedDate(day);
  };

  const handleMonthClick = (month, index) => {
    const newDate = new Date(selectedDate);
    const monthValue = (index + 1)?.toString()?.padStart(2, "0");
    setMonth(monthValue);
    newDate.setMonth(index);
    setSelectedDate(newDate);
  };

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    selectedValue !== "" && fetchAppointmentCount();
  }, [month, selectedValue]);
  const generateCalendar = () => {
    if (!selectedDate || typeof selectedDate.getMonth !== "function") {
      return null;
    }
    const daysInMonth = getDaysInMonth(
      selectedDate.getFullYear(),
      selectedDate.getMonth()
    );
    const firstDayOfMonth = getFirstDayOfMonth(
      selectedDate.getFullYear(),
      selectedDate.getMonth()
    );
    const blanks = Array.from({ length: firstDayOfMonth });
    const days = Array.from({ length: daysInMonth }, (_, index) => index + 1);
    const calendarDays = [...blanks, ...days];
    return calendarDays.map((day, index) => {
      const formattedDate = `${selectedDate?.getFullYear()}-${(
        selectedDate?.getMonth() + 1
      )
        ?.toString()
        ?.padStart(2, "0")}-${day?.toString()?.padStart(2, "0")}`;
      const appointmentCount = count.find(
        (item) => item.date === formattedDate
      )?.count;

      const percTotalBooking = (
        bookedAppointmentsPerSlot,
        totalAppointmentsPerSlot
      ) => {
        return (bookedAppointmentsPerSlot / totalAppointmentsPerSlot) * 100;
      };
      const bookingPercentage = percTotalBooking(
        appointmentCount,
        slotCapacity
      );

      const backgroundColor = () => {
        if (!isNaN(bookingPercentage)) {
          if (parseInt(bookingPercentage) < 50) {
            return "#50B85A";
          } else if (
            parseInt(bookingPercentage) >= 50 &&
            parseInt(bookingPercentage) < 100
          ) {
            return "#FFC700";
          } else if (parseInt(bookingPercentage) >= 100) {
            return "#ED0C10";
          } else {
            return "none";
          }
        }
      };
      return (
        <>
          {!isMobile() ? (
            <div
              key={index}
              className={`day ${
                day === selectedDate.getDate() ? "selected" : ""
              }`}
              onClick={() => day !== undefined && handleDayClick(day)}
              style={{ border: day !== undefined ? "1px solid #d3d6e1" : "" }}
            >
              {day !== undefined && (
                <>
                  <Col>{day}</Col>
                  <div
                    className="countAppointment"
                    style={{ backgroundColor: backgroundColor() }}
                  >
                    <span style={{ color: "white" }}>{appointmentCount}</span>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div
              key={index}
              className={`dayMobile ${
                day === selectedDate.getDate() ? "selected" : ""
              }`}
              onClick={() => day !== undefined && handleDayClick(day)}
              style={{ border: day !== undefined ? "1px solid #d3d6e1" : "" }}
            >
              {day !== undefined && (
                <>
                  <Row justify={"center"}>
                    <Col xs={24} style={{ height: "9px" }}></Col>
                    <Col
                      xs={24}
                      sm={24}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      {day}
                    </Col>
                    <Col xs={24} style={{ height: "5px" }}></Col>
                    <Col
                      xs={24}
                      sm={24}
                      style={{
                        backgroundColor: backgroundColor(),
                        borderRadius: "0px 0px 5px 5px",
                      }}
                    >
                      {" "}
                      <span
                        style={{
                          color: "white",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {appointmentCount}
                      </span>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}
        </>
      );
    });
  };

  return (
    <div className="calendar-container">
      <Row className="header">
        <Col
          span={2}
          style={{ display: "flex", justifyContent: "center" }}
          className="calButton"
        >
          <div className="btnCal">
            <Button
              size="large"
              onClick={() => props.buttonClick()}
              style={{ border: "none" }}
              icon={
                <img className="CalendarBackIcon" src={CalendarBackIcon}></img>
              }
            />
          </div>
        </Col>
        <Col
          className="overFlowHidden"
          span={21}
          style={{ overflowX: "scroll", paddingTop: "5px" }}
        >
          <Row>
            <Space>
              {months.map((month, index) => (
                <Col
                  span={20}
                  key={index}
                  className={`month ${
                    index === selectedDate.getMonth() ? "selected" : ""
                  }`}
                  onClick={() => handleMonthClick(month, index)}
                >
                  <div className="monthName">{month}</div>
                </Col>
              ))}
            </Space>
          </Row>
        </Col>
      </Row>
      <Divider
        className="customize_divider"
        style={{ margin: "0px", margin: "0px 0px 24px 0px" }}
      />
      <div className="calendarView">
        <div style={{ padding: "16px" }}>
          <div className="cal">
            <select
              id="dropdown"
              value={selectedValue}
              onChange={handleDropdownChange}
              className="dropDown"
            >
              {locationDropdownData.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {/* <Row > */}
          <div className="daysWeek">
            {daysOfWeek.map((day) => (
              <Col
                span={3}
                key={day}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="dayName">{day}</div>
              </Col>
            ))}
          </div>
          {/* </Row> */}
          <div className="days">{generateCalendar()}</div>
        </div>

        <Row className="slotInstruction">
          <Col
            style={{
              paddingTop: "3px",
              paddingRight: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                borderRadius: "100%",
                background: "#ED0C10",
                width: "16px",
                height: "16px",
                verticalAlign: "middle",
                display: "flex",
              }}
            ></div>
          </Col>
          <Col style={{ marginRight: "10px" }}>All slots are full</Col>
          <Col style={{ paddingTop: "3px", paddingRight: "10px" }}>
            <div
              style={{
                borderRadius: "100%",
                background: "#FFC700",
                width: "16px",
                height: "16px",
              }}
            ></div>
          </Col>
          <Col style={{ marginRight: "10px" }}>
            <div>Almost all slots are full</div>
          </Col>
          <Col style={{ paddingTop: "3px", paddingRight: "10px" }}>
            <div
              style={{
                borderRadius: "100%",
                background: "#50B85A",
                width: "16px",
                height: "16px",
              }}
            ></div>
          </Col>
          <Col>
            <div>Few slots are booked</div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default BusinessAppointment;
