import {
  Row,
  Col,
  Breadcrumb,
  Typography,
  Input,
  Space,
  Select,
  Divider,
  List,
  Card,
  Switch,
  Pagination,
  Empty,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import "../../../sass/styles/fyndr/_promocodes.scss";
import { useHistory } from "react-router-dom";
import promocode2 from "../../../assets/images/PromoCode2.png";
import { SearchOutlined } from "@material-ui/icons";
import { EditOutlined } from "@ant-design/icons";
import actions from "../../../actions";
import { getFormattedDtNew } from "../../../common/util";
import moment from "moment";
import { useSelector } from "react-redux";
const { Text } = Typography;

const PromoCode = () => {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const [activePromoCode, setActivePromoCode] = useState();
  const [expiredPromoCode, setExpiredPromoCode] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [nodata, setNodata] = useState(false);

  const getPromos = async () => {
    setLoading(true);
    let resp = await actions.FetchPromoCodes({ text: searchTerm });
    if (resp.success === true) {
      if (resp.resp?.length === 0) {
        setNodata(true);
      } else {
        setNodata(false);
      }
      setActivePromoCode(resp?.resp);
    } else {
      console.log("Error fetching data");
    }
    setLoading(false);
  };

  const getExpiredPromos = async (text) => {
    setLoading(true);
    const { current, pageSize } = pagination;
    let response = await actions.FetchExpiredPromoCodes({
      pgStart: current,
      pgSize: pageSize,
      text: searchTerm,
    });

    if (response.success === true) {
      setExpiredPromoCode(response?.resp.promocodesList);
      setPagination({ ...pagination, total: response?.resp.count });
    } else {
      console.log("error fetching data");
    }
    setLoading(false);
  };

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    setSearchTerm(searchValue);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handlePageChange = (page, pageSize) => {
    setPagination({ ...pagination, current: page, pageSize: pageSize });
  };
  useEffect(() => {
    getExpiredPromos();
  }, [pagination.current, pagination.pageSize, searchTerm]);
  useEffect(() => {
    getPromos();
  }, [searchTerm]);

  const handleSelectChange = (e) => {
    if (e === "registration") {
      history.push("/admin/promo/registration", {
        mode: "add",
        type: "registration",
      });
    } else if (e === "promotion") {
      history.push("/admin/promo/promotion", { mode: "add" });
    } else if (e === "redeem_promocode") {
      history.push("/admin/promo/registration", {
        mode: "add",
        type: "redeem",
      });
    }
  };

  const handleStatusChange = async (checked, promo) => {
    setLoading(true);

    const updatedPromo = {
      promCodeStatus: checked ? "ACTIVE" : "INACTIVE",
    };

    try {
      const resp = await actions.updatePromoCode(promo.id, updatedPromo);

      if (resp.success) {
        message.success(
          `Promo code ${promo.promoCode} status updated to ${
            checked ? "ACTIVE" : "INACTIVE"
          }`
        );
        getPromos();
      } else {
        message.error(resp.msg || "Failed to update promo code status");
      }
    } catch (error) {
      message.error("An error occurred while updating the status");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
      <div className="quickLoader">
        <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
      </div>

      <article className="article">
        <Row justify="space-between">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Promo Code</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <div
          style={{
            background: "#fff",
            marginTop: "2rem",
            padding: "24px 24px 24px 24px",
          }}
        >
          <Row>
            <Col span={10}>
              <span className="promo-heading">Active Promo Codes</span>
            </Col>
            <Col
              span={14}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Space>
                <Input
                  value={searchValue}
                  onChange={handleInputChange}
                  onPressEnter={handleSearch}
                  style={{ borderRadius: "10px", height: "46px" }}
                  placeholder="Search"
                  suffix={
                    <SearchOutlined
                      style={{
                        color: "#999999",
                        height: "2rem",
                        width: "2rem",
                      }}
                    />
                  }
                />
                <Select
                  className="promo-drop-down"
                  style={{ width: "200px" }}
                  onChange={handleSelectChange}
                  placeholder="Create promo"
                  options={[
                    {
                      value: "registration",
                      label: "For Registration",
                    },
                    {
                      value: "redeem_promocode",
                      label: "For wallet",
                    },
                    // {
                    //   value: "promotion",
                    //   label: "For Promotion",
                    // },
                  ]}
                />
              </Space>
            </Col>
          </Row>
          <div style={{ marginTop: "2rem" }} className="horizontal-div-active">
            <div className="promo-cards-container" gutter={[16, 16]}>
              {nodata ? (
                <Col span={24}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Col>
              ) : (
                activePromoCode?.map((promo, index) => (
                  <div
                    className="activepromo"
                    style={{
                      background:
                        promo.status === "ACTIVE"
                          ? "#fff"
                          : promo.status === "UPCOMING"
                          ? "#ffffcc"
                          : "#f0f0f0",
                      filter:
                        promo.status === "ACTIVE"
                          ? "none"
                          : promo.status === "UPCOMING"
                          ? "none"
                          : "grayscale(100%)",

                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <div className="promo-buttons">
                      <button
                        className="edit123-button"
                        style={{
                          background: "#aebdc980",
                          borderRadius: "100%",
                        }}
                        onClick={() => {
                          if (promo.promoCodeType === "REGISTRATION") {
                            history.push("/admin/promo/registration", {
                              mode: "update",
                              promocode: promo.promoCode,
                              type: "registration",
                            });
                          } else if (promo.promoCodeType === "PROMOTION") {
                            history.push("/admin/promo/promotion", {
                              mode: "update",
                            });
                          } else if (
                            promo.promoCodeType === "REDEEM_PROMOCODE"
                          ) {
                            history.push("/admin/promo/registration", {
                              mode: "update",
                              promocode: promo.promoCode,
                              type: "redeem",
                            });
                          }
                        }}
                      >
                        {" "}
                        <EditOutlined
                          style={{ zIndex: "999", color: "#fff" }}
                        />{" "}
                      </button>
                      {promo.status !== "UPCOMING" && (
                        <button className="active123-button">
                          <Switch
                            defaultChecked={
                              promo.status === "ACTIVE" ? true : false
                            }
                            style={{ background: "#30D158", zIndex: "999" }}
                            onChange={(checked) =>
                              handleStatusChange(checked, promo)
                            }
                          />
                        </button>
                      )}
                    </div>
                    <img
                      className="active-promo-image"
                      src={promo.imageUrl}
                      alt="promocodes"
                    />
                    <div style={{ padding: "12px" }}>
                      <Row>
                        <Col span={24}>
                          <Text
                            style={{
                              color: "#257cdb",
                              fontFamily: "Roboto",
                              fontSize: "16px",
                              fontWeight: "500",
                              lineHeight: "18.75px",
                              textAlign: "left",
                            }}
                            className="active-promo-heading"
                          >
                            {promo.promoCode}
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Text
                            style={{
                              fontFamily: "Roboto",
                              fontSize: "13.5px",
                              fontWeight: "400",
                              lineHeight: "20px",
                              textAlign: "left",
                              color: "#666666",
                            }}
                            className="active-promo-date"
                          >
                            Valid till :{" "}
                            {moment
                              .tz(promo.endDate, promo.timeZone)
                              .format(" hh:mm a, D MMM, YYYY")}
                          </Text>
                        </Col>
                        <Col span={24}>
                        <Text
                          style={{
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            fontWeight: "400",
                            lineHeight: "20px",
                            textAlign: "left",
                            color: "#666666",
                          }}
                          className="active-promo-date"
                        >
                         Time Zone: {promo.timeZone}
                        </Text>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          <Divider style={{ background: "#D3D6E1", margin: "12px 0px" }} />

          <div>
            <Text className="history-heading">History</Text>
            <List
              itemLayout="horizontal"
              className="list-history"
              dataSource={expiredPromoCode || []}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    className="history-card"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid #D3D6E1",
                    }}
                  >
                    <List.Item.Meta
                      avatar={
                        <img
                          src={promocode2}
                          alt="promo"
                          style={{
                            width: "74px",
                            height: "74px",
                            borderRadius: "10px",
                          }}
                        />
                      }
                      description={
                        <div
                          style={{
                            padding: "12px 16px 0px 0px",
                            height: "74px",
                          }}
                        >
                          <Row>
                            <Col span={12}>
                              <Text
                                style={{ color: "#257cdb" }}
                                className="active-promo-heading"
                              >
                                {item.promoCode}
                              </Text>
                            </Col>
                            <Col
                              span={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Text className="history-content">
                                {item.description}
                              </Text>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "1.7vh" }}>
                            <Col span={12}>
                              <Text className="active-promo-date">
                                Expired on:{" "}
                                {moment
                              .tz(item.endDate, item.timeZone)
                              .format(" D MMM, YYYY")}
                              </Text>
                            </Col>
                            <Col
                              span={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Text className="active-promo-date">
                                Users Registered: {item.userRegistered}
                              </Text>
                            </Col>
                          </Row>
                        </div>
                      }
                    />
                  </Card>
                </List.Item>
              )}
            />
          </div>
          <Row justify="center" style={{ marginTop: "20px" }}>
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={handlePageChange}
              showSizeChanger
              pageSizeOptions={["10", "20", "50", "100"]}
              onShowSizeChange={handlePageChange}
            />
          </Row>
        </div>
      </article>
    </div>
  );
};

export default PromoCode;
