import React from "react";
import { Button, Row, Col, Image, Typography } from "antd";
import { isMobile } from "../../common/util";
const Text = Typography;
export default function AboutUsRight({ title, content, image, buttonText,buttonClick }) {
  return (
    <>
      {title &&
        <div>
          {!isMobile() ? (
            <Row justify={"center"}>
              <Col xs={24} sm={24} md={7} lg={6} xl={6} xxl={6}>
                <Image
                  src={image}
                  alt={title}
                  preview={false}
                  style={{ height: "23rem", width: "25rem", objectFit: "contain" }}
                />
              </Col>
              <Col className="aboutUs_aboutFyndrImg" xs={24} sm={24} md={13} lg={14} xl={12} xxl={13}>
                <div className="unicode">&#9886;</div>
                <h1 style={{ color: "#257CDB" }}>{title}</h1>
                <p className="pararAboutus"  style={{ fontSize: "1.4rem", fontWeight: "500",color: "#333333"}}>
                  {content}
                </p>
                <div>
                  <Button onClick={buttonClick}  type="primary" style={{ margin: "10px 0 0 0", padding: "10px, 20px, 10px, 20px",
                borderRadius:"5px", width:"142px", height:"46px", fontSize:"16px"
                }}>
                    {buttonText}
                  </Button>
                </div>
              </Col>
            </Row>
          ) : (
            <Row justify={"center"} style={{}} className="aboutUs_fyndrHelp">
              <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} offset={2} className="aboutUs_fyndrHelpText">
                <div className="unicode">&#9886;</div>
                <h1 style={{ color: "#257CDB" }}>{title}</h1>
                <Text style={{ fontSize: "1.4rem", fontWeight: "500",color: "#333333" }}>
                  {content}
                </Text>
              </Col>
              <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0} className="aboutUs_fyndrHelpImg">
                <Image
                  src={image}
                  alt={title}
                  preview={false}
                  style={{ height: "25rem", width: "25rem", objectFit: "contain" }}
                />
              </Col>
              <Col>
                <div>
                  <Button  onClick={buttonClick} type="primary" style={{ margin: "10px 0 0 0" }}>
                    {buttonText}
                  </Button>
                </div></Col>
            </Row>
          )}
        </div>
      }
    </>
  );
}