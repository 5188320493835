/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React from "react";
import moment from "moment";
import "moment-timezone";
import { days } from "../constants/lists";
import constants from "./ActionsStrings";
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
export const getTotal = (rec) => {
  return (
    Number(rec.baseAmount) +
    Number(!isNaN(rec.taxAmount) ? rec.taxAmount : 0) +
    (rec.tipAmount ? Number(rec.tipAmount) : 0) -
    Number(rec.discountAmount)
  ).toFixed(2);
};

export const status_list = [
  { value: "redeemed", display: "Fully Redeemed" },
  { value: "partially-redeemed", display: "Partially Redeemed" },
];
export const getDisplayStatus = (status) => {
  try {
    if (status === "unused") return "Unused";
    else return status_list.filter((row) => row.value === status)[0]["display"];
  } catch (err) {
    return "";
  }
};

export const getDateNew= (tm, timeZone) =>{
  var value = moment.tz(tm, timeZone);
  return value.utc().format("MM-DD-YYYY");
}

export const getFormattedDt = (tm) => {
  let timeZone = moment.tz.guess(true);
  if (!tm) return "";

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    timeZone,
  };
  return new Intl.DateTimeFormat("locale", options).format(new Date(tm));
};

export const getFormattedDtNew = (tm, timeZone) => {
  var value = moment.tz(tm, timeZone);
  return value.utc().format("MMMM DD, YYYY");
};
export const getCurrentDateTime = () => {
  let timeZone = moment.tz.guess(true);
  const options = {
    year: "2-digit",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone,
  };
  return new Intl.DateTimeFormat("locale", options).format(new Date());
};
export const getFormattedTm = (tm) => {
  let timeZone = moment.tz.guess(true);
  if (!tm) return "";

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone,
  };
  return new Intl.DateTimeFormat("locale", options).format(new Date(tm));
};
export const getFormattedTmNew = (tm, timeZone) => {
  var value = moment.tz(tm, timeZone);
  return value.utc().format(" h:mm A");
};
export const getFormatedDateTime = (tm) => {
  let timeZone = moment.tz.guess(true);
  if (!tm) return "";

  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone,
  };

  return new Intl.DateTimeFormat("locale", options).format(new Date(tm));
};
export const getFormattedDateTimeNew = (tm, timeZone) => {
  var value = moment.tz(tm, timeZone);
  return value.utc().format("MM-DD-YYYY, h:mm A");
};

export const filterEvents = (events, days, searchTxt) => {
  return events.filter((row) => {
    let flag = true;
    if (searchTxt) {
      flag = false;
      const { loc } = row;
      const locStr = loc ? `${loc.name} ${loc.city} ${loc.postalCode}` : "";
      const str = `${row.name} ${row.bizName} ${locStr}`;
      flag = flag || str.toLowerCase().indexOf(searchTxt.toLowerCase()) !== -1;
    }
    if (days) {
      const time_diff = new Date() - new Date(row.time).getTime();
      const days_diff = Math.ceil(time_diff / (1000 * 3600 * 24));
      flag = flag && days_diff <= days;
    }
    return flag;
  });
};

export const parseEvents = (rcpts) => {
  let indv = [];
  let biz = [];

  rcpts.forEach((row) => {
    if (row.bizName) {
      let bizRow = biz.filter((brow) => brow.qrId === row.qrId);
      const key = `${row.loc.name} - ${row.loc.city}`;
      if (bizRow.length === 0) {
        biz.push({ qrId: row.qrId, locs: [key] });
      } else {
        let locs = bizRow[0]["locs"];
        locs.push(key);
        locs = [...new Set(locs)];
        biz = biz.filter((brow) => brow.qrId !== row.qrId);
        biz.push({ qrId: row.qrId, locs });
      }
    } else {
      indv.push(row.qrId);
    }
  });
  indv = [...new Set(indv)];

  return {
    totalReceivers: indv.length + biz.length,
    indv,
    biz,
  };
};

export const parseAddress = (addr) => {
  return addr
    ? `${addr?.addressLine1 !== null ? addr?.addressLine1 : ""}${
        addr?.addressLine2 !== null && addr?.addressLine2 !== undefined && addr?.addressLine2 !== ""
          ? ", " + addr?.addressLine2
          : ""
      }${addr?.city !== null ? ", " + addr?.city + ", " : ""} ${addr?.state} ${
        addr?.postalCode
      }`
    : "";
};

export const parseTerms = (data) => {
  const terms = [];
  data.forEach((row) => {
    const dt = getFormattedDt(row.displayDate);
    const terms_with_flag = [];
    Array.isArray(row.termsContent) &&
      row.termsContent.forEach((row) => {
        terms_with_flag.push({ ...row, selected: true });
      });
    //also add default list if draft
    // if(row.status === 'draft') {
    //   default_list.forEach(row => {
    //     terms_with_flag.push({...row, selected: false});
    //   })
    // }

    terms[row.status] = {
      status: row.status,
      objid: row.objid,
      list: terms_with_flag,
      presentment: row.presentment,
      storage: row.consentStorage,
      updatedDt: dt,
      remark: row.remark,
      displayTerms: row.displayTerms,
    };
  });
  return terms;
};

export const makeWebstr = (list) => {
  let str = "";
  list.forEach((row) => {
    if (row.selected) {
      str +=
        "<div style='margin-top: 20px'><strong>" +
        row.title +
        "</strong></div>\n";
      str +=
        "<div>" + row.content.replace(/(\r\n|\n|\r)/gm, "<br>") + "</div>\n";
    }
  });
  return str;
};
export const stringToHTML = (row) => {
  // var dom = document.createElement('div');
  // dom.innerHTML = row;
  return React.createElement("div", null, row);
};
export const last14 = () => {
  let timeZone = moment.tz.guess(true);
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour12: true,
    timeZone,
  };
  let list = [];
  const dateOffset = 24 * 60 * 60 * 1000; //milis in a day
  const today = new Date();
  for (let i = 0; i < 14; i++) {
    const tm = today.getTime() - i * dateOffset;
    const str = Intl.DateTimeFormat("locale", options).format(new Date(tm));
    list.push({ label: str, value: str });
  }
  return list;
};
export const decodeValidity = (validityPeriod) => {
  const period =
    validityPeriod === "CMPN"
      ? "CMPN"
      : validityPeriod.substring(
          validityPeriod.length - 1,
          validityPeriod.length
        );
  const no =
    validityPeriod === "CMPN"
      ? null
      : validityPeriod.substring(0, validityPeriod.length - 1);
  const names = { D: "day", W: "week", M: "month" };
  const validTill =
    period === "CMPN"
      ? "Valid till campaign end date"
      : `Valid for ${no} ${names[period]}/s after purchase`;
  return { period, no, validTill };
};

export const isMobile = () => {
  return window.innerWidth < 500 ? true : false;
};

export const isTab = () => {
  return window.innerWidth < 768 ? true : false;
};

export const getTiming12 = (str) => {
  try {
    const pair = str.trim().split(" ");
    const tm_arr = pair[1].split(",").map((row) => {
      const parts = row.split("-");
      const start_time = parts[0];
      const end_time = parts[1];
      if (end_time !== "00:00") {
        // return `${convertTo12(start_time)} to ${convertTo12(end_time)}`;
        return `${moment(start_time, "HH:mm").format("hh:mm A")} to ${moment(
          end_time,
          "HH:mm"
        ).format("hh:mm A")}`;
      }
    });
    return { day: days[pair[0]], tm: tm_arr };
  } catch (err) {
    return null;
  }
};
export const convertTo12 = (str) => {
  const tm_grp = str.split(":");
  let hr = tm_grp[0];
  const min = tm_grp[1];
  let suffix = "am";
  if (Number(hr) === 12) {
    return "12 noon";
  } else if (Number(hr) === 24) {
    return "12:00 am";
  } else if (Number(hr) > 12) {
    suffix = "pm";
    hr = (Number(hr) - 12 + "").padStart(2, "0");
  }
  return `${hr}:${min} ${suffix}`;
};

export const postalPattern = {
  GB: "GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\\d{1:4}",
  JE: "JE\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}",
  GG: "GY\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}",
  IM: "IM\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}",
  US: "\\d{5}([ \\-]\\d{4})?",
  CA: "[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d",
  DE: "\\d{5}",
  JP: "\\d{3}-\\d{4}",
  FR: "\\d{2}[ ]?\\d{3}",
  AU: "\\d{4}",
  IT: "\\d{5}",
  CH: "\\d{4}",
  AT: "\\d{4}",
  ES: "\\d{5}",
  NL: "\\d{4}[ ]?[A-Z]{2}",
  BE: "\\d{4}",
  DK: "\\d{4}",
  SE: "\\d{3}[ ]?\\d{2}",
  NO: "\\d{4}",
  BR: "\\d{5}[\\-]?\\d{3}",
  PT: "\\d{4}([\\-]\\d{3})?",
  FI: "\\d{5}",
  AX: "22\\d{3}",
  KR: "\\d{3}[\\-]\\d{3}",
  CN: "\\d{6}",
  TW: "\\d{3}(\\d{2})?",
  SG: "\\d{6}",
  DZ: "\\d{5}",
  AD: "AD\\d{3}",
  AR: "([A-HJ-NP-Z])?\\d{4}([A-Z]{3})?",
  AM: "(37)?\\d{4}",
  AZ: "\\d{4}",
  BH: "((1[0-2]|[2-9])\\d{2})?",
  BD: "\\d{4}",
  BB: "(BB\\d{5})?",
  BY: "\\d{6}",
  BM: "[A-Z]{2}[ ]?[A-Z0-9]{2}",
  BA: "\\d{5}",
  IO: "BBND 1ZZ",
  BN: "[A-Z]{2}[ ]?\\d{4}",
  BG: "\\d{4}",
  KH: "\\d{5}",
  CV: "\\d{4}",
  CL: "\\d{7}",
  CR: "\\d{4:5}|\\d{3}-\\d{4}",
  HR: "\\d{5}",
  CY: "\\d{4}",
  CZ: "\\d{3}[ ]?\\d{2}",
  DO: "\\d{5}",
  EC: "([A-Z]\\d{4}[A-Z]|(?:[A-Z]{2})?\\d{6})?",
  EG: "\\d{5}",
  EE: "\\d{5}",
  FO: "\\d{3}",
  GE: "\\d{4}",
  GR: "\\d{3}[ ]?\\d{2}",
  GL: "39\\d{2}",
  GT: "\\d{5}",
  HT: "\\d{4}",
  HN: "(?:\\d{5})?",
  HU: "\\d{4}",
  IS: "\\d{3}",
  IN: "\\d{6}",
  ID: "\\d{5}",
  IL: "\\d{5}",
  JO: "\\d{5}",
  KZ: "\\d{6}",
  KE: "\\d{5}",
  KW: "\\d{5}",
  LA: "\\d{5}",
  LV: "\\d{4}",
  LB: "(\\d{4}([ ]?\\d{4})?)?",
  LI: "(948[5-9])|(949[0-7])",
  LT: "\\d{5}",
  LU: "\\d{4}",
  MK: "\\d{4}",
  MY: "\\d{5}",
  MV: "\\d{5}",
  MT: "[A-Z]{3}[ ]?\\d{2:4}",
  MU: "(\\d{3}[A-Z]{2}\\d{3})?",
  MX: "\\d{5}",
  MD: "\\d{4}",
  MC: "980\\d{2}",
  MA: "\\d{5}",
  NP: "\\d{5}",
  NZ: "\\d{4}",
  NI: "((\\d{4}-)?\\d{3}-\\d{3}(-\\d{1})?)?",
  NG: "(\\d{6})?",
  OM: "(PC )?\\d{3}",
  PK: "\\d{5}",
  PY: "\\d{4}",
  PH: "\\d{4}",
  PL: "\\d{2}-\\d{3}",
  PR: "00[679]\\d{2}([ \\-]\\d{4})?",
  RO: "\\d{6}",
  RU: "\\d{6}",
  SM: "4789\\d",
  SA: "\\d{5}",
  SN: "\\d{5}",
  SK: "\\d{3}[ ]?\\d{2}",
  SI: "\\d{4}",
  ZA: "\\d{4}",
  LK: "\\d{5}",
  TJ: "\\d{6}",
  TH: "\\d{5}",
  TN: "\\d{4}",
  TR: "\\d{5}",
  TM: "\\d{6}",
  UA: "\\d{5}",
  UY: "\\d{5}",
  UZ: "\\d{6}",
  VA: "00120",
  VE: "\\d{4}",
  ZM: "\\d{5}",
  AS: "96799",
  CC: "6799",
  CK: "\\d{4}",
  RS: "\\d{6}",
  ME: "8\\d{4}",
  CS: "\\d{5}",
  YU: "\\d{5}",
  CX: "6798",
  ET: "\\d{4}",
  FK: "FIQQ 1ZZ",
  NF: "2899",
  FM: "(9694[1-4])([ \\-]\\d{4})?",
  GF: "9[78]3\\d{2}",
  GN: "\\d{3}",
  GP: "9[78][01]\\d{2}",
  GS: "SIQQ 1ZZ",
  GU: "969[123]\\d([ \\-]\\d{4})?",
  GW: "\\d{4}",
  HM: "\\d{4}",
  IQ: "\\d{5}",
  KG: "\\d{6}",
  LR: "\\d{4}",
  LS: "\\d{3}",
  MG: "\\d{3}",
  MH: "969[67]\\d([ \\-]\\d{4})?",
  MN: "\\d{6}",
  MP: "9695[012]([ \\-]\\d{4})?",
  MQ: "9[78]2\\d{2}",
  NC: "988\\d{2}",
  NE: "\\d{4}",
  VI: "008(([0-4]\\d)|(5[01]))([ \\-]\\d{4})?",
  PF: "987\\d{2}",
  PG: "\\d{3}",
  PM: "9[78]5\\d{2}",
  PN: "PCRN 1ZZ",
  PW: "96940",
  RE: "9[78]4\\d{2}",
  SH: "(ASCN|STHL) 1ZZ",
  SJ: "\\d{4}",
  SO: "\\d{5}",
  SZ: "[HLMS]\\d{3}",
  TC: "TKCA 1ZZ",
  WF: "986\\d{2}",
  XK: "\\d{5}",
  YT: "976\\d{2}",
};

export const timeAgo = (time) => {
  const templates = {
    prefix: "",
    suffix: " ago",
    seconds: "less than a minute",
    minute: "a minute",
    minutes: "%d minutes",
    hour: "an hour",
    hours: "%d hours",
    day: "a day",
    days: "%d days",
    month: "a month",
    months: "%d months",
    year: "a year",
    years: "%d years",
  };
  const template = function (t, n) {
    return templates[t] && templates[t].replace(/%d/i, Math.abs(Math.round(n)));
  };

  let timer = (time) => {
    if (!time) return;
    time = time.replace(/\.\d+/, ""); // remove milliseconds
    time = time.replace(/-/, "/").replace(/-/, "/");
    time = time.replace(/T/, " ").replace(/Z/, " UTC");
    time = time.replace(/([\+\-]\d\d)\:?(\d\d)/, " $1$2"); // -04:00 -> -0400
    time = new Date(time * 1000 || time);

    var now = new Date();
    var seconds = ((now.getTime() - time) * 0.001) >> 0;
    var minutes = seconds / 60;
    var hours = minutes / 60;
    var days = hours / 24;
    var years = days / 365;

    return (
      templates.prefix +
      ((seconds < 45 && template("seconds", seconds)) ||
        (seconds < 90 && template("minute", 1)) ||
        (minutes < 45 && template("minutes", minutes)) ||
        (minutes < 90 && template("hour", 1)) ||
        (hours < 24 && template("hours", hours)) ||
        (hours < 42 && template("day", 1)) ||
        (days < 30 && template("days", days)) ||
        (days < 45 && template("month", 1)) ||
        (days < 365 && template("months", days / 30)) ||
        (years < 1.5 && template("year", 1)) ||
        template("years", years)) +
      templates.suffix
    );
  };
  return timer(time);
};

export const getClosingTime = (str) => {
  if (!str) return null;
  try {
    const arr = str.split(";");
    let dt = new Date();
    const day = dt.getDay();
    const day_index = {
      1: "Mo",
      2: "Tu",
      3: "We",
      4: "Th",
      5: "Fr",
      6: "Sa",
      0: "Su",
    };
    const tm_str = arr.filter((row) => {
      return row.trim().substr(0, 2) === day_index[day];
    })[0];
    const closing_time = tm_str?.substr(tm_str.lastIndexOf("-") + 1);
    const pair = closing_time?.split(":");
    if (!Array.isArray(pair)) {
      return null;
    }
    const closing_hr = Number(pair[0]);
    const suffix = moment(closing_time, "HH:mm").format("hh:mm A");
    const disp_hr = (
      (closing_hr > 12 ? closing_hr - 12 : closing_hr) + ""
    ).padStart(2, "0");
    const closing_min = Number(pair[0]) * 60 + Number(pair[1]);
    const current_minutes = dt.getHours() * 60 + dt.getMinutes();
    return current_minutes > closing_min ? "Closed" : ` Open until ${suffix}`;
  } catch (err) {
    return null;
  }
};

export const getToday = () => {
  let timeZone = moment.tz.guess(true);
  const options = {
    month: "short",
    day: "2-digit",
    hour12: true,
    timeZone,
  };
  const today = new Date();
  return new Intl.DateTimeFormat("locale", options).format(
    new Date(today.getTime())
  );
};

export const getTomorrow = () => {
  let timeZone = moment.tz.guess(true);
  const options = {
    month: "short",
    day: "2-digit",
    hour12: true,
    timeZone,
  };
  const today = new Date();
  return new Intl.DateTimeFormat("locale", options).format(
    new Date(today.getTime() + 24 * 60 * 60 * 1000)
  );
};

export const findHomePathFromRole = (role) => {
  const { ROLE_FYNDR } = constants;
  switch (role) {
    case ROLE_FYNDR: {
      return "/admin/dashboard";
    }
    case "INDIVIDUAL": {
      return "/";
    }
    default:
      return "/billing/transactions";
  }
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "Anonymous";
    if (url.startsWith("data:image")) {
      image.src = url;
    } else {
      image.src = url + "?not-from-cache-please";
    }
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
  });
export async function getRotatedImage(imageSrc, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const orientationChanged =
    rotation === 90 ||
    rotation === -90 ||
    rotation === 270 ||
    rotation === -270;
  if (orientationChanged) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file));
    }, "image/png");
  });
}

export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);
  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}
export const getCroppedImg = async (
  imageSrc,
  pixelCrop,
  imageType,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) => {
  try {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Could not create canvas context");
    }

    const { width: imageWidth, height: imageHeight } = image;
    const { width: cropWidth, height: cropHeight, x, y } = pixelCrop;

    const rotRad = getRadianAngle(rotation);
    const { width: rotatedWidth, height: rotatedHeight } = rotateSize(
      imageWidth,
      imageHeight,
      rotation
    );

    canvas.width = rotatedWidth;
    canvas.height = rotatedHeight;
    ctx.translate(rotatedWidth / 2, rotatedHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.drawImage(image, -imageWidth / 2, -imageHeight / 2);
    const croppedCanvas = document.createElement("canvas");
    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      throw new Error("Could not create cropped canvas context");
    }

    croppedCanvas.width = cropWidth;
    croppedCanvas.height = cropHeight;

    croppedCtx.drawImage(
      canvas,
      x,
      y,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );
    return new Promise((resolve, reject) => {
      croppedCanvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Failed to convert canvas to blob"));
          return;
        }
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          resolve(base64String);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(blob);
      }, imageType);
    });
  } catch (error) {
    console.error("Error cropping image:", error);
    throw error;
  }
};

export const findHomePathFromRoleMGR = (role, type) => {
  const { ROLE_MANAGER, ROLE_SUPPORT } = constants;
  if (type === "BUSINESS" && role === ROLE_MANAGER) {
    return "/admin/dashboard";
  } else if (type !== "BUSINESS" && role === ROLE_MANAGER) {
    return "/admin/dashboard";
  } else if (type === "INDIVIDUAL" && role === ROLE_SUPPORT) {
    return "/preferences/profile";
  } else if (type !== "INDIVIDUAL" && role === ROLE_SUPPORT) {
    return "/campaign";
  } else {
    return "/billing/transactions";
  }
};
