import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Form,
  Input,
  Select,
  Radio,
  DatePicker,
  Button,
  Row,
  Col,
  Breadcrumb,
  Switch,
  message,
} from "antd";
import PulseLoader from "react-spinners/PulseLoader";
import ImageUploader from "./ImageUploader";
import { ArrowLeftOutlined } from "@ant-design/icons";
import moment from "moment";
import actions from "../../../actions";
import { useLocation } from "react-router-dom";
import UserActions from "../../../actions";
import "../../../sass/styles/fyndr/_promocodes.scss";
import { Co } from "react-flags-select";
import FormItem from "antd/es/form/FormItem";
import { useSelector } from "react-redux";
const { TextArea } = Input;

const PromoRegistration = () => {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [countries, setCountries] = useState();
  const [selectedPromo, setSelectedPromo] = useState();
  const [countryTimeZone, setCountryTimeZone] = useState();

  const location = useLocation();
  const { mode, promocode, type } = location.state || {};

  const handleSubmit = async (values) => {
    const amount = parseFloat(values.amount);
    const countryId = parseFloat(values.countryId);
    const startDateInPhoenix = values.startDate;
    const endDateInPheoneix = values.endDate;
    const base64Image = imageFile
      ? imageFile.replace(/^data:image\/[a-zA-Z]+;base64,/, "")
      : null;
    const payload = {
      ...values,
      timeZone: countryTimeZone,
      amount,
      countryId,
      promoCodeType: type === "redeem" ? "REDEEM_PROMOCODE" : "REGISTRATION",
      type: "FLAT",
      imageUrl: base64Image,
      startDate: startDateInPhoenix.format("YYYY-MM-DD"),
      endDate: endDateInPheoneix.format("YYYY-MM-DD"),
      privatePromocode:
        values.privatePromocode !== undefined ? values.privatePromocode : false,
    };
    setLoading(true);
    let resp = await actions.addPromo(payload);
    if (resp.success === true) {
      message.success(resp.resp.message);
      form.resetFields();
      history.push("/admin/promo");
    } else {
      message.error(resp.msg);
    }
    setLoading(false);
  };

  const handleUpdate = async (values) => {
    const amount = parseFloat(values.amount);
    const countryId = parseFloat(values.countryId);
    const startDateInPhoenix = values.startDate;
    const endDateInPheoneix = values.endDate;
    const base64Image = imageFile
      ? imageFile.replace(/^data:image\/[a-zA-Z]+;base64,/, "")
      : null;

    const payload = {
      ...values,
      timeZone: countryTimeZone,
      amount,
      countryId,
      // type: "FLAT",
      imageUrl: base64Image,
      startDate:
        mode === "update" && selectedPromo?.promoCodeStatus === "ACTIVE"
          ? null
          : startDateInPhoenix.format("YYYY-MM-DD"),
      endDate: endDateInPheoneix.format("YYYY-MM-DD"),
      privatePromocode: values.privatePromocode,
    };

    setLoading(true);
    let resp = await actions.updatePromoCode(selectedPromo.id, payload);

    if (resp.success === true) {
      message.success(resp?.msg);
      form.resetFields();
      history.push("/admin/promo");
    } else {
      message.error(resp?.msg);
    }
    setLoading(false);
  };

  const history = useHistory();
  const toggleComponent = () => {
    history.push("/admin/promo/promotion");
  };
  const GetCountryList = async () => {
    try {
      let response = await UserActions.GetCountryList();
      const filteredCountries = response.filter(
        (country) => country.name === "USA"
      );
      const options = filteredCountries.map((country) => ({
        value: country.objId,
        label: country.isoCode,
      }));
      setCountries(options);
    } catch (error) {
      console.log("error fetching country list");
    }
  };

  const handleCountryChange = (countryId) => {
    const selectedCountry = countries.find(
      (country) => country.value === countryId
    );
    if (selectedCountry && selectedCountry.label === "US") {
      setCountryTimeZone("America/Phoenix");
    } else {
      setCountryTimeZone();
    }
  };
  const getPromoById = async (promocode) => {
    setLoading(true);
    let resp = await actions.getPromoById(promocode);

    if (resp.success) {
      setSelectedPromo(resp?.data);
    } else {
      message.error(resp.msg);
    }
    setLoading(false);
  };
  useEffect(() => {
    GetCountryList();
    if (mode === "update" && promocode) {
      getPromoById(promocode);
    }
  }, [mode, promocode]);
  useEffect(() => {
    moment.tz.setDefault(countryTimeZone);
  }, [countryTimeZone]);
  moment.tz.setDefault(userTimeZone);
  useEffect(() => {
    if (selectedPromo) {
      form.setFieldsValue({
        promoCode: selectedPromo.promoCode,
        countryId: selectedPromo.country,
        targetUser: selectedPromo.targetUser,
        amount: selectedPromo.amount,
        startDate: moment.tz(selectedPromo.startDate, selectedPromo?.timeZone),
        endDate: moment.tz(selectedPromo.endDate, selectedPromo?.timeZone),
        description: selectedPromo.description,
        privatePromocode: selectedPromo.private,
      });
    }
  }, [selectedPromo, form]);

  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
      <div className="quickLoader">
        <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
      </div>

      <article className="article">
        <Row justify="space-between">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <Breadcrumb>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Promo code</Breadcrumb.Item>
              <Breadcrumb.Item>
                Create Promo for{" "}
                {type === "registration" ? "Registration" : "Wallet"}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col>
            <Button
              type="link"
              style={{ fontSize: "16px", color: "#000" }}
              onClick={() => history.push("/admin/promo")}
            >
              <ArrowLeftOutlined />
              Go Back
            </Button>
          </Col>
        </Row>
        <div style={{ padding: "24px", background: "#fff" }}>
          {/* <Row justify="end" style={{ marginBottom: 20 }}>
            <Switch
              style={{ background: "#223369" }}
              checkedChildren="Promotion"
              unCheckedChildren="Promotion"
              defaultChecked
              onChange={toggleComponent}
            />
          </Row> */}
          <Form
            form={form}
            layout="vertical"
            onFinish={mode === "update" ? handleUpdate : handleSubmit}
            style={{
              width: 500,
              margin: "0 auto",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <Form.Item
              className="form-reg"
              name="imageUrl"
              style={{ marginBottom: "12px" }}
            >
              <ImageUploader
                onFileSelect={setImageFile}
                imgUrl={selectedPromo?.imageUrl}
              />
            </Form.Item>

            <Form.Item
              name="promoCode"
              style={{ marginBottom: "12px" }}
              rules={[
                { required: true, message: "Promo code is required." },
                {
                  pattern: /^[A-Z0-9]+$/,
                  message:
                    "Promo code should be capital letters only, no spaces or special characters.",
                },
              ]}
            >
              <Input
                style={{ borderRadius: "10px", height: "46px" }}
                placeholder="Promo Code"
                disabled={mode === "update" ? true : false}
              />
            </Form.Item>

            <Form.Item
              name="countryId"
              style={{ marginBottom: "12px" }}
              rules={[{ required: true, message: "Please select a country" }]}
            >
              <Select
                style={{ borderRadius: "10px", height: "46px" }}
                options={countries}
                className="country-dropdown"
                disabled={mode === "update" ? true : false}
                placeholder="Select Country"
                onChange={handleCountryChange}
              />
            </Form.Item>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                style={{ marginBottom: "12px" }}
                name="targetUser"
                rules={[{ required: true, message: "Please select a type" }]}
              >
                <Radio.Group>
                  <Radio value="ALL">Both</Radio>
                  <Radio value="INDIVIDUAL">Individual</Radio>
                  <Radio value="BUSINESS">Business</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: "12px" }}
                name="privatePromocode"
                valuePropName="checked"
              >
                <Switch
                  className="public-private-switch"
                  style={{
                    background: "#ffffff",
                    border: "1px solid #257cdb",
                  }}
                  checked={form.getFieldValue("privatePromocode")}
                  unCheckedChildren="Public promo"
                  checkedChildren="Private promo"
                  onChange={(checked) => {
                    form.setFieldsValue({ privatePromocode: checked });
                  }}
                />
              </Form.Item>
            </div>

            <Form.Item
              style={{ marginBottom: "12px" }}
              name="amount"
              rules={[
                { required: true, message: "Fyndr Cash is required" },
                {
                  pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Fyndr Cash should be a valid number",
                },
              ]}
            >
              <Input
                style={{ borderRadius: "10px", height: "46px" }}
                placeholder="Fyndr Cash"
                prefix="$"
              />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  style={{ marginBottom: "12px" }}
                  name="startDate"
                  rules={[
                    { required: true, message: "Please select a start date" },
                  ]}
                >
                  <DatePicker
                    timeZone="America/Phoenix"
                    placeholder="From"
                    allowClear={false}
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      height: "46px",
                    }}
                    disabledDate={(current) => {
                      if (mode === "update") {
                        return (
                          current &&
                          current <
                            moment(form.getFieldValue("startDate", selectedPromo?.timeZone)).startOf(
                              "day"
                            )
                        );
                      }
                      return current && current < moment.tz(selectedPromo?.timeZone).startOf("day");
                    }}
                    disabled={
                      mode === "update" &&
                      selectedPromo?.promoCodeStatus === "ACTIVE"
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ marginBottom: "12px" }}
                  name="endDate"
                  dependencies={["startDate"]}
                  rules={[
                    { required: true, message: "Please select an end date" },
                  ]}
                >
                  <DatePicker
                    placeholder="To"
                    allowClear={false}
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      height: "46px",
                    }}
                    disabledDate={(current) => {
                      const startDate = form.getFieldValue("startDate");
                      return (
                        current && current <= moment(startDate).startOf("day")
                      );
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              style={{ marginBottom: "12px" }}
              name="description"
              rules={[{ required: true, message: "Description is required." }]}
            >
              <TextArea rows={4} placeholder="Add Description" />
            </Form.Item>

            <Form.Item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ borderRadius: "10px", width: "200px", height: "46px" }}
                type="primary"
                htmlType="submit"
                block
              >
                {mode === "update"
                  ? "Update Promo Code"
                  : "Generate Promo Code"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </article>
    </div>
  );
};

export default PromoRegistration;
