import { Col, Divider, Row } from "antd";
import React from "react";

import ReviewStars from "../ReviewStars/ReviewStars";
import CommentButtons from "./CommentButtons";
import AddImage from "../RatingChart/AddImage";
import { isTab } from "../../common/util";
import flagLight from "../../assets/images/ph_flag-light.svg";
import comment from "../../assets/images/commment.svg";
import flag from "../../assets/images/flag.svg"
import UserCommentText from "./UserCommentText";
import moment from "moment";
import AddImageSlider from "../RatingChart/AddImageSlider";
import { useSelector } from "react-redux";
const ReviewComments = (props) => {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  return (
    <>
      {!isTab() ? (
        <div
          className="reviewcomments_mainContainerRow-comment"
          style={{ marginTop: "1.5rem" }}
        >
          <Row>
            <Col span={24}>
              <Row>
                <div className="reviewcomments_userName">{`${props?.data?.user?.firstName}`} {`${props?.data?.user?.lastName?.[0] || ''}`}</div>

                <div className=" reviewcomments_ratingDuration">
                  {/* {moment.utc(props?.data.createdDt).local().format('YYYY-MM-DD HH:mm A')} */}
                  {moment.tz(props?.data.createdDt, userTimeZone).utc().format("YYYY-MM-DD, h:mm A")}
                </div>
              </Row>

              <div className="bigScreenStar">
                <ReviewStars
                  disabled={true}
                  value={props?.data?.rating}
                  rating={props?.data?.rating}
                />
              </div>
              <div className="reviewcomments_scrollControler">
                {props?.data?.images !== null &&
                  props?.data?.images?.length > 0 &&
                  props?.data.images.map((e) => {
                    return (
                      <div style={{ marginRight: "12px" }}>
                        <AddImage data={e} />
                      </div>
                    );
                  })}
              </div>
              <Row
                className="reviewcomments_comments"
                style={{ padding: "0 24px", margin: "2px 0 24px 0" }}
              >
                <UserCommentText
                  openParagraph={(data) => {
                    props.openParagraph(data)
                  }}
                  userText={props?.data?.review}
                  userReply={
                    props?.data?.commentThread
                  }
                />
              </Row>
              {props.indv === props.businessId && (
                <Row style={{ marginBottom: "2px" }}>
                  <>
                    <Col span={12}>
                      <CommentButtons
                        type="primary"
                        commnetOutLined={
                          <img
                            alt="msgIcon"
                            src={comment}
                            className="reviewcomments_commentBtnIcon"
                          />
                        }
                        buttonOnClick={props.setModalVisible}
                        buttonText="Reply"
                      />
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <CommentButtons
                        type="primary"
                        disabled={props.data?.isReportingAllowed !== true ? true : false}
                        commnetOutLined={
                          <img
                            alt="flag"
                            src={props.data?.isReportingAllowed === true ? flag : flagLight}
                            className={props.data?.isReportingAllowed === true ? "reviewcomments_commentBtnIcon" : "reviewcomments_commentBtnIconDisabled"}
                          />
                        }
                        buttonText={
                          props.data?.isReportingAllowed === true
                            ? "Report"
                            : "Reported"
                        }
                        buttonOnClick={props.reportModalVisibleClick}
                      />
                    </Col>
                  </>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{ marginTop: "2rem" }}>
          <div className="reviewcomments_mainContainerRow-mobile">
            <Row>
              <Col span={24}>
                <Row style={{ width: "100%" }}>
                  <div className="reviewcomments_userName-mobile">{`${props?.data?.user?.firstName}`} {`${props?.data?.user?.lastName?.[0] || ''}`}</div>

                  <div className=" reviewcomments_ratingDuration-mobile">
                    {moment(props?.data.createdDt, "YYYYMMDD").fromNow()}
                  </div>
                </Row>

                <div style={{ marginLeft: "12px" }}>
                  <ReviewStars rating={props?.data?.rating} disabled={true} />
                </div>

                <Divider
                  className="divider-mobile"
                  style={{ margin: "9px 0 12px 0" }}
                />

                {props?.data?.images !== null &&
                  props?.data?.images?.length > 0 && (
                    <div>
                      <AddImageSlider image={props?.data.images} />
                    </div>
                  )}

                <Row
                  style={{
                    paddingLeft: "8px",
                    paddingRight: "8px"
                  }}
                >
                  <UserCommentText
                    openParagraph={(data) => {
                      props.openParagraph(data)
                    }}
                    userText={props?.data?.review}
                    userReply={
                      props.indv === props.businessId &&
                      props?.data?.commentThread
                    }
                  />
                </Row>
                {props.indv === props.businessId && (
                  <Row style={{ marginBottom: "12px",width:"100%" }}>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <CommentButtons
                        type="primary"
                        commnetOutLined={
                          <img
                            alt="msgIcon"
                            src={comment}
                            className="reviewcomments_commentBtnIcon"
                          />
                        }
                        buttonOnClick={props.setModalVisible}
                        buttonText="Reply"
                      />
                    </Col>
                    <Col
                      span={12}
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <CommentButtons
                        type="primary"
                        disabled={props.data?.isReportingAllowed !== true ? true : false}
                        commnetOutLined={
                          <img
                            alt="flag"
                            src={props.data?.isReportingAllowed === true ? flag : flagLight}
                            className={props.data?.isReportingAllowed === true ? "reviewcomments_commentBtnIcon" : "reviewcomments_commentBtnIconDisabled"}
                          />
                        }
                        buttonOnClick={props.reportModalVisibleClick}
                        buttonText={props.data?.isReportingAllowed === true
                          ? "Report"
                          : "Reported"}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </div>
      )}


    </>
  );
};

export default ReviewComments;
