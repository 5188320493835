/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { detectIncognito } from "detectincognitojs";
import { Buffer } from "buffer";
import ReactPlayer from 'react-player'
import { useSelector, useDispatch } from "react-redux";
import { Input, Button, Typography, message, Row, Col, Tooltip } from "antd";
import Loader from "../../components/Loading/Loader";
import {
  FacebookOutlined,
  GoogleOutlined,
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined, EyeTwoTone
} from "@ant-design/icons";
import GoogleLogin from "react-google-login";
import Registration from "../Registration";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Controller, useForm } from "react-hook-form";
import { FormSchema } from "../../common";
import { yupResolver } from "@hookform/resolvers";
import UserAction from "../../actions";
import { encryptPassword } from "../../utils/Helper";
import getEnvVars from "../../environment";
import googleLogin from "../../assets/images/googleLogin.svg"
import { Actions } from "../../common";
import { findHomePathFromRole, isMobile } from "../../common/util";
import { generateToken } from "../../network/ApiManager";
import ModalIncognito from "../../components/ModalIncognito/ModalIncognito";
import { googleloginDetails, loginDetails, loginPageView } from "../../metaPixel";
import overlayWeb from "../../assets/videos/overlayWeb.mp4";
import mobileOverlay from "../../assets/videos/mobileOverlay.mp4"
import { GoAlertFill } from "react-icons/go";
import { email } from "../../common/config";
const { SET_ROUTE } = Actions;

const { Text } = Typography;
const {
  app: { facbookAppId, googleClientId },
} = getEnvVars();
var renderPropsVar;
const Login = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { LoginSchema } = FormSchema;
  const { route, indv } = useSelector(({ auth }) => auth);
  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const FormRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblePermission, setModalVisiblePermission] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [okClick, setOkClick] = useState(false);
  const onSubmit = async (data) => {

    let password = Array.from(await encryptPassword(data.email, data.password));
    setIsLoading(true);
    const response = await UserAction.classicLogin({
      email: data.email,
      pwd: password,
    });
    setIsLoading(false);
    if (!response.success) {
      message.error(response.msg.message || "Credentials could not be matched", 3);
    } else {
      // if(response?.googleCalendarPermissionGranted){
      //   onLoginSuccess(response.entityType);
      // }else{
      //   let res = await UserAction.googlePermissionCheck();
      //   console.log("what is googlePermissionCheck res 2", res);
      //   // setModalVisiblePermission(true);
      //   const googleLoginWindow = window.open('https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=1068896131755-vckn3h3sgl50i1or7ohn2m2hpfqco5dg.apps.googleusercontent.com&redirect_uri=https://api.dev.fyndr.us/appointment/googleCalendar/permission&response_type=code&scope=https://www.googleapis.com/auth/calendar%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile', '_blank', 'width=600,height=600');

      // }
      onLoginSuccess(response?.entityType)

    }
  };
  useEffect(() => {
    loginPageView()
  }, [])

  useEffect(() => {
    if (indv.indvid) {
      message.error("You are already logged in, please logout or try with different browser to proceed further for login")
      history.push("/")

    }

  }, []);

  const videoRef = useRef(null);
  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };
    document.addEventListener('click', playVideo);
    return () => {
      document.removeEventListener('click', playVideo);
    };
  }, []);

  const googleSignInSucceded = async (response) => {
    setIsLoading(true);
    const resp = await UserAction.getAccount({
      email: response.profileObj.email,
      regMode: "google",
    });
    // googleloginDetails({
    //   email: response.profileObj.email,
    //   regMode: "google",
    // })

    setIsLoading(false);
    if (resp.status === "exists") {

      const encodedString = Buffer.from(response?.accessToken).toString("base64");
      let respn = await generateToken({
        provider: "google",
        token: encodedString,
      }, resp?.entityType);

      if (resp?.entityType === "FYNDR") {
        localStorage.setItem("adminToken", respn.accessCode)
      }
      if (respn.success === true) {
        // console.log("data>>",respn,resp);
        // if(resp?.googleCalendarPermissionGranted){
        //   onLoginSuccess();
        // }else{
        //   let res = await UserAction.googlePermissionCheck();
        //   console.log("what is googlePermissionCheck res 2", res);
        //   // setModalVisiblePermission(true);
        //   const googleLoginWindow = window.open('https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=1068896131755-vckn3h3sgl50i1or7ohn2m2hpfqco5dg.apps.googleusercontent.com&redirect_uri=https://api.dev.fyndr.us/appointment/googleCalendar/permission&response_type=code&scope=https://www.googleapis.com/auth/calendar%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile', '_blank', 'width=600,height=600');

        // }
        onLoginSuccess();

      } else {
        message.error(respn.message || "error in login", 3)
      }
    } else {
      message.error(resp.message || "Error fetching account details");
    }
  };

  const googleSignInFailed = (info) => {
    const { error, details } = info;
    if (error !== "idpiframe_initialization_failed" && details) {
      message.error(details);
    }
    if (error === "popup_blocked_by_browser") {
      alert('Pop-up blocked by the browser. Please enable pop-ups for this site to log in with Google.');
    }
  };

  const responseFacebook = async (response) => {
    if (!response.email) {
      message.error("Facebook login didn't succeed");
    } else {
      setIsLoading(true);
      const resp = await UserAction.getAccount({
        email: response.email,
        regMode: "facebook",
      });
      setIsLoading(false);
      if (resp.status === "exists") {

        const encodedString = Buffer.from(response.accessToken).toString(
          "base64"
        );


        let respn = await generateToken({
          provider: "facebook",
          token: encodedString,
        });

        if (respn.success) {
          // if(response?.googleCalendarPermissionGranted){
          //   onLoginSuccess();
          // }else{
          //   let res = await UserAction.googlePermissionCheck();
          //   console.log("what is googlePermissionCheck res 2", res);
          //   // setModalVisiblePermission(true);
          //   const googleLoginWindow = window.open('https://accounts.google.com/o/oauth2/auth?access_type=offline&client_id=1068896131755-vckn3h3sgl50i1or7ohn2m2hpfqco5dg.apps.googleusercontent.com&redirect_uri=https://api.dev.fyndr.us/appointment/googleCalendar/permission&response_type=code&scope=https://www.googleapis.com/auth/calendar%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile', '_blank', 'width=600,height=600');

          // }

          onLoginSuccess()
        }
      }
      else if (resp.status === "new") {
        history.push('/register')
        // message.error("Error fetching account details");
      }
    }
  };

  const onLoginSuccess = async (role) => {
    // if (props?.location?.state?.locId) {
    //   history.push(`/qr/${props.location.state.locId}`, { isLogin: true });
    //   return;
    // }

    if (props?.location?.state?.route) {
      dispatch({ type: SET_ROUTE, payload: null });
      history.replace(props?.location?.state?.route, { catalogueDetail: props?.location?.state?.catalogueDetail });
    } else if (route) {
      dispatch({ type: SET_ROUTE, payload: null });
      history.replace(route)
    } else {

      // localStorage.setItem("adminToken",)

      //this function will find the path  as per the role
      let path = findHomePathFromRole(role)

      history.push(path);
    }
  };


  return (
    <>
      <div className="no-breadcrumb container-mw-lg chapter video-section" style={{ alignContent: "center", display: "flex", justifyContent: "center"}}>
        <video
          ref={videoRef}
          className="bg-video"
          loop
          muted
          autoPlay
          playsInline
          webkit-playsinline
          style={{display:!isVideoReady ? "none":"flex"}}
          onCanPlayThrough={() => setIsVideoReady(true)}
        >
          <source src={isMobile() ? mobileOverlay : overlayWeb} type="video/mp4" />
        </video>
        {!isVideoReady ?
          <div style={{ display: "flex", alignItems: "center",height:"100vh" }}>  <Loader /></div>
          :
          <div className="form-card__body login_div" style={{ width: isMobile() ? "100%" : "70%", margin: "10px" }}>
            <h1 style={{ color: "rgba(255, 255, 255, 1)", marginBottom: "12px" }}>
              Welcome Back!
            </h1>
            <Text className="login_text">
              Log in to access your account and continue exploring amazing offers,
              events, and services.
            </Text>
            <section className="login_box" >
              <Row
                justify="start"
                style={{
                  fontSize: isMobile() ? "12px" : "14px", flexDirection: "column", display: "flex",
                  textAlignLast: "left"
                }}
              >
                <h2 style={{ color: "rgba(255, 255, 255, 1)", marginBottom: "12px" }}>
                  Login to Continue
                </h2>
                <Row>
                  <Col span={12}>
                  <Text style={{ color: "#B3B3B3", fontWeight: "400", marginBottom: isMobile() ? "12px" : "24px" }}>
                  Don't have an account?{"  "}
                  <span style={{ color: "#257CDB", fontWeight: "500", cursor: "pointer", textDecoration: "underline", fontSize: isMobile() ? "14px" : "16px" }} onClick={() => history.push("/register", { from: history.location.pathname })}>Sign up</span>
                </Text>
                  </Col>
                
                </Row>
                
              </Row>
              <form
                onSubmit={handleSubmit(onSubmit)}
                ref={FormRef}
                className="login"
              >
                <Controller
                  className="login_btn"
                  style={{ marginBottom: "12px" }}
                  as={
                    <Input
                      size="large"
                      autoFocus
                      placeholder="Email"
                      prefix={errors.email ? <Tooltip placement="bottomRight" title={errors.email?.message} color="#fff" overlayInnerStyle={{ color: "black" }}><GoAlertFill style={{ color: "red", marginRight:"4px" }}/></Tooltip> : null} 
                      style={{border: `1px solid ${
                        errors.email ? "red" : "rgba(230, 230, 230, 1)"
                      }`,}}
                      status={errors.email ? "error" : ""} 
                    />
                  }
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                />
                <Controller
                  className="login_btn "
                  style={{ marginBottom: "12px" }}
                  as={
                    <Input.Password
                      size="large"
                      autoFocus
                      placeholder="Password"
                      prefix={errors.password ? <Tooltip placement="bottomRight" title={errors.password?.message} color="#fff" overlayInnerStyle={{ color: "black" }}><GoAlertFill style={{ color: "red", marginRight:"4px" }}/></Tooltip> : null} 
                      status={errors.password ? "error" : ""} 
                    />
                  }
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  type="password"
                  name="password"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                />
                <Row style={{ width: "100%", marginTop: "24px" }} align={"middle"} justify={"space-between"}>
                  <Col xs={14} sm={14} md={16} lg={20} xl={20} xxl={20}>
                    <Button
                      disabled={isLoading}
                      type="primary"
                      htmlType="submit"
                      className="login_btn"
                    >
                      Log in {isLoading && <Loader />}
                    </Button>
                  </Col>
                  <Col>
                    <Text style={{ color: "#CCCCCC", textAlign: 'center', bottom: "0.4vw" }}>Or</Text>
                  </Col>
                  <Col xs={4} sm={4} md={2} lg={2} xl={2} xxl={2} align={"end"} style={{ alignSelf: "start" }}>
                    <GoogleLogin
                      responseType="token"
                      clientId={googleClientId}
                      scope="profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events"
                      onSuccess={googleSignInSucceded}
                      onFailure={googleSignInFailed}
                      cookiePolicy={"single_host_origin"}
                      ux_mode={"popup"}
                      prompt='select_account'
                      render={(renderProps) => (
                        <img src={googleLogin}
                          style={{ height: "44px", width: "44px", cursor: "pointer" }}
                          onClick={() => {
                            detectIncognito().then((result) => {
                              if (result.isPrivate) {
                                setModalVisible(true);
                                renderPropsVar = renderProps;

                              } else {
                                renderProps.onClick()
                              }
                            });

                          }}
                        >
                        </img>
                      )}
                    />
                  </Col>
                </Row>
              </form>
              <p
                onClick={() => {
                  props.history.push("/reset-password");
                }}
                className="forgot"
              >
                Forgot your password? <a href="" style={{ color: "#CCCCCC", textDecoration: "underline" }}>Reset password</a>
              </p>
            </section>
          </div>
        }

      </div>
      <ModalIncognito
        title="Private or Incognito Window"
        width="50%"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          setModalVisible(false)
          renderPropsVar.onClick()

        }}
        modalText="It seems you are using incognito or private web browser, you need to allow third party cookies in your browser's settings to sign in with google."
      />
    </>
  );
};

export default Login;
