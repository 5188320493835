/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";

import {
  Row,
  Select,
  Col,
  Steps,
  Input,
  Button,
  Switch,
  Modal,
  Table,
  message,
  Space,
  Typography,
  Form,
  Popover,
} from "antd";

import { useSelector, useDispatch } from "react-redux";
import Calendar from "react-calendar";
import {
  getFormattedDt,
  capitalize,
  getCroppedImg,
} from "../../common/util.js";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../components/Loading/Loader";
import { Actions } from "../../common";
import UserActions from "../../actions";
import { CheckOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { DocViewer, Uploader, RichTextEditor } from "../../components";
import OffersList from "./Offers/";
import { UploadOutlined } from "@ant-design/icons";
import { BsImage } from "react-icons/bs";
import ImageUploader from "./ImageUploader";
import getEnvVars from "../../environment";
import AddLocations from "../../pages/Venue/Edit.jsx";
import Theme from "../../common/Theme.js";
import ContainerWrapper from "../../components/ContainerWrapper.jsx";
import Crop from "../../components/Crop.jsx";
import HelmetFyndr from "../../components/HelmetFyndr";
import Video from "../../components/Video.jsx";
import "../../sass/styles/fyndr/_campaign.scss";
import DropdownComponent from "../../components/Dashboard/DropdownComponent.js";
import { Link } from "react-router-dom";

const { Text } = Typography;
const INITIAL_STEP = 0;
const FINAL_STEP = 6;
const { web } = getEnvVars();

const { Option } = Select;
const {
  UPSERT_OFFER,
  REMOVE_TYPE_SPECIFIC_DATA,
  PREVIEW_ITEM_UPDATE,
  END_DATE,
  UPDT_CMPN_IMG,
  UPDT_CMPN_VIDEO,
} = Actions;

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 16,
  },
};

const FormItem = Form.Item;

const Edit = (props) => {
  const { callback, onChange, current, nextStep, isEdit } = props;
  const dispatch = useDispatch();
  const { Step } = Steps;
  const {
    indv,
    indv: { indvid, bizid, locations, address, tags },
    settings: { campaign_goal, campaign_dc },
  } = useSelector(({ auth }) => {
    return auth;
  });
  const {
    cmpn,
    cmpn: { objid, cmpnImgs, offers, status, cmpnLocIds, dc, campaignVideo },
  } = useSelector(({ cmpn }) => cmpn);

  const cmpnTypes = ["offers", "events", "coupons", "brochure"];

  const currentCmpn = objid || Date.now();


  const [payload, setPayload] = useState({
    cmpnType: cmpn.cmpnType || cmpnTypes[0],
    goal: cmpn.goal,
    category: cmpn.categoryId,
    title: cmpn.title,
    startDt: (cmpn.startDt && new Date(cmpn.startDt)) || new Date(),
    endDt:
      (cmpn.endDt && new Date(cmpn.endDt)) ||
      new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
    descr: cmpn.descr,
    cmpnImgs,
    finePrint: "",
    cmpnUrl: cmpn.cmpnUrl,
    campaignVideo,
    tags: cmpn?.tags,
  });
  const userTags = cmpn?.tags ? cmpn?.tags?.split(",").map((item) => item) : [];
  const allTags = tags.split(",").map((item) => ({ value: item, label: item }));
  const [isStartDate, setIsStartDate] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [brochureUri, setBrochureUri] = useState();
  const [brochureName, setBrochureName] = useState();
  const [brochureDisplay, setBrochureDisplay] = useState();
  const [cmpnUrl, setCmpnUrl] = useState(cmpn.cmpnUrl);
  const [resetUploader, setResetUploader] = useState(false);

  const [allLocations, setAllLocation] = useState(locations);
  const [, setallCampDelivery] = useState(campaign_dc);
  const [locEnabled, setLocEnabled] = useState([]);
  const [, setIsUpdate] = useState(false);
  const [selectedDelivery, setSelectedDelivery] = useState([]);
  const [isActive, setIsActive] = useState(status === "active");
  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [videoIndex, setVideoIndex] = useState(-1);
  const images = [0, 1];
  const videos = [0];
  const [modalVisible, setModalVisible] = useState(false);
  const [, setLocalImgs] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [started, setStarted] = useState(false);
  const [details, setDetails] = useState(false);
  const [LocationIsEdit, setlocationIsEdit] = useState();
  const [imgEdit, setImgEdit] = useState("");
  const [isCrop, setIsCrop] = useState(false);
  const [fileType, setFileType] = useState("");
  const [tagsName, setTagsName] = useState(userTags);
  const [campaignGoal, setCampaignGoal] = useState(cmpn.goal);
  const [cmpnBookingEnabled , setCmpnBookingEnabled] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    showSizeChanger: true,
  });
  const [deleteModal, setDeleteModal] = useState(false);

  const handleRemoveVideo = (index) => {
    const payload = { video: "", thumbnailUri: "", videoUri: "", index };
    dispatch({ type: UPDT_CMPN_VIDEO, payload });
    setDeleteModal(false);
  };
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
    });
  };
  const categoryListArr = categoryList.map((row) => {
    return { value: row.objid, label: row.name };
  });
  const { LOCATION_SELECTED } = Actions;
  const selectLocation = async (item, type) => {
    const { objid, locName, qrCode, qrid } = item;
    await dispatch({
      type: LOCATION_SELECTED,
      payload: { location_id: objid, locName, qrcode: qrCode },
    });
    // setSelectedLocation(item);
    if (type === "view") {
      props.history.push(
        `/account/interaction/viewer/${objid}/${qrCode}/${qrid}`,
        {
          locName,
          type: "loc",
        }
      );
    } else {
      setIsLoading(true);
      const resp = await UserActions.getLocation(objid);
      if (resp && resp.success) {
        setlocationIsEdit(true);
        setDetails(resp.payload);
        setStarted(true);
      } else {
        message.error("Error getting location details..");
      }
      setIsLoading(false);
    }
  };
  
  useEffect(()=>{
    console.log("what is goal", campaignGoal);
  }, [campaignGoal]);

  useEffect(() => {
    const isBookingEnabled = offers.some((offer) => offer.isBookingEnabled === true);
    setCmpnBookingEnabled(isBookingEnabled);
  }, [offers]);


  const locationListColumns = [
    {
      title: "Select Location",
      dataIndex: "locName",
      key: "objid",
    },
  ];
  const tagNames = allTags.filter((tag) => !tagsName?.includes(tag.value));

  const getFileExtension = (url) => {
    return url.split(".").pop().toLowerCase();
  };

  useEffect(() => {
    dispatch({ type: REMOVE_TYPE_SPECIFIC_DATA });
    if (isEdit) {
      dispatch({
        type: END_DATE,
        payload: null,
      });
    }
  }, [payload.cmpnType]);

  useEffect(() => {
    getCampnList();
  }, []);
  useEffect(() => {
    let tempData = [];
    let tempData2 = [];

    let validLocation = locations?.filter(
      (row, key) => row.parentLocation === null
    );
    Array.isArray(locations) &&
      locations.map((item, index) => {
        item.key = index;
        tempData.push(item);
        return null;
      });

    campaign_dc.map((item, index) => {
      item.key = index;
      if (item.label !== "Send app notification") {
        tempData2.push(item);
      }
      return null;
    });
    setAllLocation(tempData);
    setallCampDelivery(tempData2);
  }, [locations, campaign_dc, locEnabled]);

  useEffect(() => {
    if (isEdit) {
      const { offers } = cmpn;

      setPayload({
        cmpnType: cmpn.cmpnType,
        goal: cmpn.goal,
        category: cmpn.categoryId,
        title: cmpn.title,
        startDt: cmpn.startDt && new Date(cmpn.startDt),
        endDt: cmpn.endDt && new Date(cmpn.endDt),
        descr: cmpn.descr,
        cmpnImgs,
        finePrint: cmpn.finePrint,
        cmpnUrl: cmpn.cmpnUrl,
        campaignVideo,
        tags,
      });

      setCmpnUrl(cmpn.cmpnUrl);
      setBrochureUri(cmpn.brochureUri);
      offers &&
        offers.forEach((item) => {
          dispatch({ type: UPSERT_OFFER, payload: item });
        });

      let selectedLocations = locations.filter((item) =>
        cmpnLocIds?.includes(item.objid)
      );

      let selectedDC = campaign_dc.filter((item) => dc?.includes(item?.value));

      setLocEnabled(selectedLocations);
      setSelectedDelivery(selectedDC);

      let tempKeysLocation = [];
      selectedLocations.forEach((item) => {
        tempKeysLocation.push(item.key);
      });

      let tempKeysDC = [];
      selectedDC.forEach((item) => {
        tempKeysDC.push(item.key);
      });

      // setSelectedRowKeysCamps(tempKeysDC);
      setSelectedRowKeys(tempKeysLocation);
      setBrochureUri(cmpn.brochureUri);
      setLocalImgs(cmpnImgs);
    } else {
      dispatch({
        type: END_DATE,
        payload: getFormattedDt(
          new Date(new Date().setFullYear(new Date().getFullYear() + 2))
        ),
      });
      setPayload({
        cmpnType: cmpnTypes[0],
        goal: null,
        category: null,
        title: null,
        startDt: new Date(),
        endDt: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
        descr: null,
        cmpnImgs: [{}, {}],
        campaignVideo: [{}, {}],
        finePrint: null,
        tags: null,
      });

      setLocEnabled([]);
      setSelectedDelivery([]);
      setSelectedRowKeys([]);
      // setSelectedRowKeysCamps([]);
    }
  }, [isEdit]);

  const { errors } = useForm({
    mode: "onChange",
  });

  const payloadSetter = (type, value) => {
    setPayload({ ...payload, [type]: value });
  };

  // const handleTableChange = (pagination) => {
  //   setPagination({
  //     ...pagination,
  //   });
  // };

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const finePrintRef = useRef(null);
  const cmpnurlRef = useRef(null);
  const newDateStringValue = (date) => {
    const originalDate = new Date(date);

    // Set the time to midnight (00:00:00)
    originalDate.setUTCHours(0, 0, 0, 0);

    // Convert the date back to an ISO 8601 string
    return originalDate.toISOString();
  };
  const verifier = async ({ isEdit }) => {
    const {
      cmpnType,
      goal,
      category,
      title,
      startDt,
      endDt,
      descr,
      finePrint,
      tags,
    } = payload;

    const stripHtmlTags = (str) => {
      if (!str) return "";
      var div = document.createElement("div");
      div.innerHTML = str;
      return div.textContent || div.innerText || "";
    };
    switch (current) {
      case 0: {
        if (cmpnType == null) {
          message.error("Please select Type");
          return false;
        }
        return true;
      }
      case 1: {
        if (!goal || goal.length < 1) {
          message.error("Campaign Objective can not be empty");
          return false;
        }
        if (!category || category.length < 1) {
          message.error("Campaign category can not be empty");
          return false;
        }

        return true;
      }
      case 2: {
        const startTime = startDt.getTime();
        const endTime = endDt.getTime();
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const now = today.getTime();

        if (title === null || title === undefined || title.length < 1) {
          message.error("Title can not be blank");
          return false;
        }
        if (!isEdit && startTime < now) {
          message.error("Start date is in past");
          return false;
        }
        if (!isEdit && endTime < now) {
          message.error("End date is in past");
          return false;
        }
        if (startTime && endTime && endTime < startTime) {
          message.error("End date should be after start date");
          return false;
        }
        const strippedDescr = stripHtmlTags(descr).trim();
        if (descr === null || descr === undefined || strippedDescr.length < 1) {
          message.error("Please enter description");
          return false;
        }

        return true;
      }
      case 3: {
        if (
          cmpnImgs.filter((row) => row.hasOwnProperty("img")).length < 2 &&
          !isEdit
        ) {
          message.error("Please Add campaign Images");
          return false;
        }
        return true;
      }
      case 4: {
        if (offers.length < 1 && cmpnType === "offers") {
          message.error("please create at least one offer to proceed");
          return false;
        }
        return true;
      }
      case 5: {
        const strippedfinePrint = stripHtmlTags(finePrint).trim();
        if (
          strippedfinePrint.length < 1 ||
          finePrint === null ||
          finePrint === undefined
        ) {
          message.error(
            "Please add the Terms & Conditions before moving further."
          );
          return false;
        }
        return true;
      }
      case 6: {
        if (selectedRowKeys.length < 1) {
          message.error("Please select at least one location");
          return false;
        }
        const cmpnLocs = [];
        const dcTmp = [];
        locEnabled.forEach((item, index) => {
          cmpnLocs.push(item.objid);
        });

        selectedDelivery.forEach((item, index) => {
          dcTmp.push(item.value);
        });
        let data = {
          objid: currentCmpn,
          bizid,
          cmpnType,
          categoryId: category,
          goal,
          title,
          descr,
          finePrint,
          startDt,
          endDt,
          cmpnLocs,
          dc: dcTmp,
          brochureName,
          brochureUri,
          offers,
          cmpnImgs,
          cmpnUrl,
          campaignVideo,
          tags: tagsName.length > 0 ? tagsName.join(",") : null,
        };
        let payload = data;
        !isEdit && delete payload.objid;

        if (cmpnType !== "brochure") {
          const offersNew = [];

          //remove extra from payload
          payload.offers.forEach((row, index) => {
            try {
              row.isNew && delete row.objid;
              delete row.imgUri;
              delete row.thumbnailUri;
              if (row?.displayOrder === undefined) {
                row.displayOrder = index + 1;
              }
            } catch (err) {}

            offersNew.push(row);
          });
          payload.offers = offersNew;
        }
        payload.cmpnImgs = payload.cmpnImgs
          .map((row) => {
            return {
              index: row.index,
              img: row.img,
              thumbnail: row.thumbnail,
            };
          })
          .sort((a, b) => a.index - b.index);

        payload.campaignVideo = payload.campaignVideo
          .map((row) => {
            return {
              index: row.index,
              video: row.video,
              thumbnail: row.thumbnailUri,
            };
          })
          .sort((a, b) => a.index - b.index);
        payload.status = isActive ? "active" : "inactive";
        payload.country = address.country;
        payload.lastUpdatedBy = indvid;
        payload.dc = data.dc.join(",");
        setIsLoading(true);
        let response = await UserActions.saveCmpn(payload);
        setIsLoading(false);
        if (response.success) {
          message.success("Campaign saved");
          callback(false);
          setIsUpdate(false);

          return true;
        } else {
          message.error(response.msg);
          return false;
        }
      }
      case 7: {
        const cmpnLocs = [];
        const dcTmp = [];
        locEnabled.forEach((item) => {
          cmpnLocs.push(item.objid);
        });

        selectedDelivery.forEach((item) => {
          dcTmp.push(item.value);
        });

        let data = {
          objid: currentCmpn,
          bizid,
          cmpnType,
          categoryId: category,
          goal,
          title,
          descr,
          finePrint,
          startDt: newDateStringValue(startDt),
          endDt: newDateStringValue(endDt),
          cmpnLocs : (campaignGoal!== "online"  || cmpnBookingEnabled) ? cmpnLocs :[] ,
          dc: dcTmp,
          brochureName,
          brochureUri,
          offers,
          cmpnImgs,
          cmpnUrl,
          campaignVideo,
          tags: tagsName.length > 0 ? tagsName.join(",") : null,
        };
        let payload = data;

        !isEdit && delete payload.objid;

        if (cmpnType !== "brochure") {
          const offersNew = [];

          //remove extra from payload
          payload.offers.forEach((row, index) => {
            try {
              row.isNew && delete row.objid;
              delete row.imgUri;
              delete row.thumbnailUri;
              if (row?.displayOrder === undefined) {
                row.displayOrder = index + 1;
              }
            } catch (err) {}

            offersNew.push(row);
          });
          payload.offers = offersNew;
        }
        payload.cmpnImgs = payload.cmpnImgs
          .map((row) => {
            return {
              index: row.index,
              img: row.img,
              thumbnail: row.thumbnail,
            };
          })
          .sort((a, b) => a.index - b.index);
        payload.campaignVideo = payload.campaignVideo
          .map((row) => {
            return {
              index: row.index,
              video: row.video,
              thumbnail: row.thumbnailUri,
            };
          })
          .sort((a, b) => a.index - b.index);
        payload.status = isActive ? "active" : "expired";
        payload.country = address.country;
        payload.lastUpdatedBy = indvid;
        payload.dc = data.dc.join(",");

        let response = await UserActions.saveCmpn(payload);
        if (response.success) {
          message.success("Campaign created successfully ");
          callback(false);
          setIsUpdate(false);

          return true;
        } else {
          message.error(response.msg);
          return false;
        }
      }
      default:
        return false;
    }
  };
  const saveCampaign = async () => {
    const cmpnLocs = locEnabled.map((item) => item.objid);
    const dcTmp = selectedDelivery.map((item) => item.value);
    const {
      cmpnType,
      goal,
      category,
      title,
      startDt,
      endDt,
      descr,
      finePrint,
      tags,
    } = payload;
    let data = {
      objid: currentCmpn,
      bizid,
      cmpnType,
      categoryId: category,
      goal,
      title,
      descr,
      finePrint,
      startDt: newDateStringValue(startDt),
      endDt: newDateStringValue(endDt),
      cmpnLocs: (campaignGoal!== "online"  || cmpnBookingEnabled) ? cmpnLocs : [],
      dc: dcTmp,
      brochureName,
      brochureUri,
      offers,
      cmpnImgs,
      cmpnUrl,
      campaignVideo,
      tags: tagsName.length > 0 ? tagsName.join(",") : null,
      status: isActive ? "active" : "expired",
      country: address.country,
      lastUpdatedBy: indvid,
      dc: dcTmp.join(","),
    };

    if (!isEdit) delete data.objid;

    data = modifyPayloadForSave(data);

    setIsLoading(true);
    const response = await UserActions.saveCmpn(data);
    setIsLoading(false);

    if (response.success) {
      message.success("Campaign saved");
      callback(false);
      setIsUpdate(false);
      return true;
    } else {
      message.error(response.msg);
      return false;
    }
  };

  const modifyPayloadForSave = (payload) => {
    if (payload.cmpnType !== "brochure") {
      const offersNew = payload.offers.map((row, index) => {
        if (row.isNew) delete row.objid;
        delete row.imgUri;
        delete row.thumbnailUri;
        if (row?.displayOrder === undefined) {
          row.displayOrder = index + 1;
        }
        return row;
      });
      payload.offers = offersNew;
    }

    payload.cmpnImgs = payload.cmpnImgs
      .map((row) => ({
        index: row.index,
        img: row.img,
        thumbnail: row.thumbnail,
      }))
      .sort((a, b) => a.index - b.index);

    payload.campaignVideo = payload.campaignVideo
      .map((row) => ({
        index: row.index,
        video: row.video,
        thumbnail: row.thumbnailUri,
      }))
      .sort((a, b) => a.index - b.index);

    return payload;
  };

  useEffect(() => {
    if (!isEdit && isStartDate) {
      const newEndDate = new Date(payload.startDt);
      newEndDate.setFullYear(newEndDate.getFullYear() + 2);
      payloadSetter("endDt", newEndDate);
    }
  }, [payload.startDt]);

  const handleStartDateChange = (value) => {
    const newStartDate = new Date(value);

    payloadSetter("startDt", newStartDate);

    const newEndDate = new Date(newStartDate);
    newEndDate.setFullYear(newEndDate.getFullYear() + 2);
    dispatch({
      type: END_DATE,
      payload: getFormattedDt(newEndDate),
    });
    setShowCalendar(false);
  };

  const handleEndDateChange = (newEndDate) => {
    dispatch({
      type: END_DATE,
      payload: getFormattedDt(new Date(newEndDate)),
    });
    payloadSetter("endDt", newEndDate);
    setShowCalendar(false);
  };

  const onSelectChange = (selectedRowKeys, rows) => {
    setSelectedRowKeys(selectedRowKeys);
    setLocEnabled(rows);
  };

  const saveCroppedImage = async (imgEdit, croppedAreaPixels, rotation) => {
    try {
      const croppedBase64 = await getCroppedImg(
        imgEdit,
        croppedAreaPixels,
        fileType,
        rotation
      );
      console.log("fileType", fileType);
      const imgBase64 = croppedBase64.substring(
        croppedBase64.indexOf("base64,") + 7
      );
      const payload = {
        index: index,
        img: imgBase64,
        imgUri: croppedBase64,
        thumbnail: imgBase64,
        thumbnailUri: croppedBase64,
        fileType: fileType,
      };
      dispatch({ type: UPDT_CMPN_IMG, payload });
      setModalVisible(false);
      setIsCrop(false);
    } catch (error) {
      console.error("Error saving cropped image:", error);
    }
  };

  let newobj = {};
  const result = [];
  const getCampnList = async () => {
    let response = await UserActions.fetchcmpnCategoriesList();
    for (let value of response) {
      newobj = { ...value };
      result.push(newobj);
    }
    const newArrayOfObj = result.map(({ 0: objid, 1: name, ...rest }) => ({
      objid,
      name,
      ...rest,
    }));
    setCategoryList(newArrayOfObj);
  };

  // const onDcChange = (key, rows) => {
  //   setSelectedRowKeysCamps(key);
  //   setSelectedDelivery(rows);
  // };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // const rowSelectionTwo = {
  //   selectedRowKeys: selectedRowKeysCamps,
  //   onChange: onDcChange,
  // };

  const Switcher = (props) => {
    const { isEdit } = props;
    switch (current) {
      case 0: {
        return (
          <div className="container-fluid container-mw-md chapter">
            <article className="article">
              <h2 className="article-title">Campaign Type</h2>
              <div className="box box-default">
                <div className="box-body">
                  <Row justify="center">
                    <Space direction="horizontal">
                      {
                        // cmpnTypes.map((item, index) => (
                        cmpnTypes
                          .filter((item) => item !== "brochure")
                          .map((item, index) => (
                            <Button
                              disabled={
                                isEdit && payload.cmpnType !== item
                                  ? true
                                  : false
                              }
                              type={
                                payload.cmpnType === item
                                  ? "info"
                                  : isEdit
                                  ? "default"
                                  : "primary"
                              }
                              size="large"
                              onClick={() => {
                                !isEdit && payloadSetter("cmpnType", item);
                              }}
                              icon={
                                payload.cmpnType === item ? (
                                  <CheckOutlined />
                                ) : null
                              }
                            >
                              {capitalize(item)}
                            </Button>
                          ))
                      }
                    </Space>
                  </Row>
                </div>
              </div>
            </article>
          </div>
        );
      }
      case 1: {
        return (
          <div className="container-fluid container-mw-lg chapter">
            <article className="article">
              <h2 className="article-title">Campaign Objective and Category</h2>
              <div className="box box-default">
                <div className="box-body">
                  <Row justify={"space-between"} style={{ width: "100%" }}>
                    <Col xs={24} md={10} span={12}>
                      <Select
                        style={{ minWidth: "100%" }}
                        placeholder="Objective"
                        value={payload.goal}
                        onChange={(value) => {
                          setCampaignGoal(value)
                          payloadSetter("goal", value);
                        }}
                      >
                        {campaign_goal.map((item, index) => {
                          return (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Col>
                    <Col xs={24} md={10} span={10}>
                      <Select
                        showSearch
                        value={payload.category}
                        style={{ minWidth: "100%" }}
                        placeholder="Category"
                        onChange={(value, option) => {
                          console.log("Selected Label:", option?.children);
                          console.log("Selected Value:", value);
                          payloadSetter("category", value);
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {categoryListArr.map((item, index) => {
                          return (
                            <Option key={index} value={item.value}>
                              {item.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Col>
                  </Row>

                  <Row style={{ paddingTop: "24px" }}>
                    <Col xs={24} md={24} span={24}>
                      <h2
                        className="article-title"
                        style={{
                          paddingTop: "0rem",
                          fontSize: "16px",
                        }}
                      >
                        Tags{" "}
                        <span>
                          <Popover
                            content={
                              <div>
                                You can manage tags from your profile section.{" "}
                                <Link to="/preferences/Profile">
                                  Click here
                                </Link>
                              </div>
                            }
                          >
                            <i
                              style={{
                                color: Theme.blue,
                                marginLeft: ".5rem",
                                fontSize: "16px",
                              }}
                              className="fa fa-info-circle"
                              aria-hidden="true"
                            />
                          </Popover>
                        </span>
                      </h2>
                    </Col>
                    <Col xs={24} md={10} span={24}>
                      <DropdownComponent
                        options={tagNames}
                        placeholder="Select tags"
                        mode="multiple"
                        value={tagsName}
                        onChange={(e) => setTagsName(e)}
                        style={{ width: "100%" }}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </article>
          </div>
        );
      }
      case 2: {
        return (
          <div className="container-fluid container-mw-lg chapter">
            <article className="article">
              <h2 className="article-title">
                {payload?.cmpnType === "events"
                  ? "Event Details"
                  : "Campaign Details"}
              </h2>
              <div className="box box-default">
                <div className="box-body">
                  <Form {...layout}>
                    <FormItem
                      label="Title"
                      help={errors.title?.message}
                      validateStatus={errors.title ? "error" : "success"}
                    >
                      <Input
                        ref={titleRef}
                        key="editorKey"
                        placeholder="Title"
                        defaultValue={payload.title}
                        onBlur={(text) => {
                          let existingValue = titleRef?.current?.state?.value;
                          payloadSetter(
                            "title",
                            existingValue ? existingValue : text.target.value
                          );
                        }}
                      />
                    </FormItem>
                    <FormItem
                      label="Campaign URL"
                      help={errors.cmpnurlRef?.message}
                      validateStatus={errors.cmpnurlRef ? "error" : "success"}
                    >
                      <Input
                        ref={cmpnurlRef}
                        key="editorKey"
                        placeholder="Campaign URL"
                        defaultValue={payload.cmpnType ? cmpnUrl : ""}
                        onBlur={(text) => {
                          let urlValue = cmpnurlRef?.current?.state?.value;
                          setCmpnUrl(urlValue ? urlValue : text.target.value);
                        }}
                      />
                    </FormItem>
                    <FormItem {...tailLayout}>
                      <Space direction="horizontal" size="large">
                        <Text>
                          {payload.cmpnType === "events"
                            ? "Event Campaign Start Date:"
                            : "Start Date:"}
                        </Text>
                        <Button
                          onClick={() => {
                            setIsStartDate(true);
                            setShowCalendar(true);
                          }}
                        >
                          {payload.startDt
                            ? getFormattedDt(payload.startDt)
                            : payload.cmpnType === "events"
                            ? "Event Campaign Start Date:"
                            : "Start Date:"}
                        </Button>

                        <Text>
                          {payload.cmpnType === "events"
                            ? "Event Date:"
                            : "End Date:"}
                        </Text>
                        <Button
                          onClick={() => {
                            setIsStartDate(false);
                            setShowCalendar(true);
                          }}
                        >
                          {payload.endDt
                            ? getFormattedDt(payload.endDt)
                            : "End Date"}
                        </Button>
                      </Space>
                    </FormItem>
                    <FormItem
                      label="Description"
                      help={errors.descr?.message}
                      validateStatus={errors.descr ? "error" : "success"}
                    >
                      {/* <TextArea
                        ref={descriptionRef}
                        key="keyEditor"
                        placeholder="Description"
                        maxLength={1000}
                        style={{ width: "100%", height: "20rem" }}
                        defaultValue={payload.descr}
                        onBlur={(e) =>
                          payloadSetter(
                            "descr",
                            descriptionRef.current.resizableTextArea.props.value
                          )
                        }
                        row={10}
                      /> */}

                      <RichTextEditor
                        ref={descriptionRef}
                        getMarkdownValue={(value) => {
                          payloadSetter("descr", value);
                        }}
                        desc={payload.descr}
                      />
                    </FormItem>
                    <Modal
                      visible={showCalendar}
                      footer={null}
                      onCancel={() => setShowCalendar(false)}
                      title={isStartDate ? "Start Date" : "End Date"}
                    >
                      <Calendar
                        onChange={(date) => {
                          if (isStartDate) {
                            handleStartDateChange(date);
                          } else {
                            handleEndDateChange(date);
                          }
                        }}
                        minDate={new Date()}
                        value={isStartDate ? payload.startDt : payload.endDt}
                      />
                    </Modal>
                  </Form>
                </div>
              </div>
            </article>
          </div>
        );
      }
      case 3: {
        return (
          <div className="container-fluid container-mw-lg chapter">
            <article className="article">
              <h2 className="article-title">Campaign Images & Videos</h2>
              <div className="box box-default">
                <div className="box-body">
                  <div style={{ marginBottom: "1rem" }}>
                    <div key={index} style={{ marginBottom: "1rem" }}>
                      {images.map((index) => {
                        let imgUri;
                        try {
                          imgUri = cmpnImgs?.filter((row) => {
                            return row.index === index;
                          })[0]["imgUri"];
                        } catch {}

                        return (
                          <>
                            <Space direction="horizontal">
                              {imgUri ? (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={imgUri}
                                    style={{
                                      width: "200px",
                                      objectFit: "contain",
                                      maxHeight: "100px",
                                      marginRight: "20px",
                                    }}
                                  />
                                  <i
                                    onClick={(e) => {
                                      setIsCrop(true);
                                      setImgEdit(imgUri);
                                      setIndex(index);
                                      setModalVisible(true);
                                    }}
                                    style={{
                                      position: "absolute",
                                      top: "-5px",
                                      right: "20px",
                                      background: Theme.blue,
                                      height: "1.8rem",
                                      width: "1.8rem",
                                      borderRadius: "50%",
                                      color: "white",
                                      cursor: "pointer",
                                      textAlign: "center",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    className="fa fa-crop"
                                    aria-hidden="true"
                                  />
                                </div>
                              ) : (
                                <BsImage
                                  style={{
                                    fontSize: 100,
                                    color: "#CCC",
                                    marginRight: "20px",
                                  }}
                                />
                              )}
                              <Button
                                type="primary"
                                onClick={(e) => {
                                  setFileType("image");
                                  setImgEdit(imgUri);
                                  setIndex(index);
                                  setModalVisible(true);
                                  setVideoIndex(-1);
                                }}
                              >
                                Upload Image
                              </Button>
                            </Space>
                            <br />
                            <br />
                          </>
                        );
                      })}
                    </div>
                    <div key={videoIndex} style={{ marginBottom: "1rem" }}>
                      {videos.map((index) => {
                        let videoUri;
                        try {
                          videoUri = campaignVideo?.filter((row) => {
                            return row.index === index;
                          })[0]["videoUri"];
                        } catch {}

                        return (
                          <>
                            <Space direction="horizontal">
                              {videoUri ? (
                                <div
                                  style={{
                                    position: "relative",
                                    display: "inline-block",
                                    marginRight: "20px",
                                  }}
                                >
                                  <Video
                                    width="200"
                                    height="150"
                                    videoUri={videoUri}
                                  />
                                  <i
                                    onClick={(e) => {
                                      setDeleteModal(true);
                                      setVideoIndex(index);
                                    }}
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "-1px",
                                      background: Theme.red,
                                      height: "1.8rem",
                                      width: "1.8rem",
                                      borderRadius: "50%",
                                      color: "white",
                                      cursor: "pointer",
                                      textAlign: "center",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  />
                                </div>
                              ) : (
                                <BsImage
                                  style={{
                                    fontSize: 100,
                                    color: "#CCC",
                                    marginRight: "20px",
                                  }}
                                />
                              )}
                              <Button
                                type="primary"
                                onClick={(e) => {
                                  console.log("fileTyoe", fileType);
                                  setFileType("video");
                                  setVideoIndex(index);
                                  setModalVisible(true);
                                }}
                              >
                                Upload Video
                              </Button>
                            </Space>
                            <br />
                            <br />
                          </>
                        );
                      })}
                    </div>

                    <Modal
                      visible={modalVisible}
                      footer={null}
                      onCancel={() => {
                        setModalVisible(false);
                        setIsCrop(false);
                        setVideoIndex(-1);
                      }}
                      title={
                        !isCrop
                          ? fileType === "video"
                            ? "Upload Video"
                            : "Upload Image"
                          : "Crop Image"
                      }
                      width="40%"
                    >
                      {!isCrop ? (
                        <ImageUploader
                          index={index}
                          callback={(data) => {
                            setModalVisible(false);
                            setFileType(data);
                          }}
                          isEdit={isEdit}
                          imgUrl={imgEdit}
                          videoIndex={videoIndex}
                        />
                      ) : (
                        <Crop
                          index={index}
                          src={imgEdit}
                          handleSaveClick={(croppedAreaPixels, rotation) =>
                            saveCroppedImage(
                              imgEdit,
                              croppedAreaPixels,
                              rotation
                            )
                          }
                        />
                      )}
                    </Modal>
                    <Modal
                      visible={deleteModal}
                      onCancel={() => {
                        setDeleteModal(false);
                      }}
                      onOk={() => {
                        handleRemoveVideo(videoIndex);
                        console.log("videoIndex", videoIndex);
                      }}
                      title={null}
                      width="40%"
                    >
                      Do you want to remove the video?
                    </Modal>
                  </div>
                </div>
              </div>
            </article>
          </div>
        );
      }
      case 4: {
        return (
          <>
            {payload.cmpnType === "brochure" && (
              <div className="container-fluid container-mw-lg chapter">
                <article className="article">
                  <h2 className="article-title">Upload Brochure</h2>
                  <div className="box box-default">
                    <div className="box-body">
                      <Row>
                        <Col span={12}>
                          <Uploader
                            uploaderType="text"
                            imgUrl={null}
                            allowedTypes={["pdf", "image"]}
                            callback={async (data) => {
                              {
                                console.log(
                                  "dataaaa",
                                  data + "display",
                                  brochureDisplay + "name",
                                  brochureName + "uri",
                                  brochureUri
                                );
                              }
                              setFileType(data.type);
                              setBrochureDisplay(data.img);
                              setBrochureName(data.name);
                              setBrochureUri(
                                data.img.substring(
                                  data.img.indexOf("base64,") + 7
                                )
                              );
                              setResetUploader(false);
                            }}
                            aspect={2 / 1}
                            label={
                              <Button icon={<UploadOutlined />}>
                                Upload File
                              </Button>
                            }
                            reset={resetUploader}
                          />
                        </Col>
                        <Col span={12}>
                          {brochureUri !== undefined && (
                            <DocViewer
                              isEdit={isEdit}
                              uri={brochureDisplay || brochureUri}
                              title={payload.title}
                            />
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </article>
              </div>
            )}

            {payload.cmpnType !== "brochure" && (
              <OffersList cmpnType={payload.cmpnType} />
            )}
          </>
        );
      }
      case 5: {
        return (
          <div className="container-fluid container-mw-lg chapter">
            <article className="article">
              <h2 className="article-title">Terms & Conditions</h2>
              <div className="box box-default">
                <div className="box-body">
                  {/* <TextArea
                    ref={finePrintRef}
                    key="keyEditor"
                    placeholder="Fine Print"
                    maxLength={1000}
                    style={{ width: "100%", height: "20rem" }}
                    defaultValue={payload.finePrint}
                    onBlur={() =>
                      payloadSetter(
                        "finePrint",
                        finePrintRef.current.resizableTextArea.props.value
                      )
                    }
                    row={10}
                  /> */}
                  <RichTextEditor
                    ref={finePrintRef}
                    getMarkdownValue={(value) => {
                      payloadSetter("finePrint", value);
                    }}
                    desc={payload.finePrint}
                  />
                </div>
              </div>
            </article>
          </div>
        );
      }
      case 6: {
        return (
          <div className="container-fluid container-mw-lg chapter">
            <article className="article">
              <h2 className="article-title">Select the applicable Locations</h2>
              <Row justify="end" style={{ marginBottom: "2rem" }}>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setStarted(true);
                      setlocationIsEdit(false);
                    }}
                  >
                    Add Locations
                  </Button>
                </Col>
              </Row>
              {locations?.length > 0 || allLocations?.length > 0 ? (
                <div className="box box-default">
                  <div className="box-body">
                    <Table
                      key="locationkey"
                      dataSource={locations || allLocations}
                      columns={locationListColumns}
                      rowSelection={rowSelection}
                      pagination={pagination}
                      onChange={handleTableChange}
                    />
                  </div>
                </div>
              ) : (
                <h6>
                  There are No Locations created yet, please select add
                  locations to create your location
                </h6>
              )}
            </article>
          </div>
        );
      }

      default: {
        return <Loader />;
      }
    }
  };
  const PreviewCampaign = () => {
    let cmpnLocs = [];
    let dcTmp = [];
    locEnabled.forEach((item) => {
      cmpnLocs.push(item.objid);
    });

    selectedDelivery.forEach((item) => {
      dcTmp.push(item.value);
    });

    const {
      cmpnType,
      goal,
      category,
      title,
      startDt,
      endDt,
      descr,
      finePrint,
    } = payload;
    let data = {
      objid: currentCmpn,
      bizid,
      cmpnType,
      categoryId: category,
      goal,
      title,
      descr,
      finePrint,
      startDt,
      endDt,
      cmpnLocs,
      dc: dcTmp,
      brochureName,
      brochureUri,
      offers,
      cmpnImgs,
      cmpnUrl,
      campaignVideo,
      tags,
      biz: {
        bizName: indv.bizName,
        mainLogo: indv.mainLogo,
        addressLine1: indv.address.addressLine1,
        addressLine2: indv.address.addressLine2,
        city: indv.address.city,
        country: indv.address.country,
        postalCode: indv.address.postalCode,
        state: indv.address.state,
        ctryCode: indv.address.ctryCode,
        showBiz: indv.showBiz,
        website: indv.website,
      },
    };
    dispatch({
      type: PREVIEW_ITEM_UPDATE,
      payload: data,
    });
    window.open(`${web}/preview`, "_blank");
  };
  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text=""
      styles={{
        overlay: (base) => ({
          ...base,
          background: Theme.overlay,
        }),
      }}
    >
      {
        <HelmetFyndr
          title="Campaign"
          imgUrl={""}
          url={`${web}/campaign`}
          descr="Campaign"
        />
      }
      <ContainerWrapper title={isEdit ? "Edit Campaign" : "Create Campaign"}>
        <Steps
          size="small"
          current={current}
          onChange={isEdit ? onChange : null}
          className="terms-and-conditions"
        >
          <Step title="Type" />
          <Step title="Objective" />
          <Step title="Description" />
          <Step title="Images & Videos" />
          <Step title={`${capitalize(payload.cmpnType)}`} />
          <Step title={`Terms & Conditions`} />
          {(campaignGoal != "online" || cmpnBookingEnabled) && (<Step title={`Locations`} />)} 
        </Steps>
        <div>
          <Switcher {...props} />
        </div>
        <Row justify="end">
          <Space direction="horizontal">
            {isEdit && current === FINAL_STEP && (
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                checked={isActive}
                onChange={(checked) => {
                  setIsActive(checked);
                }}
              />
            )}
            {current !== INITIAL_STEP && (
              <Button
                size="large"
                type="info"
                onClick={() => {
                  nextStep(current - 2);
                  setIsUpdate(false);
                }}
              >
                Back
              </Button>
            )}
            {current === FINAL_STEP && (
              <Button size="large" type="primary" onClick={PreviewCampaign}>
                Preview
              </Button>
            )}
            <Button
              size="large"
              type="primary"
              onClick={async () => {
                if (current === FINAL_STEP || (current === FINAL_STEP -1 && campaignGoal==="online")) {
                  await saveCampaign();
                } else if (await verifier(props)) {
                  nextStep(current);
                }
              }}
            >
              {(current === FINAL_STEP) || (current === FINAL_STEP -1 && campaignGoal==="online") ? "Save" : "Next"}
            </Button>
          {current !== INITIAL_STEP && current !== FINAL_STEP && isEdit &&
            <Button
              size="large"
              type="primary"
              onClick={async () => {
                await saveCampaign();
              }}
            >
              Save
            </Button>
            }

            {current !== 0 && (
              <Button
                size="large"
                type="warning"
                onClick={() => callback(false)}
              >
                Close
              </Button>
            )}
          </Space>
        </Row>
      </ContainerWrapper>
      <Modal visible={started} footer={null} width={"1050px"} closable={false}>
        {started && (
          <AddLocations
            callback={() => setStarted(false)}
            started={started}
            data={details}
            isEdit={LocationIsEdit}
          />
        )}
      </Modal>
    </LoadingOverlay>
  );
};

export default Edit;
