import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Input, Typography, message, Row, Col, Tooltip } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers";
import { FormSchema } from "../../common";
import { detectIncognito } from "detectincognitojs";
import ReactPlayer from 'react-player'
import { useLocation } from "react-router-dom";
import { Buffer } from "buffer";

import {
  FacebookOutlined,
  GoogleOutlined,
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import getEnvVars from "../../environment";
import UserActions from "../../actions";
import GoogleLogin from "react-google-login";
import Loader from "../../components/Loading/Loader";
import { encryptPassword } from "../../utils/Helper";
import googleLogin from "../../assets/images/googleLogin.svg"
import { Actions } from "../../common";
import ModalIncognito from "../../components/ModalIncognito/ModalIncognito";
import overlayWeb from "../../assets/videos/overlayWeb.mp4";
import mobileOverlay from "../../assets/videos/mobileOverlay.mp4"
import { isMobile } from "../../common/util";
import { GoAlertFill } from "react-icons/go";
import { generateToken } from "../../network/ApiManager";

const { LOGIN_SUCCESS, REFFERAL_CODE, LOG_OUT } = Actions;

const {
  app: { facbookAppId, googleClientId },
} = getEnvVars();

const FormItem = Form.Item;
const { Text } = Typography;
var renderPropsVar;
const Register = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [isBiz, setIsBiz] = useState(null);
  const [screen, setScreen] = useState(0);
  const [email, setEmail] = useState(
    useSelector(({ auth }) => auth.indv.email)
  );
  const { indv } = useSelector(({ auth }) => auth);
  const [emailError, setEmailError] = useState(null);
  // const [verificationError, setVerificationError] = useState();

  const [tokenError, setTokenError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { ChangePasswordSchema } = FormSchema;
  const { handleSubmit, errors, control } = useForm({
    resolver: yupResolver(ChangePasswordSchema),
  });
  const location = useLocation();
  const { promoCode } = location.state || {};

  const refferl_code = async () => {
    const link_refferal_code = decodeURIComponent(
      window.location.search.slice(14)
    );
    // if (indv.indvid) {
    //   dispatch({
    //     type: LOG_OUT,
    //   });
    //   await localStorage.clear();
    //   window.location.reload();
    // } else {
    dispatch({ type: REFFERAL_CODE, payload: link_refferal_code });
    // }
  };
  useEffect(() => {
    if (indv.indvid) {
      message.error(
        "You are already logged in, please logout or try with different browser to proceed further for registration"
      );
      history.push("/");
    } else {
      refferl_code();
    }
  }, []);

  const validateEmail = (val) => {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (val && val.match(mailformat)) {
      return null;
    } else {
      return "Please enter valid email address";
    }
  };


  const handleSetPassword = async (payload) => {
    const pwd = Array.from(await encryptPassword(email, payload.password));
    history.push("/register/profile/", {
      mode: "add",
      email,
      isBiz,
      regMode: "classic",
      password: pwd,
      promoCode
    });
  };

  const videoRef = useRef(null);
  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };
    document.addEventListener('click', playVideo);
    return () => {
      document.removeEventListener('click', playVideo);
    };
  }, []);

  const handleSubmitTokenAndSetPassword = async (payload) => {
    const { token, password, confirmPassword } = payload;
    if (!tokenError && token) {
      setIsLoading(true);
      const resp = await UserActions.verifyToken({ email, token });
      setIsLoading(false);

      if (resp.status === "ok") {
        setScreen(3);
        await handleSubmit(handleSetPassword)();
      } else if (resp.status === "not-ok") {
        message.error(resp.msg);
      }
    }
  };

  const handleChangeEmail = (value) => {
    setEmail(value.trim());
    setEmailError(validateEmail(value));
  };

  const handleSubmitClassic = () => {
    setEmailError(validateEmail(email));
    if (emailError == null && email) {
      getAccount();
    }
  };

  const getAccount = async () => {
    setIsLoading(true);
    const resp = await UserActions.getAccount({ email, regMode: "classic" });
    setIsLoading(false);
    if (resp.status === "new") {
      setScreen(2);
      message.success("Your token is being sent to your email.");
    }
    else if (resp.status === "exists") {
      message.warning(
        "We already have an account with this email id. Please login to continue.."
      );
      setTimeout(() => {
        history.goBack();
      }, 1500);
    } else {
      message.error(
        resp.message || "Error processing request, please try again..",
        3
      );
    }
  };

  const googleSignInSucceded = async (response) => {
    const { profileObj } = response;
    const data = {
      email: profileObj.email,
      fname: profileObj.givenName,
      lname: profileObj.familyName,
      accessToken: response.accessToken
    };
    console.log("response", response, data);
    proceed(data, "google");
  };

  const googleSignInFailed = (info) => {
    const { error, details } = info;
    if (error !== "idpiframe_initialization_failed" && details) {
      message.error(details);
    }
    if (error === "popup_blocked_by_browser") {
      alert(
        "Pop-up blocked by the browser. Please enable pop-ups for this site to log in with Google."
      );
    }
  };

  const postFacebook = async (response) => {
    const name_arr = response.name.split(" ", 2);
    const data = {
      email: response.email,
      fname: name_arr[0],
      lname: name_arr.length > 1 ? name_arr[1] : "",
    };
    if (!response.email) {
      // message.error("Facebook login didn't succeed");
      message.error(
        "This facebook account doesn't contain email address, please update your facebook profile and add email address to access Fyndr app."
      );
    } else {
      proceed(data, "facebook");
    }
  };

  const proceed = async (data, regMode) => {
    setIsLoading(true);
    const payload = { email: data.email, regMode };
    const resp = await UserActions.getAccount(payload);
    setIsLoading(false);

    if (resp.status === "exists") {
      message.info(
        "We already have an account with this email id. We have logged you in.."
      );
      if (regMode === "google") {
        const encodedString = Buffer.from(data?.accessToken).toString("base64");
        let respn = await generateToken({
          provider: "google",
          token: encodedString,
        }, resp?.entityType);
      }

      setTimeout(() => {
        history.push("/");
      }, 1500);
    } else if (resp.status === "new") {
      await dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          email: data.email,
          lname: data.lname,
          fname: data.fname,
          regMode,
        },
      });
      history.push("/register/profile", { regMode, mode: "add", isBiz, promoCode });
    } else {
      message.warning(
        resp.message || "Error processing request, please try again.."
      );
    }
  };

  const renderViews = () => {
    switch (screen) {
      case 0: {
        return (
          <section className="login_box" >
            <Row
              justify="start"
              style={{
                fontSize: isMobile() ? "12px" : "14px", flexDirection: "column", display: "flex",
                textAlignLast: "left"
              }}
            >
              <h2 style={{ color: "rgba(255, 255, 255, 1)", marginBottom: "12px" }}>
                Get Started
              </h2>
              <Row>
                <Col span={12}>
                  <Text className="verification_text">
                    Already have an account?{"  "}
                    <span style={{ color: "#257CDB", fontWeight: "500", cursor: "pointer", textDecoration: "underline", fontSize: isMobile() ? "14px" : "16px" }} onClick={() => { history.push("/login") }}>Log in</span>
                  </Text>
                </Col>
              </Row>

            </Row>
            <Form className="form-v1">
              <Button
                type="primary"
                htmlType="submit"
                className="login_btn"
                onClick={() => {
                  if (indv.indvid) {
                    message.error(
                      "You are already logged in, please logout or try with different browser to proceed further for registration"
                    );
                  } else {
                    setIsBiz(true);
                    setScreen(1);
                  }
                }}
              >
                Register as a Business
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="login_btn"
                onClick={() => {
                  if (indv.indvid) {
                    message.error(
                      "You are already logged in, please logout or try with different browser to proceed further for registration"
                    );
                  } else {
                    setIsBiz(false);
                    setScreen(1);
                  }
                }}
              >
                Register as an Individual
              </Button>
              <Button
                type="warning"
                htmlType="submit"
                className="login_btn go-back"
                style={{ background: "transparent", marginTop: "12px", color: "rgb(255, 255, 255)" }}
                onClick={() => {
                  if (props?.location?.state?.from) {
                    history.goBack();
                  } else history.replace("/");
                }}
              >
                Go Back
              </Button>
            </Form>
          </section>
        );
      }
      case 1: {
        return (
          <section className="login_box" >
            <Row
              justify="start"
              style={{
                fontSize: isMobile() ? "12px" : "14px", flexDirection: "column", display: "flex",
                textAlignLast: "left"
              }}
            >
              <h2 style={{ color: "rgba(255, 255, 255, 1)", marginBottom: "12px" }}>
                Get Started
              </h2>
              <Row>
                <Col span={12}>
                  <Text className="verification_text">
                    Already have an account?{"  "}
                    <span style={{ color: "#257CDB", fontWeight: "500", cursor: "pointer", textDecoration: "underline", fontSize: isMobile() ? "14px" : "16px" }} onClick={() => { history.push("/login") }}>Log in</span>
                  </Text>
                </Col>
              </Row>

            </Row>
            <Text className="register_user">Register as {isBiz ? "a Business" : "an Individual"}</Text>
            <Form className="verification_signup">
              <Input
                name="email"
                size="large"
                placeholder="Email"
                autoFocus
                defaultValue={email}
                onChange={(e) => handleChangeEmail(e.target.value)}
                className="login_btn"
                style={{ marginBottom: "12px" }}
                status={emailError ? "error" : ""}
                prefix={emailError ? <Tooltip placement="bottomRight" title={emailError} color="#fff" overlayInnerStyle={{ color: "black" }}>
                  <GoAlertFill style={{ color: "red", marginRight: "4px" }} />
                </Tooltip> : null}
              />
              <Row style={{ width: "100%" }} align={"middle"} justify={"space-between"}>
                <Col xs={14} sm={14} md={12} lg={20} xl={20} xxl={20} >
                  <Button
                    disabled={isLoading}
                    type="primary"
                    htmlType="submit"
                    className="login_btn"
                    onClick={handleSubmitClassic}
                  >
                    Proceed {isLoading && <Loader />}
                  </Button>
                </Col>
                <Col>
                  <Text style={{ color: "#CCCCCC", textAlign: 'center', bottom: "0.4vw" }}>Or</Text>
                </Col>
                <Col xs={4} sm={4} md={4} lg={2} xl={2} xxl={2} align={"end"} style={{ alignSelf: "start" }}>
                  <GoogleLogin
                    clientId={googleClientId}
                    onSuccess={googleSignInSucceded}
                    onFailure={googleSignInFailed}
                    cookiePolicy={"single_host_origin"}
                    scope="profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events"
                    render={(renderProps) => (
                      <img
                        src={googleLogin}
                        style={{ height: "46px", width: "46px", cursor: "pointer" }}
                        onClick={() => {
                          detectIncognito().then((result) => {
                            if (result.isPrivate) {
                              setModalVisible(true);
                              renderPropsVar = renderProps;
                            } else {
                              renderProps.onClick();
                            }
                            console.log(result.browserName, result.isPrivate);
                          });
                        }}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Button
                type="warning"
                htmlType="submit"
                className="login_btn go-back"
                style={{ backgroundColor: "transparent", color: "rgb(255, 255, 255)" }}
                onClick={() => setScreen(0)}
              >
                Go Back
              </Button>
            </Form>
          </section>
        );
      }
      case 2: {
        return (
          <section className="login_box" >
            <Row
              justify="start"
              style={{
                fontSize: isMobile() ? "12px" : "14px", flexDirection: "column", display: "flex",
                textAlignLast: "left"
              }}
            >
              <h2 style={{ color: "rgba(255, 255, 255, 1)" }}>
                Get Started
              </h2>
              <Row>
                <Col span={12}>
                  <Text className="verification_text">
                    Already have an account?{"  "}
                    <span style={{ color: "#257CDB", fontWeight: "500", cursor: "pointer", textDecoration: "underline", fontSize: isMobile() ? "14px" : "16px" }} onClick={() => { history.push("/login") }}>Log in</span>
                  </Text>
                </Col>
              </Row>

            </Row>
            <Form className="verification_signup">
              <FormItem
                validateStatus={errors.token ? "error" : "success"}
              >
                <Controller
                  name="token"
                  control={control}
                  as={
                    <Input
                      name="token"
                      size="large"
                      placeholder="Verification Token"
                      className="login_btn"
                      style={{ marginBottom: "0px" }}
                      prefix={errors.token ? <Tooltip placement="bottomRight" title={errors.token?.message} color="#fff" overlayInnerStyle={{ color: "black" }}>
                        <GoAlertFill style={{ color: "red", marginRight: "4px" }} />
                      </Tooltip> : null}
                    />
                  }
                />
              </FormItem>

              <FormItem
                // help={errors.password?.message}
                validateStatus={errors.password ? "error" : "success"}
                style={{ marginTop: isMobile() ? "8px" : "12px" }}
              >
                <Controller
                  name="password"
                  control={control}
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  as={
                    <Input.Password
                      size="large"
                      type="password"
                      placeholder="Password"
                      className="login_btn"
                      style={{ marginBottom: "0px" }}
                      prefix={errors.password ? <Tooltip placement="bottomRight" title={errors.password?.message} color="#fff" overlayInnerStyle={{ color: "black" }}>
                        <GoAlertFill style={{ color: "red", marginRight: "4px" }} />
                      </Tooltip> : null} />
                  }
                />
              </FormItem>

              <FormItem
                // help={errors.confirmPassword?.message}
                validateStatus={errors.confirmPassword ? "error" : "success"}
                style={{ marginTop: isMobile() ? "8px" : "12px" }}
              >
                <Controller
                  name="confirmPassword"
                  iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  control={control}
                  as={
                    <Input.Password
                      size="large"
                      type="password"
                      placeholder="Confirm Password"
                      className="login_btn"
                      style={{ marginBottom: "0px" }}
                      prefix={errors.confirmPassword ? <Tooltip placement="bottomRight" title={errors.confirmPassword?.message} color="#fff" overlayInnerStyle={{ color: "black" }}>
                        <GoAlertFill style={{ color: "red", marginRight: "4px" }} />
                      </Tooltip> : null} />
                  }
                />
              </FormItem>

              <Button
                disabled={isLoading}
                type="primary"
                htmlType="submit"
                className="login_btn"
                style={{ marginTop: isMobile() ? "8px" : "24px" }}
                // onClick={handleSubmitToken}
                onClick={handleSubmit(handleSubmitTokenAndSetPassword)}
              >
                Proceed {isLoading && <Loader />}
              </Button>
              <Button
                type="warning"
                htmlType="submit"
                className="login_btn go-back"
                style={{ backgroundColor: "transparent", color: "rgb(255, 255, 255)" }}
                onClick={() => setScreen(1)}
              >
                Go Back
              </Button>
            </Form>
          </section>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div className="no-breadcrumb  container-mw-lg chapter video-section" style={{ padding: "0em" }}>
      <video
        ref={videoRef}
        className="bg-video"
        loop
        muted
        autoPlay
        playsInline
        webkit-playsinline
        style={{ display: !isVideoReady ? "none" : "flex" }}
        onCanPlayThrough={() => setIsVideoReady(true)}
      >
        <source src={isMobile() ? mobileOverlay : overlayWeb} type="video/mp4" />
      </video>

      {!isVideoReady ?
        <div style={{ display: "flex", alignItems: "center", height: "100vh" }}>  <Loader /></div>
        :

        <div className="form-card__body login_div" style={{ width: isMobile() ? "100%" : "70%" }}>
          <h1 style={{ color: "rgba(255, 255, 255, 1) " }} className="login_h2">
            Join Fyndr Today!
          </h1>
          <Text className="login_text">
            Unlock exclusive offers, events, and services tailored just for you.
            Sign up now to start enjoying amazing experiences!
          </Text>
          {renderViews()}
        </div>
      }
      <ModalIncognito
        title="Private or Incognito Window"
        width="50%"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          setModalVisible(false);
          renderPropsVar.onClick();
        }}
        modalText="It seems you are using incognito or private web browser, you need to allow third party cookies in your browser's settings to sign in with google."
      />
    </div>
  );
};
export default Register;
