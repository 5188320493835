/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import { AppstoreFilled, AppstoreOutlined } from "@ant-design/icons";
import {
  Col,
  Input,
  Row,
  Button,
  message,
  Space,
  FloatButton,
  Modal,
  Typography,
  Affix,
} from "antd";

import { ScrollMenu } from "react-horizontal-scrolling-menu";
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  lazy,
  Suspense,
} from "react";
import { isMobile, parseAddress, isTab } from "../../common/util";
import "../../sass/styles/fyndr/_newBizHome.scss";
import { TopMenu, Logo, Footer } from "../../components";
import { Theme } from "../../common";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../common";
import UserAction from "../../actions";
import LoadingOverlay from "react-loading-overlay";
import { useHistory } from "react-router-dom";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByLatLng,
} from "react-google-places-autocomplete";
import { motion } from "framer-motion";
import Images from "../../assets/images/Images";
import SearchIcon from "../../assets/images/home-search-icon.svg";
import googleAutoIcon from "../../assets/images/google-auto-icon.svg";
import googlelocations from "../../assets/images/googlelocation.svg";
import { useRecoilState } from "recoil";
import { offerPageState } from "../../recoil/Atom";
// import FloatingButton from "../../components/FloatingButton/FloatingButton";
import UpArrow from "../../assets/images/UpArrow.svg";
import Loader from "../../components/Loading/Loader";
import {
  getAllStates,
  getStateByCode,
  getStateByCodeAndCountry,
  getStatesOfCountry,
} from "country-state-city/lib/state";
import { landingpageEvent } from "../../metaPixel";
import actions from "../../actions";
import SkeletonLoader from "../CataloguePreviewUrl/SkeletonLoader";
import HelmetFyndr from "../../components/HelmetFyndr";
import getEnvVars from "../../environment";
import HomeSearch from "../../components/BizHome/HomeSearch";
import NearCategory from "../../components/BizHome/NearCategory";
import { setBottom } from "../../actions/isBottomActions";
import usePageSEO from "../../components/usePageSEO";
const { ROLE_FYNDR } = Actions;
const { Text } = Typography;

const CategoryIcons = lazy(() =>
  import("../../components/BizHome/CategoryIcons")
);
const FeaturedOffers = lazy(() =>
  import("../../components/FeaturedOffers/FeaturedOffers")
);
const NearOffers = lazy(() => import("../../components/BizHome/NearOffers"));
const NearEvents = lazy(() => import("../../components/BizHome/NearEvents"));
const PopularOffer = lazy(() =>
  import("../../components/BizHome/PopularOffer")
);

const Home = (props) => {
  const history = useHistory();
  const {
    indv,
    indv: { indvid, address, qrid },
    indv: { entityType },
    settings: { campaign_search_page_size, bizdir_search },
  } = useSelector(({ auth }) => auth);


  const { isMasquerade } = useSelector(({ admin }) => admin);

  //all states;
  const { LOCOFFER, LOCQR, CART_STARTED } = Actions;
  const dispatch = useDispatch();
  const [offerPageSetting, setOfferPageSetting] =
    useRecoilState(offerPageState);
  const [lat, setLat] = useState(
    offerPageSetting?.lat || address?.lat || 33.6629442
  );
  const [lng, setLng] = useState(
    offerPageSetting?.lng || address?.lng || -112.0182329
  );
  const [position, setPosition] = useState({
    lat: offerPageSetting?.lat || address?.lat || 33.6629442,
    lng: offerPageSetting?.lng || address?.lng || -112.0182329,
  });
  const [popularOffers, setPopularOffers] = useState([]);
  const [popularResult, setPopularResult] = useState([]);
  const [bgImg, setBgImg] = useState("");

  const [distance] = useState(50);
  const [pgSize] = useState(campaign_search_page_size || 50);
  const [searchStr, setSearchStr] = useState(null);
  const [data, setData] = useState("");
  const [spinnerVisible, setSpinnerVisible] = useState(false);
  const [isStoreClicked, setIsStoreClicked] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [imglistValue, setImgListValue] = useState(12);
  const [imglistValueOff, setImgListValueOff] = useState(32);
  const [catList, setCatList] = useState(!isMobile() ? 11 : 5);
  const [locOffers, setLocOffers] = useState({});
  const [, setLocQrs] = useState({});
  const [isCategory, setIsCategory] = useState(false);
  const [items, fetchMore] = useSearch(false);
  const [isLoading, setIsLoading] = useState(false);
  const [label, setLabel] = useState("");
  const [popularEvents, setPopularEvents] = useState([]);
  const [seeEventsMore, setSeeEventsMore] = useState(false);
  const [events, setEvents] = useState(32);
  const [orderBy, setOrderBy] = useState("ASC");
  const [isVisible, setIsVisible] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const topContainer = useRef();

  const [addressName, setAddressName] = useState(
    offerPageSetting?.addressName || parseAddress(indv?.address)
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  let allPopular = [];

  const popularList = bizdir_search || [
    { keyword: "restaurant,food", title: "Restaurants Near You" },
    { keyword: "relax,spa,rejuvenate", title: "Relax & Rejuvenate" },
  ];

  const catListHome = [
    { keyword: "Entertainment", title: "Explore Exciting Activities Nearby" },
    { keyword: "Food & Beverages", title: "Discover Dining Experiences Near You" },
    { keyword: "Beauty & Wellness", title: "Nearby Beauty Finds" },
  ]
  const handleSeeAll = (selectedOptions) => {
    history.push("/offers", { selectedOptions });
  };

  const handleScroll = () => {
    const container = topContainer.current;
    if (container) {
      const scrollTop = container.scrollTop || window.scrollY;
      const scrollHeight = container.scrollHeight || document.documentElement.scrollHeight;
      const clientHeight = container.clientHeight || window.innerHeight;
      if (scrollTop > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsBottom(true)
        dispatch(setBottom(true));
      }
      else {
        setIsBottom(false)
        dispatch(setBottom(false));
      }
    }
  };

  useEffect(() => {
    const container = topContainer.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    landingpageEvent();
  }, []);
  useEffect(() => {

    fetchBgImg(lat, lng)

  }, []);
  useEffect(() => {
    if (items.length > 0) {
      props.history.push("/offer-listing", {
        items: items,
        locOffers,
        searchStr,
        isCategory: isCategory,
      });
    }
    console.log("index", items, locOffers);
  }, [items]);

  useEffect(() => {
    data === "" ? setData("") : setData(data);
  }, [data]);
  // useEffect(() => {
  //   setOfferPageSetting({
  //     ...offerPageSetting,
  //     lat,
  //     lng,
  //     addressName,
  //   });
  // }, [])
  useEffect(() => {
    (async () => {
      await setOfferPageSetting({
        ...offerPageSetting,
        lat,
        lng,
        addressName,
      });
    })();
  }, [lat, lng, indv, addressName]);

  function useSearch() {
    const [page, setPage] = useState(0);
    // default this to true to kick the initial effect hook to
    // fetch the first page
    const [shouldFetch, setShouldFetch] = useState(false);
    const [items, setItems] = useState([]);

    // return this function for Flatlist to call onEndReached
    const fetchMore = useCallback((reStart) => {
      setShouldFetch(true);
      if (reStart === true) {
        setPage(0);
        setItems([]);
      }
    }, []);

    useEffect(() => {
      // prevent fetching for other state changes
      if (!shouldFetch) {
        return;
      }

      const fetch = async () => {
        setSpinnerVisible(true);

        //this is just to set the message properly i.e. 'No listing found..'
        // dispatch({ type: types.BIZDIR_SEARCH, payload: "yes" });
        if (searchStr !== null) {
          const { success, payload } = await UserAction.searchBizdir({
            searchStr,
            page,
            pgSize,
            location: {
              lat,
              lng,
            },
            distance,
            isCategory: isCategory,
            indvId: indvid,
          });

          //this is just to set the message properly i.e. 'No listing found..'
          // dispatch({ type: types.BIZDIR_SEARCH, payload: "no" });
          // set the should fetch call to false to prevent fetching
          // on page number update
          if (success) {
            setItems((old) => [...old, ...payload]);
            //increment page for the next call
            setPage(page + 1);
            //build location list to search offers
            let locToSearch = [];
            let newQrs = {};
            let bizList = [];
            Array.isArray(payload) &&
              payload.forEach((row) => {
                if (!Object.keys(locOffers).includes(row.objid)) {
                  locToSearch.push(row.objid);
                  newQrs[row.objid + ""] = row.qrid;
                  bizList.push(row.bizid);
                }
              });

            UserAction.fetchBizinfo({
              bizId: [...new Set(bizList)],
            });

            if (locToSearch.length > 0) {
              const { success, resp } = await UserAction.fetchLocOffers(
                locToSearch.join(",")
              );

              if (success) {
                let newOffers = {};
                Array.isArray(resp) &&
                  resp.forEach((row) => {
                    newOffers[row.locid + ""] = {
                      count: row.count,
                      activeCampaignCount: row.activeCampaignCount,
                      cmpnQrCode: row.cmpnQrCode,
                      img: row.cmpnImg,
                    };
                  });

                setLocOffers((old) => ({ ...old, ...newOffers }));
                setLocQrs((old) => ({ ...old, ...newQrs }));
                // await setLocOffers({ ...locOffers, ...newOffers });
                // await setLocQrs({ ...locQrs, ...newQrs });

                dispatch({
                  type: LOCOFFER,
                  payload: newOffers,
                });
                dispatch({
                  type: LOCQR,
                  payload: newQrs,
                });
                setSearchStr(null);
              }
            }
          }
          // setSearchStr(null)
          setSpinnerVisible(false);

          setShouldFetch(false);
          if (payload.length === 0) {
            message.error("There were no businesses found with this category");
          }
        } else {
          // setSearchStr(null)

          message.error("Search field can't be empty");
          setSpinnerVisible(false);

          setShouldFetch(false);
        }
      };
      fetch();
    }, [shouldFetch]);

    return [items, fetchMore];
  }
  var width = window.innerWidth;
  useEffect(() => {
    if (width <= 576) {
      setImgListValue(10);
      setEvents(10);
    }
  }, [seeMore, popularOffers, popularEvents, seeEventsMore]);
  useEffect(() => {
    getCampnList();
  }, []);
  useEffect(() => {
    const popularEvents = popularOffers?.filter(
      (item) => item.cmpnType === "events" && !item?.isFeatured
    );
    setPopularEvents(popularEvents);
  }, [popularOffers]);
  let newobj = {};
  const result = [];
  const getCampnList = async () => {
    let response = await UserAction.fetchcmpnCategoriesList();
    if (response != undefined) {
      for (let value of response) {
        newobj = { ...value };
        result.push(newobj);
      }
      const newArrayOfObj = result.map(({ 0: objid, 1: name, ...rest }) => ({
        objid,
        name,
        ...rest,
      }));
      setCategoryList(newArrayOfObj);
    }
    const newArrayOfObj = result.map(({ 0: objid, 1: name, ...rest }) => ({
      objid,
      name,
      ...rest,
    }));
    if (newArrayOfObj.length === 0) {
      message.error("No There were no businesses found with this category");
    }
    setCategoryList(newArrayOfObj);
  };
  const searchOffers = async () => {
    setIsLoading(true);
    const { success, payload } = await UserAction.searchCmpnsLight({
      categories: [],
      searchStr: null,
      page: 0,
      pgSize: 500,
      distance,
      location: position,
      indvId: indvid,
      fetchById: "none",
      fetchByGoal: "instore",
      // orderBy: orderBy,
    });

    if (success) {
      setPopularOffers(payload);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setPopularOffers([]);
    }
  };

  // const [featuredlist, setFeaturedlist] = useState([]);
  // const featuredList =  async() =>{
  //   const { success, payload } = await UserAction.featuredCmpn({
  //     categories: [],
  //     searchStr: null,
  //     page: 0,
  //     pgSize: 55,
  //     distance,
  //     location: position,
  //     indvId: indvid,
  //     fetchById: "none",
  //     fetchByGoal: "instore",
  //     // orderBy: orderBy,
  //   }

  // );
  // if(success){
  //   setFeaturedlist(payload)

  // }
  // else{
  //   setFeaturedlist([])
  // }
  // }
  const searchPopular = async (str) => {
    return UserAction.searchBizdir({
      searchStr: str,
      page: 0,
      pgSize: 50,
      location: position,
      distance: 50,
      indvId: indvid,
      isCategory: isCategory,
    });
  };
  //all effects

  useEffect(() => {
    // searchOffers();
    setPopularResult([]);

    popularList.forEach((row) => {
      const words = row.keyword.split(",");

      words.forEach((word) => {
        searchPopular(word).then((resp) => {
          const { success, payload } = resp;
          if (success && Array.isArray(payload) && payload.length > 0) {
            if (payload.filter((row) => row.catImg !== null).length > 0) {
              //don't add same result in the same category
              let newItems = [];
              const currentList = allPopular.filter(
                (curr) => curr.title === row.title
              );
              if (currentList.length === 0) {
                newItems = payload;
              } else {
                newItems = currentList[0].items;
                payload.forEach((item) => {
                  if (
                    newItems.filter((x) => x.objid === item.objid).length === 0
                  ) {
                    newItems.push(item);
                  }
                }); //
              }
              allPopular = allPopular.filter(
                (curr) => curr.title !== row.title
              );
              let resData = newItems.filter((item) => item.catImg !== null);
              allPopular.push({ title: row.title, items: resData });
              setPopularResult(allPopular);
            }
          }
        });
      });
    });
  }, [position]);

  useEffect(() => {
    // featuredList();
    searchOffers();
  }, [position]);

  const getPrice = (offers) => {
    let lowestPrice = Infinity;

    for (const offer of offers) {
      if (offer.offerPrice < lowestPrice) {
        lowestPrice = offer.offerPrice;
      }
    }

    // Filter objects with the lowest price
    const lowestPriceOffers = offers.filter(
      (offer) => offer.offerPrice === lowestPrice
    );
    return (
      <Space
        style={{
          display: "flex",
          justifySelf: "left",
          padding: "0px 10px 0px 10px",
        }}
      >
        {lowestPriceOffers[0]?.retailPrice !== undefined && (
          <>
            <Text delete className="offer_cards--offer-original-price">
              {`${lowestPriceOffers[0]?.currencySymbol
                }${lowestPriceOffers[0]?.retailPrice?.toFixed(2)} `}
            </Text>
            <span className="offer_cards--offer-from">From</span>
            <Text className="offer_cards--offer-discount">
              {`${lowestPriceOffers[0]?.currencySymbol
                }${lowestPriceOffers[0]?.offerPrice?.toFixed(2)}`}
            </Text>
            <span className=" offer_cards--offer-discountper">
              {lowestPriceOffers[0]?.discountType === "%" &&
                lowestPriceOffers[0]?.amount > 0 && (
                  <Text
                    strong
                    style={{
                      backgroundColor: "paleGreen",
                      padding: 2,
                      color: "green",
                    }}
                    className="list-item--offer-discount"
                  >
                    {" "}
                    {`${lowestPriceOffers[0]?.amount}% OFF`}
                  </Text>
                )}
            </span>
            {lowestPriceOffers[0]?.discountType === "flat" &&
              lowestPriceOffers[0]?.amount > 0 && (
                <Text
                  strong
                  style={{
                    backgroundColor: "paleGreen",
                    padding: 2,
                    color: "green",
                  }}
                  className="list-item--offer-discount"
                >
                  {`${lowestPriceOffers[0]?.currencySymbol}${lowestPriceOffers[0]?.amount} OFF`}
                </Text>
              )}
          </>
        )}
      </Space>
    );
  };

  const [categoryList, setCategoryList] = useState([]);

  const categoryListArr = categoryList.map((row) => {
    return { value: row.name, label: row.name };
  });

  const scrollToTop = () => {
    if (topContainer.current) {
      topContainer.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };
  const getCategories = async (locId) => {
    setIsStoreClicked(true);
    setSpinnerVisible(true);
    const resp = await UserAction.getCatalogueByLoc({ locId, buyerQrId: qrid });

    setSpinnerVisible(false);
    dispatch({ type: CART_STARTED });

    scrollToTop();
    setIsLoading(true);
    setTimeout(() => {
      props.history.push(`/catalogue/store/${resp?.payload?.catalogue?.url}`, {
        catalogueDetail: resp.payload,
      });
      setIsStoreClicked(false);
    }, 1000);
  };

  const goToOffer = (bizName, qrCode) => {
    // props.history.push(
    //   `/offer-details/${bizName?.replace(/[.\W]+/g, "-")}/${qrCode}`
    // );


    // window.open(`${web}/offer-details/${bizName?.replace(/[.\W]+/g, "-")}/${qrCode}`, '_blank', 'noopener,noreferrer')
  };
  const goToPage = (item) => {
    const hasOffers = locOffers?.hasOwnProperty(item.objid);
    const hasCatalogue = item.catalogueId !== null;

    hasCatalogue
      ? getCategories(item.objid)
      : hasOffers === true
        ? props.history.push("/offers", {
          locQRId: item.qrid,
        })
        : null;
  };
  function showPosition(position) {
    const newLat = position.coords.latitude;
    const newLng = position.coords.longitude;
    setLat(newLat);
    setLng(newLng);
    setPosition({ lat: newLat, lng: newLng });
    fetchBgImg(newLat, newLng)
    geocodeByLatLng({
      lat: newLat,
      lng: newLng,
    })
      .then((results) => {
        setLabel(results[0]?.formatted_address);
        setAddressName(results[0]?.formatted_address);
        setOfferPageSetting({
          ...offerPageSetting,
          lat: newLat,
          lng: newLng,
          addressName: results[0]?.formatted_address,
        });
      })
      .catch((error) => console.error(error));
  }
  const fetchIpAddress = async () => {
    if (navigator.geolocation) {
      try {
        setIsLoading(true);
        await navigator.geolocation.getCurrentPosition(showPosition);
        setIsLoading(false);
        // setAddressName("Current Location");
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    } else {
      alert("geolocation is not supported");
    }
  };
  const fetchBgImg = async (lat, lng) => {
    const { success, payload } = await UserAction.fetchBackgroundImgs({
      lat, lng
    })
    if (success) {
      setBgImg(payload?.backgroundImageUrl)
    }
  };
  const getCoordinates = async (value) => {
    let geoResult = await geocodeByAddress(value.value.description);
    // let getStateByCode = await getStatesOfCountry("US");
    let coordinates = await getLatLng(geoResult[0]);
    setLat(coordinates.lat);
    setLng(coordinates.lng);
    fetchBgImg(coordinates.lat, coordinates.lng)
    setPosition({ lat: coordinates.lat, lng: coordinates.lng });
    setLabel(value.label);
    setAddressName(value.label);
    setOfferPageSetting({
      ...offerPageSetting,
      lat: coordinates.lat,
      lng: coordinates.lng ,
      addressName: value.label,
    });
  };
  // const imgLink="https://s3-us-west-1.amazonaws.com/dev.fyndr.us/public/images/fyndr-logo.png";
  const { web } = getEnvVars();

  usePageSEO({
    title: "Fyndr",
    description: "Fyndr is a marketplace platform that offers real-time promotions, deals, and discounts to help people discover and find experiences in their city. If you're seeking convenience and savings, then Fyndr has the right deal for you.",
    keywords: ["Fyndr", "Fyndr.us", "fyndr", "marketplace"],
    ogTitle: "Fyndr",
    ogImage: "https://s3.us-west-1.amazonaws.com/dev.fyndr.us/public/biz-campaign/us/images/main/1000139-88d5e06b-6ebb-417c-ac34-533e531f35eb-0.png",
    ogUrl: `${web}/`
  })
  return (
    <>
      {spinnerVisible && <SkeletonLoader />}
      {<HelmetFyndr title="Fyndr" imgUrl={""} url={`${web}/`} descr="fyndr" />}
      {!isStoreClicked && (
        <div ref={topContainer} className="bizHome_container" style={{
          overflowY: "scroll", height: "500px", WebkitOverflowScrolling: "touch",
          scrollbarWidth: "none",
          overflowX: "hidden",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollBehavior: "smooth",
          paddingTop: "60px"
        }}>

          <LoadingOverlay
            active={spinnerVisible}
            spinner
            text=""
            styles={{
              overlay: (base) => ({
                ...base,
                background: Theme.overlay,
              }),
            }}
          >
            <div onClick={() => console.log("clicked")}>
              <div style={{ marginBottom: "10vh" }}
                className={
                  false
                    ? "shortHeader-gone"
                    : isMasquerade
                      ? "bg-danger-fyndr"
                      : "bg-info"
                }
              >
                {true && (
                  <Row style={{ padding: "0 0rem" }} align="middle">
                    <Col xs={0} sm={0} md={isVisible ? 5 : 6} lg={isVisible ? 5 : 10} xl={isVisible ? 5 : 9} xxl={isVisible ? 5 : 10}>
                      {(false || !isMobile()) && !isTab() && (
                        <Row
                          className="fyndrLogo"
                          style={{
                            float: isMobile() ? "right" : "left",

                            // paddingLeft: "5vh",
                          }}
                        >
                          <Col><Logo /></Col>
                        </Row>
                      )}
                    </Col>
                    <Col xs={21} sm={20} md={isVisible ? 14 : 12} lg={isVisible ? 16 : 12} xl={isVisible ? 16 : 13} xxl={isVisible ? 16 : 12}>

                      <Row align="middle"
                        //  justify={!isTab() && "center"}
                        justify="space-between">
                        <Col xs={3} sm={4} md={0}>
                          <Logo collapsed={true} />
                        </Col>
                        {isVisible &&
                          (<>
                            <Col xs={10} sm={9} md={12} xl={12} lg={12} xxl={12}>
                              <HomeSearch fetchMore={fetchMore} searchStr={searchStr} setSearchStr={setSearchStr} isVisible={isVisible} />
                            </Col>
                          </>)}
                        <Col xs={isVisible ? 10 : 18} sm={isVisible ? 9 : 17} md={isVisible ? 12 : 20} xl={isVisible ? 12 : 24} lg={isVisible ? 12 : 24} xxl={isVisible ? 12 : 24}>
                          <GooglePlacesAutocomplete
                            multiple={true}
                            selectProps={{
                              value: addressName,
                              onChange: (value) => {


                                if (
                                  value.value === "myLocation" ||
                                  value.label == "Current Location"
                                ) {
                                  fetchIpAddress();
                                } else {
                                  getCoordinates(value);

                                }
                              },

                              defaultOptions: [
                                {
                                  label: `Current Location`,
                                  value: `${addressName}`,
                                },
                              ],
                              placeholder: `${addressName ? addressName : "Location"
                                }`,

                              styles: {
                                dropdownIndicator: (base) => ({
                                  ...base,
                                  backgroundImage: `url(${googlelocations})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                  backgroundSize: "contain",
                                  cursor: "pointer",
                                  width: "24px",
                                  height: "46px",
                                  margin: "0px 12px",
                                  transition: "transform 0.5s ease-in-out",
                                }),
                              },
                              className: isVisible ? "bizHomeDrop_input2" : "bizHomeDrop_input",
                              onMenuOpen: () => setIsDropdownOpen(true),
                              onMenuClose: () => setIsDropdownOpen(false),
                            }}
                          />
                        </Col>
                        <Col align="center" xs={0} md={0}>
                          <i
                            onClick={() => {
                              //   setOpenBottomSheet(true);
                            }}
                            className="fa fa-map-marker"
                            aria-hidden="true"
                            style={{ fontSize: "1.5rem", color: "#fff" }}
                          ></i>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={2}
                      sm={3}
                      md={3}
                      lg={2}
                      xl={2}
                      xxl={2}
                      className="bizHome_bg-topMenu"
                    >
                      <TopMenu {...props} />
                    </Col>
                  </Row>
                )}
              </div>
            </div>
            <div className="bizHome_flex_container"
              style={{
                backgroundImage: bgImg !== "" && "url(" + `${bgImg.replace(/\s+/g, '%20')}` + ")"
              }}
            >
              <div
                className="bizHome_flex_container_bg"
                style={{ justifySelf: "center", display: "flex" }}
              >
                <Row justify="center" style={{ width: "100%" }}>
                  <Col span={10} md={16} lg={10} xs={20}>
                    <Input
                      className="bizHome_search-input"
                      value={searchStr}
                      onChange={async (event) => {
                        setSearchStr(event.target.value);
                      }}
                      onPressEnter={() => fetchMore(true)}
                      prefix={
                        <img
                          loading="lazy"
                          src={SearchIcon}
                          alt="search"
                          style={{ marginRight: "0.75rem" }}
                        />
                      }
                      suffix={
                        <Button
                          onClick={() => fetchMore(true)}
                          type="primary"
                          className="bizHome_search-btn"
                        >
                          Search
                        </Button>
                      }
                      placeholder="Search Offers, Events & Businesses"
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <Row
              align="middle"
              justify="center"
              style={{ marginTop: isMobile() ? "1.25rem" : "1.5rem", position: 'relative' }}
            >
              <Col align="center" xs={24} md={20}>
                <div
                  className="bizHome_content"
                  style={{
                    borderRadius: isMobile() ? "0px" : "10px",
                  }}
                >
                  <Suspense fallback={<Loader />}>
                    <CategoryIcons
                      categoryListArr={categoryListArr}
                      setIsCategory={setIsCategory}
                      setSearchStr={setSearchStr}
                      fetchMore={fetchMore}
                      catList={catList}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <div>
                      </div>
                    }
                  >
                    <FeaturedOffers
                      campaignData={popularOffers}
                      goToOffer={(bizName, qrCode) =>
                        goToOffer(bizName, qrCode)
                      }
                    />
                  </Suspense>
                  {catListHome.map((item) =>

                  (
                    <Suspense
                      fallback={
                        <div>
                        </div>
                      }
                    >
                      <NearCategory
                        catName={item.keyword}
                        title={item.title}
                        popularOffers={popularOffers}
                        imglistValue={imglistValue}
                        handleSeeAll={handleSeeAll}
                        goToOffer={goToOffer}
                        getPrice={getPrice}
                        setIsCategory={setIsCategory}
                        setSearchStr={setSearchStr}
                        fetchMore={fetchMore}
                      />
                    </Suspense>

                  )

                  )
                  }

                  <Suspense
                    fallback={
                      <div>
                      </div>
                    }
                  >

                    <NearOffers
                      popularOffers={popularOffers.filter((i) =>
                        i?.category?.name !== catListHome[0]?.keyword &&
                        i?.category?.name !== catListHome[1]?.keyword &&
                        i?.category?.name !== catListHome[2]?.keyword
                      )}
                      mainArray={popularOffers}
                      catListHome={catListHome}
                      // popularOffers={popularOffers}
                      imglistValue={imglistValueOff}
                      handleSeeAll={handleSeeAll}
                      goToOffer={goToOffer}
                      getPrice={getPrice}
                    />
                  </Suspense>
                  <Suspense
                    fallback={
                      <div>
                      </div>
                    }
                  >
                    <NearEvents
                      popularEvents={popularEvents}
                      handleSeeAll={handleSeeAll}
                      goToOffer={goToOffer}
                      getPrice={getPrice}
                      events={events}
                    />
                  </Suspense>


                  {/* <Suspense
                    fallback={
                      <div>
                      </div>
                    }
                  >
                    <PopularOffer
                      popularOffers={popularOffers}
                      popularResult={popularResult}
                      goToPage={goToPage}
                    />
                  </Suspense> */}
                </div>
              </Col>
            </Row>
            <Footer />
            {isVisible && (<div
              onClick={scrollToTop}
              className="topBottomScroll"
              style={{ bottom: isBottom ? "45px" : "20px" }}>
              <img
                src={UpArrow}
                alt="Up Arrow"
                className="up-arrow"
                style={{ width: "50px", height: "50px" }}
              />
            </div>)}
          </LoadingOverlay>
        </div>
      )}
    </>
  );
};
export default Home;

