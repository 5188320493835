/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-dupe-keys */
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { FormSchema } from "../../common";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers";
import Hours from "../../components/react-hours/dist/index.js";
import { useGoogleLogin } from "react-google-login";
import { GoAlertFill } from "react-icons/go";
import moment from "moment";

import ReactDOM from "react-dom";
import {
  Row,
  Checkbox,
  Button,
  Col,
  Form,
  message,
  Select,
  Modal,
  Divider,
  Table,
  InputNumber,
  Space,
  Popover,
  Switch,
  Radio,
  Tooltip,
} from "antd";
import UserActions from "../../actions";
import ReactFlagsSelect from "react-flags-select";
import Theme from "../../common/Theme.js";
import Loader from "../../components/Loading/Loader";
import { postalPattern } from "../../common/util";
import TimezonePicker from "react-bootstrap-timezone-picker";
import ContainerWrapper from "../../components/ContainerWrapper";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";

import accordion from "../../assets/images/Accordian.svg";
import Calendar from "react-calendar";
import SlotBooking from "../../components/SlotBooking/SlotBooking.js";
import closeBtn from "../../assets/images/closeBtn.svg";
import infoIcon from "../../assets/images/infoIcon.png";
const closeIcon = (
  <img src={closeBtn} alt="closeBtn" className="closeIconBtnAllReviewPopUp" />
);

const FormItem = Form.Item;
const { Option } = Select;
const timeZones = {
  "(GMT-10:00) Hawaii Time": "Pacific/Honolulu",
  "(GMT-08:00) Pacific Time": "America/Los_Angeles",
  "(GMT-07:00) Mountain Time": "America/Denver",
  "(GMT-07:00) Mountain Time - Arizona": "America/Phoenix",
  "(GMT-06:00) Central Time": "America/Chicago",
  "(GMT-05:00) Eastern Time": "America/New_York",
};

const VenueEdit = (props) => {
  const { data, isEdit, callback, started } = props;
  const { InteractionVenue } = FormSchema;
  let mileref = useRef(null);
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(InteractionVenue),
  });
  const {
    indv,
    indv: {
      address: {
        addressLine1,
        addressLine2,
        city,
        country,
        postalCode,
        state,
        lat,
        lng,
      },
      bizid,
      locations,
    },
  } = useSelector(({ auth }) => auth);
  const getInitialDataset = () => {
    return {
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    };
  };

  console.log("Venue Type indv", indv);
  const parents = locations?.filter(
    (row) => row.parentLocation == null && row.objid !== data.objid
  );
  const [useBizAddress, setBizAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [parent, setParent] = useState(isEdit ? data.parentLocation : null);
  const [timeZone, setTimeZone] = useState(isEdit ? data.timeZone : null);

  const [popupWindow, setPopupWindow] = useState(null);
  const [htmlContent, setHtmlContent] = useState("");

  const [workHours, setWorkHours] = useState(isEdit ? data.workingHours : "");
  const [modalVisible, setModalVisible] = useState(false);
  const [deliveryOptions, setDeliveryOptions] = useState(
    isEdit ? data?.deliveryOptions?.split(",") : []
  );
  const [showMiles, setShowMiles] = useState(false);
  const [dataset, setDataset] = useState(
    isEdit ? data.businessWorkingHours : getInitialDataset()
  );
  const [selectedSlot, setSelectedSlot] = useState(
    isEdit ? data.slotDuration : 0
  );
  const [inputNumber, setInputNumber] = useState(
    isEdit ? (data.slotCapacity === -1 ? 1 : data.slotCapacity) : 1
  );
  let catalogueAppointment = data?.catalogueAppointmentType === "APPOINTMENT_PER_ITEM" ? true : false
  let appointmentToggle = (data?.isCampaignAppoinmtnetEnabled || data?.isCatalogueAppointmentEnabled) ? false : true  
  const [toggle, setToggle] = useState(isEdit ? appointmentToggle : false);
  const [appointmentModal, setAppointmentModal] = useState(false);
  const [isItemLevel, setIsItemLevel] = useState(isEdit && data?.catalogueAppointmentType !== null && catalogueAppointment ? true : false);
  const [isCartLevel, setIsCartLevel] = useState(isEdit && data?.catalogueAppointmentType !== null && !catalogueAppointment ? true : false);
  const [catalogueAppoint, setCatalogueAppoint] = useState(isEdit ? data?.isCatalogueAppointmentEnabled : false);
  const [isOnCampaign, setOnCampign] = useState(isEdit ? data?.isCampaignAppoinmtnetEnabled : false);

  const handleInputChange = (value) => {
    setInputNumber(value);
  };
  const mapAbbreviationToFullDay = (abbreviatedDay) => {
    const dayMap = {
      Mo: "Monday",
      Tu: "Tuesday",
      We: "Wednesday",
      Th: "Thursday",
      Fr: "Friday",
      Sa: "Saturday",
      Su: "Sunday",
    };

    return dayMap[abbreviatedDay] || abbreviatedDay;
  };

  function areValuesNotEmpty(obj) {
    console.log("obj>>>", obj);
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key].length === 0) {
        return false; // If any value is an empty array, return false
      }
    }
    console.log("obj>>>", obj);
    return true; // All values are not empty arrays
  }
  const parseInputValue = (value) => {
    const daysAndSlots = value
      .split("; ")
      .map((daySlots) => daySlots.split(" "));
    const parsedValue = {};

    daysAndSlots.forEach(([day, ...slots]) => {
      parsedValue[mapAbbreviationToFullDay(day)] = slots.map((slot) =>
        slot.split(",")
      );
    });

    return parsedValue;
  };
  function parseSchedule(input) {
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const schedule = {};

    // Initialize empty arrays for each day
    daysOfWeek.forEach((day) => {
      schedule[day] = [];
    });

    // Split input by day
    const daySchedules = input.split(";");

    // Process each day's schedule
    daySchedules.forEach((daySchedule) => {
      const [day, ...timings] = daySchedule.trim().split(/\s+/);
      const dayIndex = daysOfWeek.findIndex((d) => d.startsWith(day));
      console.log("timings>>>", timings);
      if (dayIndex !== -1) {
        schedule[daysOfWeek[dayIndex]] = timings[0]?.split(",");
      }
    });
    setWorkHours(input);
    setDataset(schedule);
    console.log("schedule", schedule);
    // return schedule;
  }
  // const handleHoursChange = (value) => {
  //   console.log("what handleHoursChange", value);
  //   const workhrs = dataFormateWithDays(value);
  //   const parseValue = parseInputValue(value)
  //   const pasreArray = Object.entries(parseValue)

  //   setDataset((prevData) => {
  //     const updatedData = { ...prevData };

  //     for (const day in workhrs) {
  //       if (workhrs.hasOwnProperty(day)) {

  //         if (prevData.hasOwnProperty(day)) {
  //           const updatedSlots = prevData[day].filter(
  //             (slot) => !workhrs[day].some((selectedSlot) => selectedSlot === slot) && pasreArray.includes(slot)
  //           );
  //           const uniqueSlots = Array.from(new Set([...updatedSlots, ...workhrs[day]]));

  //           console.log("updatedSlots", updatedSlots)

  //           // updatedData[day] = uniqueSlots;
  //           updatedData[day] = uniqueSlots.length > 0 ? uniqueSlots : [];

  //         } else {
  //           updatedData[day] = [...workhrs[day]];
  //         }
  //       }
  //     }
  //     console.log("what return", updatedData)

  //     return updatedData;
  //   });

  //   setWorkHours(value);
  // };
  const handleHoursChange = (value) => {
    console.log("what value>>>", value);
    parseSchedule(value);
  };
  const [formData, setFormData] = useState({
    bizid,
    locName: isEdit ? data.locName : "",
    phone: isEdit ? data.phone : "",
    postalCode: isEdit ? data.postalCode : "",
    addressLine1: isEdit ? data.addressLine1.trim() : "",
    addressLine2: isEdit ? data.addressLine2.trim() : "",
    city: isEdit ? data.city : "",
    state: isEdit ? data.state : "",
    ctryCode: isEdit ? (data.ctryCode ? data.ctryCode : "+1") : "+1",
    country: isEdit ? data.country : "",
    lat: isEdit ? data.lat : "",
    lng: isEdit ? data.lng : "",
  });

  const dataFormateWithDays = (value) => {
    let dataset = {};
    value?.split(";").forEach((row) => {
      row = row.trim();
      const pair = row?.split(" ");
      const dayFromHours = days[pair[0]];
      const periods = pair[1];
      const dayName = periods?.replace(/,/g, ", ");
      const stringWithoutCommas = dayName?.replace(/,/g, "");
      const block = stringWithoutCommas?.split(" ");

      if (!dataset[dayFromHours]) {
        dataset[dayFromHours] = [];
      }

      dataset[dayFromHours] = dataset[dayFromHours]?.concat(block);
    });
    return dataset;
  };

  useEffect(() => {
    if (useBizAddress) {
      setFormData({
        ...formData,
        postalCode: postalCode,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        state: state,
      });
    } else {
      setFormData({
        ...formData,
        postalCode: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
      });
    }
  }, [useBizAddress]);

  useEffect(() => {
    setFormData({
      bizid,
      locName: isEdit ? data.locName : "",
      phone: isEdit ? data.phone : "",
      postalCode: isEdit ? data.postalCode : "",
      addressLine1: isEdit ? data.addressLine1.trim() : "",
      addressLine2: isEdit ? data.addressLine2.trim() : "",
      city: isEdit ? data.city : "",
      state: isEdit ? data.state : "",
      ctryCode: isEdit ? (data.ctryCode ? data.ctryCode : "+1") : "+1",
      country: isEdit ? data.country : "",
      lat: isEdit ? data.lat : "",
      lng: isEdit ? data.lng : "",
    });
  }, [started, data]);

  useEffect(() => {
    if (
      Array.isArray(deliveryOptions) &&
      (deliveryOptions.includes("homedel") ||
        deliveryOptions.includes("homeservice"))
    ) {
      setShowMiles(true);
    } else {
      setShowMiles(false);
    }
  }, [deliveryOptions]);
  const getGeoLocationn = async (payload) => {
    const resp = await UserActions.getGeoLocation({
      postalCode: payload?.postalCode,
      country: payload?.country,
      addressLine1: payload?.addressLine1.trim(),
      addressLine2: payload?.addressLine2.trim(),
      city: payload?.city,
      state: payload?.state,
    });

    if (resp.success) {
      return { addr: { lat: resp.addr.lat, lng: resp.addr.lng } };
    }
    return { addr: { lat, lng } };
  };

  const save = async (payload) => {
    console.log("payload>>>", payload);
    // if (payload.phone && !payload.ctryCode) {
    //   setError("ctryCode", "Country code is required");
    //   message.error("Country code is required");
    //   return false;
    // }

    if (showMiles) {
      if (
        mileref.current.value <= 0 ||
        mileref.current.value === undefined ||
        mileref.current.value === null
      ) {
        message.error("miles required");
        return false;
      }
    }

    setIsLoading(true);

    const { addr } = await getGeoLocationn(payload);
    const output = {};

    for (const day in dataset) {
      const upperCaseDay = day.toUpperCase();
      output[upperCaseDay] = dataset[day];
    }

    var response = {};
    let finalObject = payload;
    finalObject.lat = addr.lat;
    finalObject.lng = addr.lng;
    finalObject.country = country;
    finalObject.bizid = bizid;
    finalObject.postalCode = payload.postalcode;
    finalObject.ctryCode = formData.ctryCode;
    finalObject.parentLocation = parent;
    finalObject.timeZone = timeZone;
    finalObject.workingHours = workHours;
    finalObject.deliveryOptions = deliveryOptions?.join();
    finalObject.deliveryWithin = showMiles ? mileref.current.value : null;
    finalObject.workingHoursAndSlots = {
      workingHours: output,
      slotDurationInMin: selectedSlot || null,
      slotCapacity: !toggle ? inputNumber : -1,
      catalogueAppointmentType: catalogueAppoint ? isCartLevel ? "APPOINTMENT_PER_CART" : "APPOINTMENT_PER_ITEM" : null,
      isCampaignBookingEnabled: isOnCampaign
    };
    console.log("toggle",toggle)

    finalObject.addressLine1 = finalObject.addressLine1.trim();
    finalObject.addressLine2 = finalObject.addressLine2.trim();
    finalObject.city = finalObject.city.trim();
    finalObject.state = finalObject.state.trim();

    if (isEdit) {
      finalObject.objid = data.objid;
      response = await UserActions.updateLocation(finalObject);
    } else {
      response = await UserActions.addLocation(finalObject);
    }
    setIsLoading(false);

    if (response.success) {
      message.success("Venue saved");
      callback();
    } else {
      message.error(response?.msg);
    }
  };

  const validatePostalAddress = async (value, country) => {
    const pattern = new RegExp("^" + postalPattern[country] + "$");

    if (pattern.test(value)) return true;
    else return false;
  };
  const apiCall = (code) => {
    fetch(
      `https://api.dev.fyndr.us/appointment/googleCalendar/permission?code=${code}&scope=email profile https://www.googleapis.com/auth/calendar openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&authuser=1&prompt=none`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(" apiCall", data);
      });
  };
  const { signIn } = useGoogleLogin({
    // onSuccess: (codeResponse) => { apiCall(codeResponse.code); console.log('Login success:', codeResponse) },
    onSuccess: (codeResponse) => {
      console.log("Login success:", codeResponse);
    },
    onFailure: (error) => console.log("Login Failed:", error),
    clientId:
      "283240579326-2jp6j8jvekme9o4ddcsesa8rl81evidf.apps.googleusercontent.com",
    redirectUri:
      "https://api.dev.fyndr.us/appointment/googleCalendar/permission&response_type=code&scope=https://www.googleapis.com/auth/calendar%20https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile",
    scope:
      "profile email https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly",
    accessType: "online",
    // responseType: "code",
    responseType: "token",
    prompt: "consent",
  });

  const openPopup = () => {
    const newWindow = window.open("", "popup");
    setPopupWindow(newWindow);

    // Listen for messages from the popup window
    window.addEventListener("message", (event) => {
      // Check if the message is from the popup window
      if (event.source === newWindow) {
        // Update the htmlContent state with the received message
        setHtmlContent(event.data);
      }
    });
  };

  const handleSubmits = (event) => {
    event.preventDefault();
    const htmlContent = event.target.htmlContent.value;
    popupWindow.postMessage(htmlContent, "*");
  };

  const checkEventPermisson = async (value) => {
    // setToggle(value)
  };
  const checkPostalCode = async () => {
    setIsLoading(true);
    let value = getValues("postalcode");
    let response = await validatePostalAddress(value, country);
    setIsLoading(false);
    if (!response) {
      setError("postalcode", {
        types: {
          required: "This is required",
          minLength: "This is minLength",
        },
        message:
          value.length < 1
            ? "Postal Code can not be blank"
            : "Postal code is Invalid",
      });
    } else {
      clearErrors("postalcode");
      setValue("postalcode", value);
      setIsLoading(true);

      const resp = await UserActions.getLocationFromPostalCode(
        `${value},${country}`
      );
      setIsLoading(false);
      if (resp.success) {
        setValue("state", resp.state);
        setValue("city", resp.city);
      }
    }
  };
  console.log("newoutpit", dataset);

  const layout = {
    labelCol: {
      // span: 4,
      md: 10,
      sm: 10,
      lg: 24,
      xl: 24,
    },
    wrapperCol: {
      // span: 15,
    },
  };

  const tailLayout = {
    // wrapperCol: {
    //   offset: 4,
    //   span: 16,
    // },
  };

  const days = {
    Mo: "Monday",
    Tu: "Tuesday",
    We: "Wednesday",
    Th: "Thursday",
    Fr: "Friday",
    Sa: "Saturday",
    Su: "Sunday",
  };

  const onChange = (value) => {
    setDeliveryOptions(value);
  };
  const weekDays = [
    { value: "sunday", label: "Sunday" },
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
  ];
  useEffect(() => {
    if (!catalogueAppoint && !isOnCampaign) {
      setToggle(true);
    }
  }, [catalogueAppoint, isOnCampaign]);

  const slotOptions = [
    { value: 0, label: "Select an option" },
    { value: 30, label: "30 Minutes slot" },
    { value: 60, label: "1 Hour slot" },
    { value: 90, label: "1.5 Hours slot" },
    { value: 120, label: "2 Hours slot" },
  ];
  const handleSelectChanges = (value) => {
    const intValue = parseInt(value, 10);
    setSelectedSlot(intValue);
  };

  return (
    <ContainerWrapper
      title={"Location Details"}
      headerOption={
        <Button type="link" onClick={callback} size="large">
          Go Back
        </Button>
      }
    >
      <Form {...layout}>
        {!isEdit && (
          <FormItem {...tailLayout} style={{ justifyContent: "center" }}>
            <Checkbox
              checked={useBizAddress}
              onChange={(e) => {
                setBizAddress(e.target.checked);
              }}
              style={{
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {" "}
              Use business address
            </Checkbox>
          </FormItem>
        )}

        <FormItem name="Parent Location">
          <div
            style={{
              position: "relative",
              height: "46px",
            }}
          >
            <p
              className="loc_p"
              style={{
                top: -10,
                left: "24px",
              }}
            >
              <span>Parent Location</span>
            </p>
            <Select
              className="country-code-dropdown"
              style={{
                width: "100%",
                position: "absolute",
                top: "0px",
                left: 0,
                height: "33px",
                border: "none",
                borderRadius: "5px",
                marginRight: "8px",
                zIndex: "1",
              }}
              value={parent}
              defaultValue={parent}
              onChange={(value) => {
                setParent(value);
              }}
              allowClear={true}
            >
              {parents?.map((item, index) => {
                return (
                  <Option key={index} value={item.objid}>
                    {item.locName}
                  </Option>
                );
              })}
            </Select>
          </div>
        </FormItem>

        <FormItem
          name="Venue Name"
          // help={errors.locName?.message}
          validateStatus={errors.locName ? "error" : "success"}
        >
          <div
            style={{
              position: "relative",
              height: "46px",
            }}
          >
            <p
              className="loc_p"
              style={{
                top: -10,
                left: "24px",
              }}
            >
              <span style={{color: errors.locName ? "red" : ""}}>Venue Name</span>
            </p>
            <input
              ref={register}
              type="text"
              className="form-control"
              name="locName"
              autoComplete="off"
              defaultValue={formData.locName}
              style={{
                height: "46px",
                borderRadius: "5px",
                width: "100%",
                border: `1px solid ${
                  errors.locName ? "red" : "rgba(230, 230, 230, 1)"
                }`,
                padding: "0px 24px",
              }}
            />
            {errors.locName && (
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "16px",
                  transform: "translateY(-50%)",
                  color: "#999",
                }}
              >
                <Tooltip placement="bottomRight" title={errors.locName?.message} color="#fff" overlayInnerStyle={{ color: "black" }}>
                <GoAlertFill style={{ color: "red" }} />
                </Tooltip>
              </span>
            )}
          </div>
        </FormItem>

        {/* <FormItem
          label="Venue Type"
          help={errors.locType?.message}
          validateStatus={errors.locType ? "error" : "success"}
        >
          <input
            type="text"
            className={`form-control`}
            name="locType"
            ref={register}
            placeholder="Interaction Venue Type"
            defaultValue={formData.locType}
          />
          <p style={{ maxWidth: "92%", color: Theme.blue }}>
            like Retail, Office, Warehouse, Restaurant, Personal Care, Sports
            Activity, Clinic, Hospital, Gym, Factory, Library or Others..
          </p>
        </FormItem> */}
        <FormItem
          name="Phone Number"
          help={errors.phone?.message || errors.ctryCode?.message}
          validateStatus={errors.phone || errors.ctryCode ? "error" : "success"}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "46px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
          >
            <p
              className="loc_p"
              style={{
                top: -10,
                left: "24px",
              }}
            >
              <span>Phone Number</span>
            </p>
            <input
              disabled
              type="text"
              className={`form-control country`}
              style={{
                width: "58px",
                border: "1px solid rgba(230, 230, 230, 1)",
                textAlign: "center",
                height: "100%",
                marginRight: "12px",
                borderRadius: "5px",
              }}
              name="ctryCode"
              ref={register}
              placeholder="+1"
              defaultValue={formData.ctryCode}
            />
            <input
              type="text"
              className="form-control"
              name="phone"
              ref={register}
              autoComplete="off"
              defaultValue={formData.phone}
              style={{
                flex: 1,
                border: "1px solid rgba(230, 230, 230, 1)",
                height: "100%",
                paddingLeft: "8px",
                borderRadius: "5px",
                padding: "0px 24px",
              }}
            />
          </div>
        </FormItem>

        <FormItem name="Country">
          <div
            style={{
              position: "relative",
              height: "46px",
            }}
          >
            <p
              className="loc_p"
              style={{
                top: -10,
                left: "24px",
              }}
            >
              <span>Country</span>
            </p>
            <input
              ref={register}
              className={`form-control country`}
              name="Country"
              defaultValue={country}
              disabled
              style={{
                height: "46px",
                borderRadius: "5px",
                width: "100%",
                border: "1px solid rgba(230, 230, 230, 1)",
                padding: "0px 24px",
              }}
            />
          </div>
        </FormItem>
        <FormItem
          name="Postal Code"
          // help={errors.postalcode?.message}
          validateStatus={errors.postalcode ? "error" : "success"}
        >
          <div
            style={{
              position: "relative",
              height: "46px",
            }}
          >
            <p
              className="loc_p"
              style={{
                top: -10,
                left: "24px",
              }}
            >
              <span style={{color: errors.locName ? "red" : ""}}>Postal Code</span>
            </p>
            <input
              type="text"
              ref={register}
              className={`form-control`}
              name="postalcode"
              defaultValue={formData.postalCode}
              style={{
                height: "46px",
                borderRadius: "5px",
                width: "100%",
                border: `1px solid ${
                  errors.postalcode ? "red" : "rgba(230, 230, 230, 1)"
                }`,
                padding: "0px 24px",
              }}
              onChange={(e) =>
                setFormData({ ...formData, postalCode: e.target.value })
              }
              onBlur={checkPostalCode}
              autoComplete="off"
            />
            {errors.postalcode && (
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "16px",
                  transform: "translateY(-50%)",
                  color: "#999",
                }}
              >
                <Tooltip placement="bottomRight" title={errors.postalcode?.message} color="#fff" overlayInnerStyle={{ color: "black" }}>
                <GoAlertFill style={{ color: "red" }} />
                </Tooltip>
              </span>
            )}
          </div>
        </FormItem>
        
        <FormItem
          name="Business Address 1"
          // help={errors.addressLine1?.message}
          validateStatus={errors.addressLine1 ? "error" : "success"}
        >
          <div style={{ position: "relative" }}>
            <p
              className="loc_p"
              style={{
                top: -10,
                left: "24px",
                color: errors.locName ? "red" : ""
              }}
            >
              Address 1
            </p>
            <input
              ref={register}
              type="text"
              className={`form-control`}
              name="addressLine1"
              defaultValue={formData.addressLine1}
              autoComplete="off"
              style={{
                height: "46px",
                borderRadius: "5px",
                width: "100%",
                border: `1px solid ${
                  errors.addressLine1 ? "red" : "rgba(230, 230, 230, 1)"
                }`,
                padding: "0px 24px",
              }}
            />
            {errors.addressLine1 && (
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "16px",
                  transform: "translateY(-50%)",
                  color: "#999",
                }}
              >
                <Tooltip placement="bottomRight" title={errors.addressLine1?.message} color="#fff" overlayInnerStyle={{ color: "black" }}>
                <GoAlertFill style={{ color: "red" }} />
                </Tooltip>
              </span>
            )}
          </div>
        </FormItem>
        <FormItem
          style={{ marginBottom: "30px" }}
          name="Address 2"
          // help={errors.addressLine2?.message}
          validateStatus={errors.addressLine2 ? "error" : "success"}
        >
          <div style={{ position: "relative" }}>
            <p
              className="loc_p"
              style={{
                top: -10,
                left: "24px",
              }}
            >
              Address 2
            </p>
            <input
              ref={register}
              type="text"
              className="form-control  inputQuick"
              name="addressLine2"
              autoComplete="off"
              defaultValue={formData.addressLine2}
              style={{
                height: "46px",
                borderRadius: "5px",
                width: "100%",
                border: "1px solid rgba(230, 230, 230, 1)",
                padding: "0px 24px",
              }}
            />
          </div>
        </FormItem>
        <FormItem
          name="City"
          // help={errors.city?.message}
          validateStatus={errors.city ? "error" : "success"}
        >
          <div style={{ position: "relative" }}>
            <p
              className="loc_p"
              style={{
                top: -10,
                left: "24px",
                color: errors.locName ? "red" : "",
              }}
            >
              City
            </p>

            <input
              ref={register}
              type="text"
              className={`form-control ${errors.city ? "error" : ""}`}
              name="city"
              autoComplete="off"
              defaultValue={formData.city}
              style={{
                height: "46px",
                borderRadius: "5px",
                width: "100%",
                border: `1px solid ${
                  errors.city ? "red" : "rgba(230, 230, 230, 1)"
                }`,
                padding: "0px 24px",
              }}
            />
            {errors.city && (
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "16px",
                  transform: "translateY(-50%)",
                  color: "#999",
                }}
              >
                <Tooltip placement="bottomRight" title={errors.city?.message} color="#fff" overlayInnerStyle={{ color: "black" }}>
                <GoAlertFill style={{ color: "red" }} />
                </Tooltip>
              </span>
            )}
          </div>
        </FormItem>

        <FormItem
          name="State"
          validateStatus={errors.state ? "error" : "success"}
        >
          <div>
            <p
              className="loc_p"
              style={{
                top: -10,
                left: "24px",
                color: errors.locName ? "red" : "",
              }}
            >
              State
            </p>
            <input
              ref={register}
              type="text"
              name="state"
              autoComplete="off"
              defaultValue={formData.state}
              className="form-control  inputQuick"
              style={{
                height: "46px",
                borderRadius: "5px",
                width: "100%",
                border: `1px solid ${
                  errors.state ? "red" : "rgba(230, 230, 230, 1)"
                }`,
                padding: "0px 24px",
              }}
            />
            {errors.state && (
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "16px",
                  transform: "translateY(-50%)",
                  color: "#999",
                }}
              >
                <Tooltip placement="bottomRight" title={errors.state?.message} color="#fff" overlayInnerStyle={{ color: "black" }}>
                <GoAlertFill style={{ color: "red" }} />
                </Tooltip>
              </span>
            )}
          </div>
        </FormItem>
        {showMiles && (
          <FormItem name="Within Miles">
            <div>
              <p
                className="loc_p"
                style={{
                  top: -10,
                  left: "24px",
                }}
              >
                Within Miles
              </p>
              <input
                className={`form-control`}
                type="number"
                ref={mileref}
                defaultValue={data.deliveryWithin}
                style={{
                  height: "46px",
                  borderRadius: "5px",
                  width: "100%",
                  border: "1px solid rgba(230, 230, 230, 1)",
                  padding: "0px 24px",
                }}
              />
            </div>
          </FormItem>
        )}
        {!parent && (
          <>
            <FormItem {...tailLayout} style={{ justifyContent: "center" }}>
              <div style={{ position: "relative" }}>
                <p
                  className="loc_p"
                  style={{
                    top: -10,
                    left: "24px",
                  }}
                >
                  Select Timezone
                </p>
                <TimezonePicker
                  style={{ borderRadius: "5px", width: "100%", height: "46px" }}
                  absolute={false}
                  timezones={timeZones}
                  defaultValue={timeZone}
                  onChange={(value) => setTimeZone(value)}
                />
              </div>
            </FormItem>
            <FormItem {...tailLayout}>
              <div className="businessUserSLot">
                <Row
                  style={{ paddingBottom: "12px", alignItems: "center" }}
                  justify={"space-between"}
                >
                  <Col>
                    <div className="workSched">
                      Work Schedule (12-hour format)
                    </div>
                  </Col>
                  <Col>
                    <Button
                      className="slot-btn"
                      style={{ height: "36px" }}
                      onClick={() => setModalVisible(true)}
                      type="info"
                    >
                      Set Working Schedule
                    </Button>
                  </Col>
                </Row>

                <Row className="weekDayTable">
                  {weekDays.map((day, index) => (
                    <Col
                      key={index}
                      style={{
                        border: "solid #D3D6E1 1px",
                        // alignContent: "center",
                        width: `${100 / 7}%`,
                        paddingBottom: "24px",
                      }}
                    >
                      <div
                        className="weekNames"
                        style={{
                          color: !workHours && day.label ? "grey" : "#257cdb",
                        }}
                      >
                        {day.label.slice(0, 3)}
                      </div>
                      <Row justify={"center"} style={{ paddingTop: "12px" }}>
                        {dataset[day.label] &&
                          dataset[day.label].map((timeSlot, timeIndex) => {
                            const startMoment = moment(
                              timeSlot.split("-")[0],
                              "HH:mm"
                            );
                            const endMoment = moment(
                              timeSlot.split("-")[1],
                              "HH:mm"
                            );
                            const formattedStartTime =
                              startMoment.format("hh:mm A");
                            const formattedEndTime =
                              endMoment.format("hh:mm A");

                            return (
                              <Row key={timeIndex}>
                                <Col
                                  span={24}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                  }}
                                >
                                  <Col span={24} className="oneSlot">
                                    <div className="slotTime">
                                      {formattedStartTime} - {formattedEndTime}
                                    </div>
                                  </Col>
                                </Col>
                              </Row>
                            );
                          })}
                      </Row>
                    </Col>
                  ))}
                </Row>
                {Object.values(dataset).some((value) => value?.length > 0) && (
                  <>
                    <Row
                      style={{
                        marginTop: "12px ",
                        marginBottom: "34px",
                        display: "flex",
                      }}
                    >
                      <Row
                        style={{
                          backgroundColor: "rgba(244, 248, 253, 1)",
                          borderRadius: "10px",
                          marginRight: "12px",
                        }}
                      >
                        <Col
                          style={{
                            backgroundColor: "#EAF2FC",
                            padding: "13px 12px",
                            borderRadius: "10px",
                            color: "rgba(51, 51, 51, 1)",
                            fontSize: "14px",
                            fontWeight: "400",
                            boxShadow: "4px 1px 3px rgba(0, 0, 0, 0.05)",
                          }}
                        >
                          <Checkbox
                            checked={catalogueAppoint}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setCatalogueAppoint(
                                isChecked ? !catalogueAppoint : isChecked
                              );
                              if (isChecked) {
                                setIsItemLevel(true);
                                setIsCartLevel(false);
                                setToggle(false);
                              }
                            }}
                          >
                            Catalogue Appointment
                          </Checkbox>
                        </Col>
                        {catalogueAppoint && (
                          <>
                            <Col
                              style={{
                                padding: "13px 6px 13px 12px",
                                borderRadius: "10px",
                                color: "rgba(51, 51, 51, 1)",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              <Radio
                                checked={isItemLevel}
                                onChange={(value) => {
                                  setIsItemLevel(value);
                                  if (value) {
                                    setIsCartLevel(false);
                                  }
                                }}
                              >
                                Item Level Appointment
                              </Radio>
                            </Col>
                            <Col
                              style={{
                                padding: "13px 6px 13px 12px",
                                borderRadius: "10px",
                                color: "rgba(51, 51, 51, 1)",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              <Radio
                                checked={isCartLevel}
                                onChange={(value) => {
                                  setIsCartLevel(value);
                                  if (value) {
                                    setIsItemLevel(false);
                                  }
                                }}
                              >
                                Cart Level Appointment
                              </Radio>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Col
                        style={{
                          backgroundColor: "#EAF2FC",
                          padding: "13px 6px 13px 12px",
                          borderRadius: "10px",
                          color: "rgba(51, 51, 51, 1)",
                          marginRight: "12px",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        <Checkbox
                          checked={isOnCampaign}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setOnCampign(isChecked ? !isOnCampaign : isChecked);
                            if (isChecked) {
                              setToggle(false);
                            }
                          }}
                        >
                          Campaign Appointment
                        </Checkbox>
                      </Col>
                      <Col
                        style={{
                          padding: "0px 0px",
                          borderRadius: "10px",
                          color: "rgba(51, 51, 51, 1)",
                          backgroundColor: "white",
                          fontSize: "14px",
                          fontWeight: "400",
                          alignContent: "center",
                        }}
                      >
                        <Radio
                          checked={toggle}
                          onChange={(e) => {
                            setToggle(e.target.checked);
                            if (e.target.checked) {
                              setSelectedSlot(0);
                              setCatalogueAppoint(false);
                              setIsCartLevel(false);
                              setIsItemLevel(false);
                              setOnCampign(false);
                            }
                          }}
                        >
                          No Appointments
                        </Radio>
                      </Col>
                    </Row>
                    {(isOnCampaign || catalogueAppoint) && (
                      <>
                        <Row justify={"space-between"} gutter={[24, 24]}>
                          <Col xs={24} xl={12}>
                            <div
                              style={{ position: "relative", height: "46px" }}
                            >
                              <p
                                className="loc_p"
                                style={{
                                  top: -10,
                                  left: "24px",
                                }}
                              >
                                Slot Duration ( 30 Mins - 2 Hours)
                                <Popover
                                  content={
                                    "Set Working Schedule To Set Appointment"
                                  }
                                >
                                  <img
                                    src={infoIcon}
                                    style={{
                                      color: Theme.blue,
                                      marginLeft: ".5rem",
                                      width: "18px",
                                      height: "18px",
                                    }}
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                  />
                                </Popover>
                              </p>
                              <select
                                style={{
                                  width: "100%",
                                  height: "46px",
                                  borderRadius: "5px",
                                  backgroundSize: "20px",
                                  background: " #FFF",
                                  padding: "0px 24px",
                                  alignItems: "center",
                                  appearance: "none",
                                  backgroundImage: `url(${accordion})`,
                                  backgroundPosition:
                                    "calc(100% - 12px) center",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "20px",
                                  outline: "none",
                                }}
                                className="loc_slot"
                                value={selectedSlot}
                                onChange={(e) =>
                                  handleSelectChanges(e.target.value)
                                }
                              >
                                {slotOptions.map((slot) => (
                                  <option key={slot.value} value={slot.value}>
                                    {slot.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                            <div style={{ position: "relative" }}>
                              <p
                                className="loc_p"
                                style={{
                                  top: -10,
                                  left: "24px",
                                }}
                              >
                                Appointments Per Slot
                              </p>
                              <InputNumber
                                min={1}
                                defaultValue={1}
                                max={100000}
                                className="openSlot"
                                placeholder="please choose no. of appointment"
                                value={inputNumber}
                                onChange={handleInputChange}
                                style={{
                                  borderRadius: "5px",
                                  width: "100%",
                                  height: "46px",
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                )}
              </div>

              <Row style={{ marginTop: "24px " }} justify={"space-between"}>
                <Col xs={24} lg={24} md={24} xl={18}>
                  <Button
                    className="slot-btn"
                    onClick={() => {
                      setToggle(false);
                      setSelectedSlot(0);
                      setInputNumber(0);
                      setWorkHours("");
                      setDataset({
                        Monday: [],
                        Tuesday: [],
                        Wednesday: [],
                        Thursday: [],
                        Friday: [],
                        Saturday: [],
                        Sunday: [],
                      });
                    }}
                    type="warning"
                  >
                    Reset Working Hours
                  </Button>
                </Col>
                <Col xs={24} sm={10} md={8} lg={8} xl={6}>
                  <Row justify={"end"}>
                    <Col xs={24} sm={10} md={8} lg={8} xl={10}>
                      <Button
                        type="warning"
                        onClick={callback}
                        className="slot-btn"
                      >
                        Go Back
                      </Button>
                    </Col>
                    <Col xs={24} sm={10} md={8} lg={8} xl={8}>
                      <Button
                        disabled={
                          isLoading ||
                          (catalogueAppoint && !isItemLevel && !isCartLevel)
                        }
                        onClick={() => {
                          handleSubmit(
                            (data) =>
                              // {
                              save(data),
                            // console.log("data", data),
                            // },
                            (err) => console.log(err)
                          )();
                        }}
                        size="large"
                        className="slot-btn_save"
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormItem>
          </>
        )}

        {parent && (
          <Row gutter={[, 10]} style={{ marginTop: "24px " }}>
            <Col xs={24} lg={24} md={24} xl={19}>
              {!parent && (
                <Button
                  onClick={() => {
                    setToggle(false);
                    setSelectedSlot(0);
                    setInputNumber(0);
                    setWorkHours("");
                    setDataset({
                      Monday: [],
                      Tuesday: [],
                      Wednesday: [],
                      Thursday: [],
                      Friday: [],
                      Saturday: [],
                      Sunday: [],
                    });
                  }}
                  type="warning"
                  className="slot-btn"
                >
                  Reset Working Hours
                </Button>
              )}
            </Col>
            <Row justify={"end"}>
              <Col xs={24} sm={10} md={8} lg={8} xl={24}>
                <Row justify={"end"}>
                  <Col xs={24} sm={10} md={8} lg={8} xl={18}>
                    <Button
                      type="warning"
                      onClick={callback}
                      className="slot-btn"
                    >
                      Go Back
                    </Button>
                  </Col>
                  <Col xs={24} sm={10} md={8} lg={8} xl={2}>
                    <Button
                      disabled={isLoading}
                      onClick={() => {
                        handleSubmit(
                          (data) =>
                            // {
                            save(data),
                          // console.log("data", data),
                          // },
                          (err) => console.log(err)
                        )();
                      }}
                      type="primary"
                      className="slot-btn_save"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Row>
        )}

        <Modal
          onCancel={() => {
            setModalVisible(false);
          }}
          onOk={() => setModalVisible(false)}
          // footer={() => null}
          title="Pick Working Hours"
          visible={modalVisible}
          width={"930px"}
          style={{ height: "900px" }}
        >
          <Hours
            id={"workHours"}
            value={workHours}
            onChange={handleHoursChange}
          />
          {/* {
            ReactDOM.render(<Hours  onChange={handleHoursChange} />, document.body)

          } */}
        </Modal>

        <Modal
          onCancel={() => {
            setAppointmentModal(false);
          }}
          // onOk={() => setAppointmentModal(false)}
          footer={[
            <Button key="back" onClick={() => setAppointmentModal(false)}>
              Save
            </Button>,
          ]}
          title="Schedule & Appointments "
          visible={appointmentModal}
          closeIcon={closeIcon}
          width={"700px"}
          style={{ height: "319px" }}
        >
          <div>
            <Row>
              <Col span={12}>
                <div
                  style={{
                    position: "relative",
                    width: "314px",
                    // height: "auto",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: -10,
                      left: 8,
                      backgroundColor: "white",
                      padding: "0 4px",
                      zIndex: 999,
                    }}
                  >
                    Working Days
                  </div>
                  <select
                    style={{ width: "100%" }}
                    value={selectedSlot}
                    onChange={(e) => handleSelectChanges(e.target.value)}
                  >
                    <option value="" disabled>
                      Select a slot durationProceed
                    </option>
                    {slotOptions.map((slot) => (
                      <option key={slot.value} value={slot.value}>
                        {slot.label}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    position: "relative",
                    width: "314px",
                    height: "46px",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: -10,
                      left: 8,
                      backgroundColor: "white",
                      padding: "0 4px",
                      zIndex: 999,
                    }}
                  >
                    Working Hours
                  </div>

                  {/* <Select
                      // options={timeOptions}
                      isMulti
                      value={selectedTime}
                      onChange={handleTimeChange}
                      placeholder="Select Time"
                      style={{width:"20vw"}}
                    >
                      {" "}
                      <Row>
                        <Col>
                          {" "}
                          {timeOptions.map((day) => (
                            <Option key={day.value} value={day.value}>
                              {day.label}
                            </Option>
                          ))}
                        </Col>
                        <Col>
                          {" "}
                          {timeOptions.map((day) => (
                            <Option key={day.value} value={day.value}>
                              {day.label}
                            </Option>
                          ))}
                        </Col>
                      </Row>
                    </Select> */}
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      </Form>
    </ContainerWrapper>
  );
};
export default VenueEdit;
