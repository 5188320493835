/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef } from "react";
import {
  Row,
  Col,
  Steps,
  Button,
  Dropdown,
  Modal,
  message,
  Space,
  Form,
  Divider,
  Breadcrumb,
  Popover,
  Table,
  Menu,
  Card,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  Container as ContainerWrapper,
  CatalogueForm as FormCatalogue,
} from "../../components";
import { FormSchema, Actions } from "../../common";
import { Theme } from "../../common";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CategoryForm from "../Category/Edit";
import ItemForm from "../Item/Edit";
import ModifierForm from "../Modifier/Edit";
import AddLocations from "../../pages/Venue/Edit.jsx";

import PriceRow from "./CatalogueItem";
import Loader from "../../components/Loading/Loader";
import LoadingOverlay from "react-loading-overlay";

import UserAction from "../../actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ConfirmationModal } from "../../components";

import Images from "../../assets";
const { Step } = Steps;

const { Meta } = Card;

const { UPDATE_CATELOG_ITEM } = Actions;
const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 8 * 2,
  margin: `0 0 ${8}px 0`,

  // change background colour if dragging
  background: isDragging ? "#f9f9f9" : "#fff",
  boxShadow: isDragging ? `2px 21px 58px -31px ${Theme.blue}` : "",
  border: isDragging ? `1px solid ${Theme.blue}` : "",
  // styles we need to apply on draggables
  ...draggableStyle,
});
//styles
const Edit = ({ isEdit, item, callback }) => {
  const {
    categories,
    items,
    catalogue_items,
    modifiers,
    catalogue_modifier,
  } = useSelector(({ catalogue }) => {
    return catalogue;
  });
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [catalogueCategories, setCatalogueCategories] = useState(null);
  const [unaddedCategories, setUnaddedCategories] = useState(categories);
  const [optionItems, setOptionItems] = useState(null);
  const [started, setStarted] = useState(false);
  const [details, setDetails] = useState(false);
  const [LocationIsEdit, setlocationIsEdit] = useState();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    showSizeChanger: true,
  });
  const dispatch = useDispatch();
  const nameRef = useRef(null);
  const descRef = useRef(null);
  const itemPriceRef = useRef(null);
  const [payload, setPayload] = useState({
    name: item?.name || "",
    description: item?.description || null,
    images: item?.images || [],
    objid: item?.objid || null,
  });

  const {
    indv: { currencySymbol, currency, bizid, locations },
  } = useSelector(({ auth }) => auth);
  const getMenu = () => {
    let menu = categories.map((item) => {
      return {
        name: item.name,
        objid: item.objid,
      };
      // return item;
    });
    menu.sort((a, b) => a.name.localeCompare(b.name));

    return menu;
  };
  const DropdownMenu = (
    <Menu>
      {categories.map((item, key) => {
        return (
          <Menu.Item key={item.objid} onClick={() => setSelectedCategory(item)}>
            {item.name}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
    });
  };

  const getVenues = async () => {
    let validVenues = await locations?.filter(
      (row, key) => row.parentLocation === null
    );
    setVenues(validVenues);
  };
  const filterCategories = async () => {
    if (
      catalogueCategories?.length > 0 ||
      categories.length !== unaddedCategories.length
    ) {
      let tempSet = catalogueCategories?.map((item) => item.objid);

      let tempCategories = categories?.filter((itemRow, index) => {
        if (!tempSet?.includes(itemRow.objid)) {
          return itemRow;
        } else {
          return null;
        }
      });
      catalogueCategories?.sort((a, b) => a.name.localeCompare(b.name));
      tempCategories?.sort((a, b) => a.name.localeCompare(b.name));
      setUnaddedCategories(tempCategories);
    }
  };
  React.useEffect(() => {
    getVenues();
  }, [locations]);
  React.useEffect(() => {
    let values = getMenu();
    if (
      catalogueCategories?.length > 0 ||
      categories.length !== unaddedCategories.length
    ) {
      filterCategories();
    }

    setMenu(values);
  }, [categories, catalogueCategories]);

  const mockData = [];
  for (let i = 0; i < 20; i++) {
    mockData.push({
      key: i.toString(),
      title: `content${i + 1}`,
      description: `description of content${i + 1}`,
    });
  }
  // const initialTargetKeys = mockData
  //   .filter((item) => +item.key > 10)
  //   .map((item) => item.key);
  const [, setMenu] = useState(getMenu());
  const [addCatModal, setAddCatModal] = useState(false);

  // const [selectedKeys, setSelectedKeys] = useState([]);
  const [priceVisibleModal, setPriceVisibleModal] = useState(false);
  const [isModalItemVisible, setIsModalItemVisible] = useState(false);
  const [configItem, setconfigItem] = useState(null);
  const [modifierConfig, setModiferConfig] = useState(null);
  const [isEditMode, setIsEditMode] = useState(true);
  const [modiferModal, setModifierModal] = useState(false);
  const [selectedItems, setselectedItems] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [venues, setVenues] = useState([]);
  const [clickedItems, setClickItems] = useState([]);
  const [catalogueDetails, setCatalogueDetails] = useState(null);
  const [targetKeys, setTargetKeys] = useState(
    catalogueDetails?.locations || []
  );
  const [catalogueItemsList, setCatalogueItemsList] = useState(
    catalogueDetails ? catalogueDetails.catalogueItems : []
  );
  const [descError, setDescError] = useState({
    status: false,
    message: null,
  });
  const { CatalogueForm, ItemPriceForm } = FormSchema;

  const { handleSubmit, errors, control } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
    resolver: yupResolver(CatalogueForm),
  });
  const itemPriceFormHook = useForm({
    mode: "onBlur",
    shouldUnregister: false,
    resolver: yupResolver(ItemPriceForm),
  });
  const restFormValidation = async (data) => {
    if (payload.description < 1) {
      setDescError({ status: true, message: "Please enter description" });
      return;
    } else {
      setDescError({ status: false, message: "" });
    }
    if (payload.images.length < 1) {
      message.error("please select Image");
      return;
    }

    // setCurrentStep(1);
    let dispatchPayload = {
      currency,
      currencySymbol,
      description: payload.description,
      name: data.name,
      images: payload.images,
      bizid,
      objid: payload.objid,
    };

    setIsLoading(true);
    try {
      let resp = await UserAction.addCatalogue(dispatchPayload);
      if (resp.success) {
        await catalogueCategoriesDetails();
        // resp.success ?  : message.error(resp.message);
        setPayload(resp.payload);

        setIsLoading(false);
        setCurrentStep(1);
      } else {
        setIsLoading(false);
        message.error(resp.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const catalogueCategoriesDetails = async () => {
    try {
      await UserAction.fetchCategories({ bizid });

      if (payload.objid !== null) {
        let categoriesInTheCatalogue = await UserAction.fetchCatalogueCategories({
          bizid,
          catalogueId: payload?.objid,
        });
        if (categoriesInTheCatalogue.success) {
          setCatalogueCategories(categoriesInTheCatalogue.payload);
        } else {
          message.error(categoriesInTheCatalogue.message);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getDetailsOfCatalogue = async (ID = null) => {
    if (
      (payload.objid && ID !== null) ||
      (payload.objid && selectedCategory?.objid)
    ) {
      setIsLoading(true);
      let cataLogueDetails = await UserAction.fetchCatalogueDetails({
        bizid,
        catalogueId: payload.objid,
        categoryId: ID || selectedCategory?.objid,
      });

      if (cataLogueDetails.success) {
        catalogueCategoriesDetails();
        setIsLoading(false);
        setCatalogueDetails(cataLogueDetails.payload);
        setTargetKeys(cataLogueDetails.payload.locations);
        let listItems = cataLogueDetails.payload.catalogueItems;
        listItems.map((item, key) => (item.id = item.objid));
        listItems.sort((a, b) => a.slno - b.slno);
        setCatalogueItemsList(listItems);
      } else {
        message.error(cataLogueDetails.message);
      }
    } else {
      if (isEdit) {
        await catalogueCategoriesDetails();
      }

      setSelectedCategory(categories[0]);
    }
  };
  const verifier = async () => {
    switch (currentStep) {
      case 0: {
        handleSubmit(
          (data) => {
            restFormValidation(data);
          },
          (error) => console.log(error)
        )();
        break;
      }
      case 1: {
        handleCategorySelect();
        break;
      }
      case 2: {
        if (catalogueDetails?.catalogueItems.length < 1) {
          message.error("Please add atleast one item to proceed ");
          return;
        }
        setCurrentStep(3);

        break;
      }
      case 3: {
        // if (targetKeys.length < 1) {
        //   message.error("please select atleast one location");
        //   return;
        // }
        setConfirmationPopup(true);
        break;
      }
      default:
        return null;
    }
  };
  const LocationUpdate = async () => {
    let dispatchPayload = {
      bizid,
      catalogueId: payload.objid,
      locations: targetKeys,
    };

    setIsLoading(true);
    let resp = await UserAction.addCatalogueLocations(
      dispatchPayload,
      catalogueDetails?.name
    );

    setIsLoading(false);

    if (resp.success) {
      message.success(resp.message);
      callback(false);
    } else {
      message.error(resp.message);
    }
  };
  const getCategoryItems = () => {
    if (selectedCategory) {
      let newselections = catalogueDetails?.catalogueItems?.filter(
        (item) => item.categoryId == selectedCategory.objid
      );

      let selections = items.filter((item) => {
        let values = newselections?.filter((row) => {
          if (item.objid == row.item.objid) {
            item.price = row.price;
          }
          return row.objid == item.objid;
        })[0];

        return values;
      });

      let optionItems = items.filter((item) => {
        let values = selections.map((row) => {
          return row.objid;
        });

        if (values?.includes(item.objid)) {
          return null;
        } else return item;
      });

      items.sort((a, b) => a.name.localeCompare(b.name));
      setselectedItems(selections);
      setOptionItems(items);
    }
  };

  React.useEffect(() => {
    getCategoryItems();
  }, [selectedCategory, items, catalogueDetails]);
  React.useEffect(() => {
    getDetailsOfCatalogue();
  }, [catalogue_items, catalogue_modifier, selectedCategory, currentStep]);

  React.useEffect(() => {
    UpdateOrders();
  }, [catalogueItemsList]);

  React.useEffect(() => {
    getItemsandModifiers();
  }, []);

  const onSelectChange = (selectedRowKeys) => {
    setTargetKeys(selectedRowKeys);
  };
  const onScroll = (direction, e) => { };
  const validatePriceForm = (handleSubmit) => {
    handleSubmit(
      (data) => {
        //  restFormValidation(data);
        return { staus: true, data };
      },
      (err) => {
        return { status: false };
      }
    )();
  };
  const checkPrice = (item) => {
    let value = catalogue_items.filter((row) => row.objid == item.objid)[0];
    if (value?.price) {
      item.price = value.price;
      return item;
    } else {
      return item;
    }
  };
  const handleDeleteItem = async (item) => {
    let dispatchPayload = {
      bizid,
      objid: item.objid,
      catalogueId: payload.objid,
      categoryId: item.categoryId,
      itemId: item.item.objid,
      price: item.price,
      slno: item.slno,
      status: item.status,
    };
    setIsLoading(true);
    let resp = await UserAction.deleteCatalogueItem(dispatchPayload);
    resp.success ? message.success(resp.message) : message.error(resp.message);
    setIsLoading(false);
    if (resp.success) {
      getDetailsOfCatalogue();
    }
  };
  const handleItemClickCallBack = (objid) => {
    let itemvalue = clickedItems.indexOf(objid);

    if (itemvalue != -1) {
      let tempvalues = clickedItems;
      tempvalues.splice(itemvalue, 1);
      setClickItems(tempvalues);
    } else {
      setClickItems([...clickedItems, objid]);
    }
  };
  const UpdateOrders = async () => {
    let dispatchdata = catalogueItemsList.map((item, index) => {
      return { objid: item.objid, slno: index + 1 };
    });
    let response = await UserAction.updateOrders({
      bizid,
      payload: dispatchdata,
    });
  };
  const CONTENT = (
    <div>
      <p>
        Please drag and drop the items to change the display order within each
        category.
      </p>
    </div>
  );
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      catalogueItemsList,
      result.source.index,
      result.destination.index
    );

    setCatalogueItemsList(items);
  };
  const getItemsandModifiers = async () => {
    setIsLoading(true);
    await UserAction.fetchItems({ bizid });
    await UserAction.fetchModifier({ bizid });
    setIsLoading(false);
  };
  const handleCategorySelect = async (category = categories[0]) => {
    if (category) {
      setSelectedCategory(category);

      getItemsandModifiers();

      getDetailsOfCatalogue(category.objid);
      setCurrentStep(2);
    } else {
      message.error("No category found");
    }
  };
  const columns = [
    {
      title: "Location",
      dataIndex: "locName",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Catalogue",
      dataIndex: "catalogueName",
    },
  ];
  const rowSelection = {
    selectedRowKeys: targetKeys,
    onChange: onSelectChange,
  };
  const Switcher = () => {
    switch (currentStep) {
      case 0: {
        return (
          <Row justify="center" align="middle">
            <Form style={{ minWidth: "100%", padding: "2rem" }} {...layout}>
              <FormCatalogue
                formHook={{ errors, control }}
                nameRef={nameRef}
                descRef={descRef}
                getMarkdownValue={(value) => {
                  setPayload({ ...payload, description: value });
                }}
                desc={payload?.description}
                name={payload?.name}
                imgs={payload?.images}
                descError={descError}
                imageCallback={(value) => {
                  let imgs = payload?.images.filter(
                    (row) => row.index !== value.index
                  );
                  imgs.push(value);
                  imgs.sort((a, b) => a.index > b.index);
                  setPayload({ ...payload, images: imgs });
                }}
              />
            </Form>
          </Row>
        );
      }
      case 1: {
        return (
          <>
            <Row
              style={{ width: "100%", marginTop: "2rem" }}
              justify="space-between"
              align="top"
            >
              <h4>Choose Category</h4>
              <Button onClick={() => setAddCatModal(true)} type="primary">
                Add Category
              </Button>
            </Row>
            <Row
              gutter={[20, 20]}
              style={{ minWidth: "100%", marginTop: "1rem" }}
              align="middle"
              justify="center"
            >
              {Array.isArray(catalogueCategories) &&
                catalogueCategories?.map((item, key) => {
                  return (
                    <Col key={item.objid} span={6}>
                      <Card
                        onClick={async () => {
                          handleCategorySelect(item);
                        }}
                        hoverable
                        style={{
                          width: 220,
                          minHeight: 280,
                          maxHeight: 280,
                        }}
                        cover={
                          <img
                            alt="Category"
                            src={
                              item.images
                                ? item.images[0].img_url
                                : Images.Images.PlaceHolder
                            }
                            style={{ height: 150, objectFit: "cover" }}
                          />
                        }
                      >
                        <Meta
                          title={item.name}
                          description={`Total Items : ${item.totalItems}`}
                          avatar={
                            <i
                              style={{ color: "#60f542" }}
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            ></i>
                          }
                        />
                      </Card>
                    </Col>
                  );
                })}
              {unaddedCategories?.map((item, key) => {
                return (
                  <Col key={item.objid} span={6}>
                    <Card
                      onClick={async () => {
                        handleCategorySelect(item);
                      }}
                      hoverable
                      style={{
                        width: 220,
                        minHeight: 280,
                        maxHeight: 280,
                      }}
                      cover={
                        <img
                          alt="Category"
                          src={
                            item.images
                              ? item.images[0].img_url
                              : Images.Images.PlaceHolder
                          }
                          style={{ height: 150, objectFit: "cover" }}
                        />
                      }
                    >
                      <Meta title={item.name} description={`Total Items : 0`} />
                    </Card>
                  </Col>
                );
              })}
            </Row>
            <Modal
              footer={null}
              closable={true}
              onCancel={() => setAddCatModal(false)}
              width={"60%"}
              visible={addCatModal}
            >
              <CategoryForm
                callback={async (value) => {
                  setAddCatModal(value);
                }}
              />
            </Modal>
          </>
        );
      }
      case 2: {
        return (
          <div style={{ width: "100%", minHeight: "60vh" }}>
            <Row
              justify="space-between"
              style={{
                marginTop: "2rem",
                marginBottom: "2rem",
                width: "100%",
                maxWidth: "100%",
              }}
            >
              <Breadcrumb>
                <Breadcrumb.Item>
                  {payload?.name || payload?.objid}
                </Breadcrumb.Item>
                {/* <Breadcrumb.Item overlay={DropdownMenu}> */}
                <Breadcrumb.Item>{selectedCategory?.name}</Breadcrumb.Item>
                <Breadcrumb.Item>Items</Breadcrumb.Item>
              </Breadcrumb>
              <Row
                style={{ width: "50%" }}
                align="middle"
                justify="center"
                gutter={[20, 20]}
              >
                <Col span={4}>
                  <h6>Category</h6>
                </Col>
                <Col span={14}>
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    overlay={DropdownMenu}
                  >
                    {selectedCategory?.name}
                  </Dropdown.Button>
                </Col>
              </Row>
              <Row>
                <Space>
                  <Button
                    onClick={() => {
                      setIsModalItemVisible(true);
                      setIsEditMode(false);
                    }}
                    type="primary"
                  >
                    Add Item
                  </Button>
                  <Button
                    onClick={() => {
                      setModifierModal(true);
                      setIsEditMode(false);
                    }}
                    type="primary"
                  >
                    Add Modifier
                  </Button>
                </Space>
              </Row>
            </Row>

            <Row align="middle" justify="center" style={{ flex: 'nowrap' }}>
              <Col span={24}>
                <Row align="middle" justify="center" style={{ width: "100%" }}>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}></Col>
                  <Col xs={2} sm={2} md={1} lg={2} xl={2} xxl={2} >
                    <Row>
                      <Space>
                        <h5 className="catalogue_heading_order" > Order</h5>
                        <Popover content={CONTENT} title="Order">
                          <i
                            style={{ color: Theme.blue }}
                            className="fa fa-info-circle"
                            aria-hidden="true"
                            
                          />
                        </Popover>
                      </Space>
                    </Row>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <h5 className="catalogue_heading_item">Item</h5>
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className='catalogue_col_price_peritem' >
                    <h5 className="catalogue_price_peritem">Price per Item </h5>
                  </Col>

                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{display:"flex", justifyContent:"center"}}>
                    <h5 className="catalogue_available_from">Available from</h5>
                  </Col>
                  <Col  xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
                    <h5 className="catalogue_heading_active">Active</h5>
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                    <h5 className="catalogue_heading_unit">Unit</h5>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <h5 className="catalogue_heading_actions">Actions</h5>
                  </Col>
                </Row>
                <div>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          // style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {catalogueItemsList.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.objid}
                                  draggableId={String(item.objid)}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}

                                    >
                                      <PriceRow
                                        modifiers={item.catalogueModifiers}
                                        categoryId={item.categoryId}
                                        catalogueId={payload.objid}
                                        catalogueItemId={item.objid}
                                        currencySymbol={currencySymbol}
                                        bizid={bizid}
                                        price={item.price}
                                        slno={index + 1}
                                        status={item.status}
                                        availableFrom={item.availableFrom}
                                        key={index}
                                        options={optionItems}
                                        ModiferOptions={modifiers}
                                        item={item.item}
                                        callback={() => {
                                          getDetailsOfCatalogue();
                                        }}
                                        editPress={() => {
                                          setconfigItem(item.item);
                                          setIsEditMode(true);
                                          setIsModalItemVisible(true);
                                        }}
                                        callbackModifier={(item) => {
                                          setModiferConfig(item.modifier);
                                          setModifierModal(true);
                                        }}
                                        deletePress={() =>
                                          handleDeleteItem(item)
                                        }
                                        itemClickedCallback={(objid) => {
                                          handleItemClickCallBack(objid);
                                        }}
                                        isOpen={clickedItems.includes(
                                          item.item.objid
                                        )}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </DragDropContext>
                  <Divider />
                  <div
                    style={{
                      marginTop: "1rem",
                      marginBottom: "2rem",
                      padding: "1rem",
                    }}
                  >
                    <PriceRow
                      categoryId={selectedCategory?.objid}
                      catalogueId={payload.objid}
                      bizid={bizid}
                      options={optionItems}
                      callback={(value) => { }}
                      hideEdit
                      ModiferOptions={modifiers}
                      currencySymbol={currencySymbol}
                      slno={catalogueItemsList.length + 1}
                      status={"active"}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Modal
              title={configItem?.title}
              visible={isModalItemVisible}
              width={"60%"}
              footer={null}
              closable={true}
              onCancel={() => setIsModalItemVisible(false)}
            >
              <ItemForm
                isEdit={isEditMode}
                item={isEditMode ? configItem : null}
                callback={(value) => setIsModalItemVisible(value)}
              />
            </Modal>
            <Modal
              width={"80%"}
              footer={null}
              visible={modiferModal}
              title={configItem?.title}
              onOk={() => setModifierModal(false)}
              onCancel={() => setModifierModal(false)}
            >
              <ModifierForm
                isEdit={isEditMode}
                item={isEditMode ? modifierConfig : null}
                callback={(value) => setModifierModal(value)}
              />
            </Modal>
          </div>
        );
      }
      case 3: {
        return (
          <div style={{ width: "100%", minHeight: "60vh" }}>
            <Row
              justify="space-between"
              style={{ marginTop: "2rem", marginBottom: "2rem", width: "100%" }}
            >
              <h5>Select Location</h5>
              <Row justify="end" style={{ marginBottom: "2rem" }}>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      setStarted(true);
                      setlocationIsEdit(false);
                    }}
                  >
                    Add Locations
                  </Button>
                </Col>
              </Row>
              <Row
                align="middle"
                justify="center"
                style={{ width: "100%", marginTop: "2rem" }}
              >
                <Col span={24}>
                  <Table
                    rowKey={(item) => item.objid}
                    rowSelection={{
                      type: "checkbox",
                      ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={venues}
                     pagination={pagination}       
                    onChange={handleTableChange}
                  />
                </Col>
              </Row>
            </Row>
            <ConfirmationModal
              title={`Update Location`}
              visible={confirmationPopup}
              description={`The catalogue on the selected location will be replaced with ${catalogueDetails?.name}`}
              onConfirm={() => {
                try {
                  LocationUpdate();
                } catch (error) {
                  console.log(error);
                } finally {
                  setConfirmationPopup(false);
                }
              }}
              onCancel={() => setConfirmationPopup(false)}
            />
          </div>
        );
      }
      default: {
        return <h1>default</h1>;
      }
    }
  };

  return (
    <>
      <LoadingOverlay
        active={isLoading}
        spinner
        text=""
        styles={{
          overlay: (base) => ({
            ...base,
            background: Theme.overlay,
          }),
        }}
      >
        <ContainerWrapper title={isEdit ? `Edit : ${item.name} ` : "Add Collections"}>
          <Row justify="start">
            <Steps
              size="small"
              current={currentStep}
              onChange={(value) => isEdit && setCurrentStep(value)}
            >
              <Step title="Step 1" description="Catalogue" />
              <Step title="Step 2" description="Select Category" />
              <Step title="Step 3" description="Customize Items" />
              <Step title="Step 4" description="Locations" />
            </Steps>
          </Row>
          <Row style={{ maxWidth: "100%" }}>
            <Switcher />
          </Row>
          <Row style={{ marginTop: "2rem" }} align="middle" justify="end">
            <Space direction="horizontal">
              {currentStep !== 0 &&
                <Button
                  disabled={isLoading}
                  type="primary"
                  onClick={() => {
                    if (currentStep == 0) {
                      return;
                    }
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  Back
                </Button>
              }
              <Button
                disabled={isLoading}
                type="primary"
                onClick={() => verifier()}
              >
                {currentStep == 3 ? "Done" : "Next"}
                {isLoading && <Loader />}
              </Button>

              {currentStep === 3 && (
                <Button
                  type="primary"
                  onClick={() => {
                    history.push(`/catalogue/store/${catalogueDetails.url}`, {
                      catalogueDetail: catalogueDetails,
                      isPreview: true,
                      
                    })
                  }}
                >
                  Preview
                </Button>
              )}
              <Button
                onClick={() => callback(false)}
                disabled={isLoading}
                type="warning"
              >
                Exit
              </Button>
            </Space>
          </Row>
        </ContainerWrapper>

        <Modal visible={started} footer={null} width={"1050px"} closable={false}>
        {started && (
          <AddLocations
            callback={() => setStarted(false)}
            started={started}
            data={details}
            isEdit={LocationIsEdit}
          />
        )}
      </Modal>
      </LoadingOverlay>
    </>
  );
};

export default Edit;
